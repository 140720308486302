import { useEffect, useState } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'

const SettingsPage = () => {

    const [users, setUsers] = useState([])
    const [role, setRole] = useState(0)
    const [company, setCompany] = useState("")
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/users')
            .then((res) => {
                setUsers(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])
    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setRole(response.data.role)
                setCompany(response.data.company)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }
    const deleteUser = (id) => {
        // axios.delete(process.env.REACT_APP_API_URL + '/users/' + id)
        // setUsers(users.filter((user) => user.id !== id))
    }

    // const changePassword = (e) => {
    //     e.preventDefault()
    //     navigate(`/resetPassword`);
    // }

    if (users.length > 0 && !loading && !userPropertiesLoading && role) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="flex h-screen overflow-hidden  bg-congress-blue bg-opacity-5">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Account" section="Settings" showUserMenu='true' showLogo='true' iconFileName='Cog-Icon' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <div className="flex flex-col">
                            <div className="align-left m-auto pt-8">
                                <h1 className='text-2xl text-congress-blue'>Authentication</h1>
                                <div className="py-2 align-middle inline-block lg:w-2/3 sm:w-3/4 w-full sm:px-6 lg:px-8">
                                </div>
                            </div>
                        </div>
                        {/* <div className="flex items-center ">
                            <div className="container max-w-6xl px-5">
                                <div className="flex items-center justify-between p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                    <div>
                                        <div className="text-sm text-gray-500 "> </div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-pacific-blue-600 "><p className="my-1">Two-factor authentication</p></div>
                                        </div>
                                    </div>
                                    <div className="bg-white ">
                                        <button type="button" className='rounded-lg shadow-lg p-2  text-pacific-blue-500 shadow-slate-300'>Set up</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="flex items-center mt-5">
                            <div className="container max-w-6xl px-5">
                                <div className="flex items-center justify-between p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                    <div>
                                        <div className="text-sm text-gray-500 "> </div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-pacific-blue-600 "><p className="my-1">Password</p></div>
                                        </div>
                                    </div>
                                    <div className="bg-white ">
                                        <button type="button" onClick={() => navigate(`/resetPassword`)} className='rounded-lg shadow-lg p-2  text-pacific-blue-500 shadow-slate-300'>Change</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {((role === 2001)||(role === 4001)||(role === 1001)||(role === 2004)||(role === 2003)||(role === 2001) )
                            ? <div className="flex items-center mt-5">
                                <div className="container max-w-6xl px-5">
                                    <div className="flex items-center justify-between p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 "> </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue-600 "><p className="my-1">Users</p></div>
                                            </div>
                                        </div>
                                        <div className="bg-white ">
                                            <button type="button" onClick={() => navigate(`/newUsers`)} className='rounded-lg shadow-lg p-2  text-pacific-blue-500 shadow-slate-300'>Approve or block users</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null

                        }


                    </div>
                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Users" section="Select User" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default SettingsPage
