import { useEffect, useState } from 'react'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const ListCreditsPublic = () => {

    const [credits, setCredits] = useState([])
    const [creditsFiltered, setCreditsFiltered] = useState([])
    const [projects, setProjects] = useState([])
    const [loadingCredits, setLoadingCredits] = useState(false)
    const [loadingProjects, setLoadingProjects] = useState(false)
    const [creditBufferFilter, setCreditBufferFilter] = useState("All")

    const internationalNumberFormat = new Intl.NumberFormat('en-US')

    useEffect(() => {

        setLoadingCredits(true);
        setLoadingProjects(true);

        axios.get(process.env.REACT_APP_API_URL + '/offset_credits')
            .then((res) => {
                setCredits(res.data);
                setCreditsFiltered(res.data);
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoadingCredits(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/offset_projects')
            .then((res) =>  {
                setProjects(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingProjects(false);
            });

    }, []);

    useEffect(() => {

        if (creditBufferFilter === "All") {
            setCreditsFiltered(credits)
        } else {
            setCreditsFiltered(credits.filter((credit) => credit.creditBuffer === creditBufferFilter))
        }

    }, [creditBufferFilter, credits]);

    const navigate = useNavigate()

    const viewCredit = (id) => {
        navigate(`/viewCreditPublic/${id}`);
    }

    if (projects.length > 0 && credits.length > 0 && creditsFiltered.length > 0 && !loadingCredits && !loadingProjects) {

        return (
            <div>

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                            <Header title="Carbon Offset Registry" section="Credits" showButtons={true} buttonToggle="Credits" isPublic="Public" />
                            <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2">
                                            <table className="objects-8A min-w-full divide-y divide-gray-200">
                                                <thead className="bg-pacific-blue-50">
                                                    <tr>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Project</th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Serial Number</th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Type</th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Vintage</th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Batch</th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Quantity</th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Status</th>
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Buffer <select className="ml-2 b-1" value={creditBufferFilter} onChange={(e) => setCreditBufferFilter(e.target.value)}>
                                                            <option value="All">All</option>
                                                            <option value="No">No</option>
                                                            <option value="Yes">Yes</option>
                                                        </select></th>
                                                    </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {creditsFiltered.map((credit) => (
                                                        <tr key={credit.id}>
                                                            <td onClick={() => viewCredit(credit.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {projects.find(project => project.id === credit.projectId).projectName} </td>
                                                            <td onClick={() => viewCredit(credit.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {credit.creditSerialNumber}</td>
                                                            <td onClick={() => viewCredit(credit.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {credit.creditType}</td>
                                                            <td onClick={() => viewCredit(credit.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {credit.creditVintage}</td>
                                                            <td onClick={() => viewCredit(credit.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {credit.creditBatch}</td>
                                                            <td onClick={() => viewCredit(credit.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {credit.creditQuantity}</td>
                                                            <td onClick={() => viewCredit(credit.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {credit.creditStatus}</td>
                                                            <td onClick={() => viewCredit(credit.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {credit.creditBuffer}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center bg-slate-100">
                                <div class="container max-w-6xl px-5 mx-auto my-5">
                                    <div class="grid gap-7 sm:grid-cols-2 "></div>
                                    <div class="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div class="text-sm text-gray-500 ">Total</div>
                                            <div class="flex items-center pt-1">
                                                <div class="text-xl font-medium text-indigo-500 "><p className="my-1">{internationalNumberFormat.format(creditsFiltered.map(item => item.creditQuantity).reduce((prev, curr) => Number(prev) + Number(curr), 0))} credits</p></div>
                                            </div>
                                        </div>
                                        <div class="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br></br>
                        </div>
                    </div>
                </div>
            </div >
        )

    } else {
        return (
            <div>

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Carbon Offset Registry" section="Credits" showButtons={true} buttonToggle="Credits" isPublic="Public" />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListCreditsPublic