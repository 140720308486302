import { useEffect, useState } from "react";
import Header from "../UI/Header";
import axios from "axios";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import Sidebar from "../UI/Sidebar";
import CountUp from "react-countup";
import { MultiSelect } from "react-multi-select-component";
import ReactApexChart from "react-apexcharts";
import Button from '../UI/Button'
import { ExportToCsv } from 'export-to-csv'

import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from "chart.js";

ChartJS.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);


const DashboardOccupantSatisfaction = () => {

    const [loading, setLoading] = useState(true);

    const [sidebarToggle, setSidebarToggle] = useState(false);

    const [numberOfRecordsToShow, setNumberOfRecordsToShow] = useState(10);

    const [numberOfRecordsToShowAttributes, setNumberOfRecordsToShowAttributes] = useState(50);

    const [surveyRecords, setSurveyRecords] = useState([]);

    const [surveyRecordsFullyFiltered, setSurveyRecordsFullyFiltered] = useState([]);

    const [scoresByProjectType, setScoresByProjectType] = useState([]);
    const [labelsByProjectType, setLabelsByProjectType] = useState([]);

    const [scoresByRegion, setScoresByRegion] = useState([]);
    const [labelsByRegion, setLabelsByRegion] = useState([]);

    const [scoresByAttribute, setScoresByAttribute] = useState([]);
    const [labelsByAttribute, setLabelsByAttribute] = useState([]);

    const [scoresByLocation, setScoresByLocation] = useState([]);
    const [labelsByLocation, setLabelsByLocation] = useState([]);

    const [scoresByCategory, setScoresByCategory] = useState([]);
    const [labelsByCategory, setLabelsByCategory] = useState([]);

    const [treeMapScores, setTreeMapScores] = useState([]);
    const [treeMapScoresByCategory, setTreeMapScoresByCategory] = useState([]);

    const [selectedProjectType, setSelectedProjectType] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState([]);

    const chartColours = [
        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
    ];
    const chartHoverColours = [
        "#10475C",
        "#007980",
        "#0E2343",
        "#96abb3",
        "#036860",
        "#0B4516",
        "#052827",
        "#3C6F26",
        "#3A4535",
        "#61703F",
        "#a16b1a",
        "#CEB691",
        "#2E211C",
        "#802741",
        "#732912",
        "#7A6009",
        "#4d6875",
        "#303372",
        "#3B2061",
        "#413F5C",
        "#481436",
        "#783378",
    ];

    const treeMapOptions = {

        plotOptions: {
            treemap: {
              enableShades: false
            }
          },

        legend: {
            show: true,
            itemMargin: {
                horizontal: 10,
                vertical: 10
            },
            fontFamily: 'TT Norms Pro',
            fontSize: 15,
            color: '#5d6a70'
        },
        chart: {
            height: 350,
            type: 'treemap'
        },
        dataLabels: {
            style: {
              fontSize: 16,
              fontFamily: "TT Norms Pro"
            },
          },
        title: {
            text: 'Average Scores per ? per ?',
            align: 'center',
            style: {
                fontFamily: 'TT Norms Pro',
                fontSize: 18,
                color: '#5d6a70'
            },
        },

        colors: [

        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
        ]
    }

    const treeMapOptionsByCategory = {

        plotOptions: {
            treemap: {
              enableShades: false
            }
          },

        legend: {
            show: true,
            itemMargin: {
                horizontal: 10,
                vertical: 10
            },
            fontFamily: 'TT Norms Pro',
            fontSize: 15,
            color: '#5d6a70'
        },
        chart: {
            height: 350,
            type: 'treemap'
        },
        dataLabels: {
            style: {
              fontSize: 16,
              fontFamily: "TT Norms Pro"
            },
          },
        title: {
            text: 'Average Scores per ? by ?',
            align: 'center',
            style: {
                fontFamily: 'TT Norms Pro',
                fontSize: 18,
                color: '#5d6a70'
            },
        },
        colors: [

        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
        ]
    }

    const aggregateProjectTypes = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectType === val.projectType);
            if (index === -1) {
                acc.push({
                    projectType: val.projectType,
                });
            };
            return acc.sort((item1, item2) => item1.projectType < item2.projectType ? -1 : item1.projectType > item2.projectType ? 1 : 0);
        }, []);
    };

    const aggregateRegions = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.region === val.region);
            if (index === -1) {
                acc.push({
                    region: val.region,
                });
            };
            return acc.sort((item1, item2) => item1.region < item2.region ? -1 : item1.region > item2.region ? 1 : 0);
        }, []);
    };

    const aggregateLocations = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.location === val.location);
            if (index === -1) {
                acc.push({
                    location: val.location,
                });
            };
            return acc.sort((item1, item2) => item1.location < item2.location ? -1 : item1.location > item2.location ? 1 : 0);
        }, []);
    };

    const aggregateCategories = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.category === val.category);
            if (index === -1) {
                acc.push({
                    category: val.category,
                });
            };
            return acc.sort((item1, item2) => item1.category < item2.category ? -1 : item1.category > item2.category ? 1 : 0);
        }, []);
    };

    const aggregateAttributes = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.attribute === val.attribute);
            if (index === -1) {
                acc.push({
                    attribute: val.attribute,
                });
            };
            return acc.sort((item1, item2) => item1.attribute < item2.attribute ? -1 : item1.attribute > item2.attribute ? 1 : 0);
        }, []);
    };

    const aggregateRespondents = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.respondentId === val.respondentId);
            if (index === -1) {
                acc.push({
                    respondentId: val.respondentId,
                });
            };
            return acc.sort((item1, item2) => item1.respondentId < item2.respondentId ? -1 : item1.respondentId > item2.respondentId ? 1 : 0);
        }, []);
    };


    const dataChart5 = {
        labels: labelsByProjectType,
        datasets: [
            {
                data: scoresByProjectType,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart6 = {
        labels: labelsByLocation.slice(0,numberOfRecordsToShow),
        datasets: [
            {
                data: scoresByLocation.slice(0,numberOfRecordsToShow),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart6b = {
        labels: labelsByLocation,
        datasets: [
            {
                data: scoresByLocation,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart7 = {
        labels: labelsByCategory.slice(0,numberOfRecordsToShow),
        datasets: [
            {
                data: scoresByCategory.slice(0,numberOfRecordsToShow),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart7b = {
        labels: labelsByCategory,
        datasets: [
            {
                data: scoresByCategory,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart8 = {
        labels: labelsByRegion,
        datasets: [
            {
                data: scoresByRegion,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart9 = {
        labels: labelsByAttribute.slice(0,numberOfRecordsToShowAttributes),
        datasets: [
            {
                data: scoresByAttribute.slice(0,numberOfRecordsToShowAttributes),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Average Scores per ?',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },

        },
        
    };

    
    const barOptionsHorizontal = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Average Scores per ?',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };


    const pieOptionsByCategory = {
        plugins: {
            title: {
                display: true,
                text: 'Average Scores per Category',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },
        },
    };

    const pieOptionsByLocation = {
        plugins: {
            title: {
                display: true,
                text: 'Average Scores per Location',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },
        },
    };

    const pieOptionsByRegion = {
        plugins: {
            title: {
                display: true,
                text: 'Average Scores per Region',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },
        },
    };

    const pieOptionsByAttribute = {
        plugins: {
            title: {
                display: true,
                text: 'Average Scores per Attribute',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },
        },
    };

    
    const barOptionsHorizontalByCategory = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Average Scores per Category',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalByRegion = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Average Scores per Region',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalByAttribute = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 10,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 10,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Average Scores per Attribute',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalByLocation = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Average Scores per Location',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const arrayToCsv = (data) => {

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Occupant Survey Results',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data)

    }

    
    useEffect(() => {

         var surveyRecordsLocalVariable = [
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature level (i.e. whether you get too cold or too hot) at your workplace within the last 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature stability (i.e. how much the temperature fluctuates during the day) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the humidity (i.e. do you feel either dry or clammy) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the odour (i.e. do smells distract you or limit your ability to perform your required tasks) at your workplace within the last 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the level of dust (i.e. airborne and stationary) at your workplace within the last 12 months?",
              "value": 4
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the levels of mould and fungi (both smells and visual presence) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "When moving around your workspace, how satisfied have you been with the consistency (i.e. are the conditions the same in all parts of the workspace) of air quality at your workspace in the previou...",
              "value": 4
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the levels of noise from outside the building (i.e. road traffic, aircraft, trains, pedestrians, events, etc.) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the level of general building equipment noise (i.e. lifts, air conditioning units, other machinery etc.) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with your sound privacy (i.e. ability to have conversations without your neighbours overhearing and vice versa) at your workspace in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the quantity and quality of light (i.e. enough to perform your tasks without interference or eye strain) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of artificial lighting flicker at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of natural light at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of lighting control (i.e. availability of dimmers, blinds for controlling natural light levels, etc.) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of work and desk space available for you to perform your job function in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of storage available for you (e.g. personal lockers and/or cupboards etc) to perform your job function in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the location and arrangement of general workspace equipment in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with your proximity to a view (i.e. being able to see into the distance either through a window or pleasing outlook) in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the comfort, appearance and quality of your workspace furnishings (i.e. chair, desk, computer, equipment, etc.) in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the eating spaces facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the breakout spaces facilities and amenities in the previous twelve months ?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the changing rooms facilities and amenities in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the bathrooms facilities and amenities in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Doe everyone receive the necessary workplace health and safety training when starting a job, changing jobs or using new techniques or procedures? ",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is the communication about workplace health and safety procedures done in a way that you can understand?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there communication between employees and management about safety issues and wellbeing concerns?",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is everyone aware who the first aiders and fire Marshalls are during their working shifts?",
              "value": 1
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there an active and effective health and safety committee and/or worker health and safety rep at your workplace that equally addresses the wellbeing of the staff?",
              "value": 1
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there a log system in place to register any non-conformity, injury, illnesses and/or complaints for both the staff and operation & management of the store? ",
              "value": 7
            },
            {
              "respondentId": 1,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Overall, does the indoor environment provided by your workplace (i.e. a combination of the previous items) interfere with or enhance your ability to get your job done?",
              "value": 4
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature level (i.e. whether you get too cold or too hot) at your workplace within the last 12 months?",
              "value": 6
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature stability (i.e. how much the temperature fluctuates during the day) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the humidity (i.e. do you feel either dry or clammy) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the odour (i.e. do smells distract you or limit your ability to perform your required tasks) at your workplace within the last 12 months?",
              "value": 6
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the level of dust (i.e. airborne and stationary) at your workplace within the last 12 months?",
              "value": 3
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the levels of mould and fungi (both smells and visual presence) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "When moving around your workspace, how satisfied have you been with the consistency (i.e. are the conditions the same in all parts of the workspace) of air quality at your workspace in the previou...",
              "value": 4
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the levels of noise from outside the building (i.e. road traffic, aircraft, trains, pedestrians, events, etc.) at your workspace in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the level of general building equipment noise (i.e. lifts, air conditioning units, other machinery etc.) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with your sound privacy (i.e. ability to have conversations without your neighbours overhearing and vice versa) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the quantity and quality of light (i.e. enough to perform your tasks without interference or eye strain) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of artificial lighting flicker at your workspace in the previous 12 months?",
              "value": 5
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of natural light at your workspace in the previous 12 months?",
              "value": 5
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of lighting control (i.e. availability of dimmers, blinds for controlling natural light levels, etc.) at your workspace in the previous 12 months?",
              "value": 5
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of work and desk space available for you to perform your job function in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of storage available for you (e.g. personal lockers and/or cupboards etc) to perform your job function in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the location and arrangement of general workspace equipment in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with your proximity to a view (i.e. being able to see into the distance either through a window or pleasing outlook) in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the comfort, appearance and quality of your workspace furnishings (i.e. chair, desk, computer, equipment, etc.) in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the eating spaces facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the breakout spaces facilities and amenities in the previous twelve months ?",
              "value": 1
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the changing rooms facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the bathrooms facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Doe everyone receive the necessary workplace health and safety training when starting a job, changing jobs or using new techniques or procedures? ",
              "value": 6
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is the communication about workplace health and safety procedures done in a way that you can understand?",
              "value": 6
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there communication between employees and management about safety issues and wellbeing concerns?",
              "value": 7
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is everyone aware who the first aiders and fire Marshalls are during their working shifts?",
              "value": 7
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there an active and effective health and safety committee and/or worker health and safety rep at your workplace that equally addresses the wellbeing of the staff?",
              "value": 6
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there a log system in place to register any non-conformity, injury, illnesses and/or complaints for both the staff and operation & management of the store? ",
              "value": 7
            },
            {
              "respondentId": 2,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Overall, does the indoor environment provided by your workplace (i.e. a combination of the previous items) interfere with or enhance your ability to get your job done?",
              "value": 5
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature level (i.e. whether you get too cold or too hot) at your workplace within the last 12 months?",
              "value": 2
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature stability (i.e. how much the temperature fluctuates during the day) at your workspace in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the humidity (i.e. do you feel either dry or clammy) at your workspace in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the odour (i.e. do smells distract you or limit your ability to perform your required tasks) at your workplace within the last 12 months?",
              "value": 6
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the level of dust (i.e. airborne and stationary) at your workplace within the last 12 months?",
              "value": 6
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the levels of mould and fungi (both smells and visual presence) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "When moving around your workspace, how satisfied have you been with the consistency (i.e. are the conditions the same in all parts of the workspace) of air quality at your workspace in the previou...",
              "value": 2
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the levels of noise from outside the building (i.e. road traffic, aircraft, trains, pedestrians, events, etc.) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the level of general building equipment noise (i.e. lifts, air conditioning units, other machinery etc.) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with your sound privacy (i.e. ability to have conversations without your neighbours overhearing and vice versa) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the quantity and quality of light (i.e. enough to perform your tasks without interference or eye strain) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of artificial lighting flicker at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of natural light at your workspace in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of lighting control (i.e. availability of dimmers, blinds for controlling natural light levels, etc.) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of work and desk space available for you to perform your job function in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of storage available for you (e.g. personal lockers and/or cupboards etc) to perform your job function in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the location and arrangement of general workspace equipment in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with your proximity to a view (i.e. being able to see into the distance either through a window or pleasing outlook) in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the comfort, appearance and quality of your workspace furnishings (i.e. chair, desk, computer, equipment, etc.) in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the eating spaces facilities and amenities in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the breakout spaces facilities and amenities in the previous twelve months ?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the changing rooms facilities and amenities in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the bathrooms facilities and amenities in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Doe everyone receive the necessary workplace health and safety training when starting a job, changing jobs or using new techniques or procedures? ",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is the communication about workplace health and safety procedures done in a way that you can understand?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there communication between employees and management about safety issues and wellbeing concerns?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is everyone aware who the first aiders and fire Marshalls are during their working shifts?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there an active and effective health and safety committee and/or worker health and safety rep at your workplace that equally addresses the wellbeing of the staff?",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there a log system in place to register any non-conformity, injury, illnesses and/or complaints for both the staff and operation & management of the store? ",
              "value": 7
            },
            {
              "respondentId": 3,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Overall, does the indoor environment provided by your workplace (i.e. a combination of the previous items) interfere with or enhance your ability to get your job done?",
              "value": 7
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature level (i.e. whether you get too cold or too hot) at your workplace within the last 12 months?",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature stability (i.e. how much the temperature fluctuates during the day) at your workspace in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the humidity (i.e. do you feel either dry or clammy) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the odour (i.e. do smells distract you or limit your ability to perform your required tasks) at your workplace within the last 12 months?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the level of dust (i.e. airborne and stationary) at your workplace within the last 12 months?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the levels of mould and fungi (both smells and visual presence) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "When moving around your workspace, how satisfied have you been with the consistency (i.e. are the conditions the same in all parts of the workspace) of air quality at your workspace in the previou...",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the levels of noise from outside the building (i.e. road traffic, aircraft, trains, pedestrians, events, etc.) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the level of general building equipment noise (i.e. lifts, air conditioning units, other machinery etc.) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with your sound privacy (i.e. ability to have conversations without your neighbours overhearing and vice versa) at your workspace in the previous 12 months?",
              "value": 5
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the quantity and quality of light (i.e. enough to perform your tasks without interference or eye strain) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of artificial lighting flicker at your workspace in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of natural light at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of lighting control (i.e. availability of dimmers, blinds for controlling natural light levels, etc.) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of work and desk space available for you to perform your job function in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of storage available for you (e.g. personal lockers and/or cupboards etc) to perform your job function in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the location and arrangement of general workspace equipment in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with your proximity to a view (i.e. being able to see into the distance either through a window or pleasing outlook) in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the comfort, appearance and quality of your workspace furnishings (i.e. chair, desk, computer, equipment, etc.) in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the eating spaces facilities and amenities in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the breakout spaces facilities and amenities in the previous twelve months ?",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the changing rooms facilities and amenities in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the bathrooms facilities and amenities in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Doe everyone receive the necessary workplace health and safety training when starting a job, changing jobs or using new techniques or procedures? ",
              "value": 2
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is the communication about workplace health and safety procedures done in a way that you can understand?",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there communication between employees and management about safety issues and wellbeing concerns?",
              "value": 4
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is everyone aware who the first aiders and fire Marshalls are during their working shifts?",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there an active and effective health and safety committee and/or worker health and safety rep at your workplace that equally addresses the wellbeing of the staff?",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there a log system in place to register any non-conformity, injury, illnesses and/or complaints for both the staff and operation & management of the store? ",
              "value": 3
            },
            {
              "respondentId": 4,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Overall, does the indoor environment provided by your workplace (i.e. a combination of the previous items) interfere with or enhance your ability to get your job done?",
              "value": 3
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature level (i.e. whether you get too cold or too hot) at your workplace within the last 12 months?",
              "value": 4
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature stability (i.e. how much the temperature fluctuates during the day) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the humidity (i.e. do you feel either dry or clammy) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the odour (i.e. do smells distract you or limit your ability to perform your required tasks) at your workplace within the last 12 months?",
              "value": 5
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the level of dust (i.e. airborne and stationary) at your workplace within the last 12 months?",
              "value": 6
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the levels of mould and fungi (both smells and visual presence) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Air quality",
              "attribute": "When moving around your workspace, how satisfied have you been with the consistency (i.e. are the conditions the same in all parts of the workspace) of air quality at your workspace in the previou...",
              "value": 6
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the levels of noise from outside the building (i.e. road traffic, aircraft, trains, pedestrians, events, etc.) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the level of general building equipment noise (i.e. lifts, air conditioning units, other machinery etc.) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with your sound privacy (i.e. ability to have conversations without your neighbours overhearing and vice versa) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the quantity and quality of light (i.e. enough to perform your tasks without interference or eye strain) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of artificial lighting flicker at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of natural light at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of lighting control (i.e. availability of dimmers, blinds for controlling natural light levels, etc.) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of work and desk space available for you to perform your job function in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of storage available for you (e.g. personal lockers and/or cupboards etc) to perform your job function in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the location and arrangement of general workspace equipment in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with your proximity to a view (i.e. being able to see into the distance either through a window or pleasing outlook) in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the comfort, appearance and quality of your workspace furnishings (i.e. chair, desk, computer, equipment, etc.) in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the eating spaces facilities and amenities in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the breakout spaces facilities and amenities in the previous twelve months ?",
              "value": 3
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the changing rooms facilities and amenities in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the bathrooms facilities and amenities in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Doe everyone receive the necessary workplace health and safety training when starting a job, changing jobs or using new techniques or procedures? ",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is the communication about workplace health and safety procedures done in a way that you can understand?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there communication between employees and management about safety issues and wellbeing concerns?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is everyone aware who the first aiders and fire Marshalls are during their working shifts?",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there an active and effective health and safety committee and/or worker health and safety rep at your workplace that equally addresses the wellbeing of the staff?",
              "value": 5
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Is there a log system in place to register any non-conformity, injury, illnesses and/or complaints for both the staff and operation & management of the store? ",
              "value": 7
            },
            {
              "respondentId": 5,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "UK",
              "location": "ABC Retailer Convent Garden store",
              "category": "H&S",
              "attribute": "Overall, does the indoor environment provided by your workplace (i.e. a combination of the previous items) interfere with or enhance your ability to get your job done?",
              "value": 3
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature level (i.e. whether you get too cold or too hot) at your workplace within the last 12 months?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature stability (i.e. how much the temperature fluctuates during the day) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the humidity (i.e. do you feel either dry or clammy) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the odour (i.e. do smells distract you or limit your ability to perform your required tasks) at your workplace within the last 12 months?",
              "value": 2
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the level of dust (i.e. airborne and stationary) at your workplace within the last 12 months?",
              "value": 2
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the levels of mould and fungi (both smells and visual presence) at your workspace in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Air quality",
              "attribute": "When moving around your workspace, how satisfied have you been with the consistency (i.e. are the conditions the same in all parts of the workspace) of air quality at your workspace in the previou...",
              "value": 3
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the levels of noise from outside the building (i.e. road traffic, aircraft, trains, pedestrians, events, etc.) at your workspace in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the level of general building equipment noise (i.e. lifts, air conditioning units, other machinery etc.) at your workspace in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with your sound privacy (i.e. ability to have conversations without your neighbours overhearing and vice versa) at your workspace in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the quantity and quality of light (i.e. enough to perform your tasks without interference or eye strain) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of artificial lighting flicker at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of natural light at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of lighting control (i.e. availability of dimmers, blinds for controlling natural light levels, etc.) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of work and desk space available for you to perform your job function in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of storage available for you (e.g. personal lockers and/or cupboards etc) to perform your job function in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the location and arrangement of general workspace equipment in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with your proximity to a view (i.e. being able to see into the distance either through a window or pleasing outlook) in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the comfort, appearance and quality of your workspace furnishings (i.e. chair, desk, computer, equipment, etc.) in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the eating spaces facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the breakout spaces facilities and amenities in the previous twelve months ?",
              "value": 1
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the changing rooms facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the bathrooms facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "H&S",
              "attribute": "Doe everyone receive the necessary workplace health and safety training when starting a job, changing jobs or using new techniques or procedures? ",
              "value": 4
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "H&S",
              "attribute": "Is the communication about workplace health and safety procedures done in a way that you can understand?",
              "value": 5
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "H&S",
              "attribute": "Is there communication between employees and management about safety issues and wellbeing concerns?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "H&S",
              "attribute": "Is everyone aware who the first aiders and fire Marshalls are during their working shifts?",
              "value": 5
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "H&S",
              "attribute": "Is there an active and effective health and safety committee and/or worker health and safety rep at your workplace that equally addresses the wellbeing of the staff?",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "H&S",
              "attribute": "Is there a log system in place to register any non-conformity, injury, illnesses and/or complaints for both the staff and operation & management of the store? ",
              "value": 6
            },
            {
              "respondentId": 6,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Australia",
              "location": "ABC Retailer Gnome Garden store",
              "category": "H&S",
              "attribute": "Overall, does the indoor environment provided by your workplace (i.e. a combination of the previous items) interfere with or enhance your ability to get your job done?",
              "value": 2
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature level (i.e. whether you get too cold or too hot) at your workplace within the last 12 months?",
              "value": 5
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature stability (i.e. how much the temperature fluctuates during the day) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the humidity (i.e. do you feel either dry or clammy) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the odour (i.e. do smells distract you or limit your ability to perform your required tasks) at your workplace within the last 12 months?",
              "value": 7
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the level of dust (i.e. airborne and stationary) at your workplace within the last 12 months?",
              "value": 4
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the levels of mould and fungi (both smells and visual presence) at your workspace in the previous 12 months?",
              "value": 7
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Air quality",
              "attribute": "When moving around your workspace, how satisfied have you been with the consistency (i.e. are the conditions the same in all parts of the workspace) of air quality at your workspace in the previou...",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the levels of noise from outside the building (i.e. road traffic, aircraft, trains, pedestrians, events, etc.) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the level of general building equipment noise (i.e. lifts, air conditioning units, other machinery etc.) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with your sound privacy (i.e. ability to have conversations without your neighbours overhearing and vice versa) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the quantity and quality of light (i.e. enough to perform your tasks without interference or eye strain) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of artificial lighting flicker at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of natural light at your workspace in the previous 12 months?",
              "value": 5
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of lighting control (i.e. availability of dimmers, blinds for controlling natural light levels, etc.) at your workspace in the previous 12 months?",
              "value": 5
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of work and desk space available for you to perform your job function in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of storage available for you (e.g. personal lockers and/or cupboards etc) to perform your job function in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the location and arrangement of general workspace equipment in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with your proximity to a view (i.e. being able to see into the distance either through a window or pleasing outlook) in the previous 12 months?",
              "value": 5
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the comfort, appearance and quality of your workspace furnishings (i.e. chair, desk, computer, equipment, etc.) in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the eating spaces facilities and amenities in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the breakout spaces facilities and amenities in the previous twelve months ?",
              "value": 2
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the changing rooms facilities and amenities in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the bathrooms facilities and amenities in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Doe everyone receive the necessary workplace health and safety training when starting a job, changing jobs or using new techniques or procedures? ",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is the communication about workplace health and safety procedures done in a way that you can understand?",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is there communication between employees and management about safety issues and wellbeing concerns?",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is everyone aware who the first aiders and fire Marshalls are during their working shifts?",
              "value": 6
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is there an active and effective health and safety committee and/or worker health and safety rep at your workplace that equally addresses the wellbeing of the staff?",
              "value": 5
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is there a log system in place to register any non-conformity, injury, illnesses and/or complaints for both the staff and operation & management of the store? ",
              "value": 4
            },
            {
              "respondentId": 7,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Overall, does the indoor environment provided by your workplace (i.e. a combination of the previous items) interfere with or enhance your ability to get your job done?",
              "value": 4
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature level (i.e. whether you get too cold or too hot) at your workplace within the last 12 months?",
              "value": 3
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the temperature stability (i.e. how much the temperature fluctuates during the day) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Thermal Comfort",
              "attribute": "How satisfied have you been with the humidity (i.e. do you feel either dry or clammy) at your workspace in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the odour (i.e. do smells distract you or limit your ability to perform your required tasks) at your workplace within the last 12 months?",
              "value": 2
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the level of dust (i.e. airborne and stationary) at your workplace within the last 12 months?",
              "value": 2
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Air quality",
              "attribute": "How satisfied have you been with the levels of mould and fungi (both smells and visual presence) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Air quality",
              "attribute": "When moving around your workspace, how satisfied have you been with the consistency (i.e. are the conditions the same in all parts of the workspace) of air quality at your workspace in the previou...",
              "value": 3
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the levels of noise from outside the building (i.e. road traffic, aircraft, trains, pedestrians, events, etc.) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with the level of general building equipment noise (i.e. lifts, air conditioning units, other machinery etc.) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Acoustic Comfort",
              "attribute": "How satisfied have you been with your sound privacy (i.e. ability to have conversations without your neighbours overhearing and vice versa) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the quantity and quality of light (i.e. enough to perform your tasks without interference or eye strain) at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of artificial lighting flicker at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of natural light at your workspace in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Lighting",
              "attribute": "How satisfied have you been with the amount of lighting control (i.e. availability of dimmers, blinds for controlling natural light levels, etc.) at your workspace in the previous 12 months?",
              "value": 4
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of work and desk space available for you to perform your job function in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the amount of storage available for you (e.g. personal lockers and/or cupboards etc) to perform your job function in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the location and arrangement of general workspace equipment in the previous 12 months?",
              "value": 2
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with your proximity to a view (i.e. being able to see into the distance either through a window or pleasing outlook) in the previous 12 months?",
              "value": 6
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the comfort, appearance and quality of your workspace furnishings (i.e. chair, desk, computer, equipment, etc.) in the previous 12 months?",
              "value": 3
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the eating spaces facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the breakout spaces facilities and amenities in the previous twelve months ?",
              "value": 1
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the changing rooms facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "Office layout",
              "attribute": "How satisfied have you been with the bathrooms facilities and amenities in the previous 12 months?",
              "value": 1
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Doe everyone receive the necessary workplace health and safety training when starting a job, changing jobs or using new techniques or procedures? ",
              "value": 7
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is the communication about workplace health and safety procedures done in a way that you can understand?",
              "value": 5
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is there communication between employees and management about safety issues and wellbeing concerns?",
              "value": 3
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is everyone aware who the first aiders and fire Marshalls are during their working shifts?",
              "value": 1
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is there an active and effective health and safety committee and/or worker health and safety rep at your workplace that equally addresses the wellbeing of the staff?",
              "value": 1
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Is there a log system in place to register any non-conformity, injury, illnesses and/or complaints for both the staff and operation & management of the store? ",
              "value": 6
            },
            {
              "respondentId": 8,
              "projectType": "Occupant Satisfaction - Retail Store",
              "region": "Singapore",
              "location": "ABC Retailer Somewhere Else store",
              "category": "H&S",
              "attribute": "Overall, does the indoor environment provided by your workplace (i.e. a combination of the previous items) interfere with or enhance your ability to get your job done?",
              "value": 6
            }
           ]

        setSurveyRecords(surveyRecordsLocalVariable)
        
        setSelectedProjectType(aggregateProjectTypes(surveyRecordsLocalVariable).map((item) => {
            return {
                label: item.projectType,
                value: item.projectType
            }
        }))

        setSelectedRegion(aggregateRegions(surveyRecordsLocalVariable).map((item) => {
            return {
                label: item.region,
                value: item.region
            }
        }))

        setSelectedLocation(aggregateLocations(surveyRecordsLocalVariable).map((item) => {
            return {
                label: item.location,
                value: item.location
            }
        }))

        setSelectedCategory(aggregateCategories(surveyRecordsLocalVariable).map((item) => {
            return {
                label: item.category,
                value: item.category
            }
        }))

        setSelectedAttribute(aggregateAttributes(surveyRecordsLocalVariable).map((item) => {
            return {
                label: item.attribute,
                value: item.attribute
            }
        }))

        setLoading(false)

    }, []);


    useEffect(() => {

            var filteredResByProjectType = []
            var filteredResByProjectTypesAndRegion = []
            var filteredResByProjectTypesAndRegionAndLocation = []
            var filteredResByProjectTypesAndRegionAndLocationAndCategory = []
            var filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes = []
            var filteredRes = surveyRecords
            var i, j
            var projectType, region, location, category, attribute, locationAndCategory
            var value = 0
            var allScores = []

            filteredResByProjectType = filteredRes.filter((itemA) => {
                return selectedProjectType.find((itemB) => {
                    return itemA.projectType === itemB.value;
                })
            })

            filteredResByProjectTypesAndRegion = filteredResByProjectType.filter((itemA) => {
                return selectedRegion.find((itemB) => {
                    return itemA.region === itemB.value;
                })
            })

            filteredResByProjectTypesAndRegionAndLocation = filteredResByProjectTypesAndRegion.filter((itemA) => {
                return selectedLocation.find((itemB) => {
                    return itemA.location === itemB.value;
                })
            })

            filteredResByProjectTypesAndRegionAndLocationAndCategory = filteredResByProjectTypesAndRegionAndLocation.filter((itemA) => {
                return selectedCategory.find((itemB) => {
                    return itemA.category === itemB.value;
                })
            })

            filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes = filteredResByProjectTypesAndRegionAndLocationAndCategory.filter((itemA) => {
                return selectedAttribute.find((itemB) => {
                    return itemA.attribute === itemB.value;
                })
            })

            setSurveyRecordsFullyFiltered(filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes)


            for (j = 0; j < filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes.length; j++) {

                value = filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes[j].value
                projectType = filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes[j].projectType
                region = filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes[j].region
                location = filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes[j].location
                category = filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes[j].category
                attribute = filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes[j].attribute
                locationAndCategory = location + category

                allScores.push({
                    region,
                    location,
                    projectType,
                    value,
                    category,
                    attribute,
                    locationAndCategory
                })
                
            }

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            var result = [];
            filteredResByProjectTypesAndRegionAndLocationAndCategoryAndAttributes.reduce(function (res, value) {
                if (!res[value.locationAndCategory]) {
                    res[value.locationAndCategory] = {
                        locationAndCategory: value.locationAndCategory,
                        location: value.location,
                        category: value.category,
                        value: 0,
                    };
                    result.push(res[value.locationAndCategory]);
                }
                res[value.locationAndCategory].value +=
                    value.value;
                return res;
            }, {});

            var treeMapArray = []
            var treeMapArrayFiltered = []

            var totalForThisLocation = 0

            for (i = 0; i < selectedLocation.length; i++) {

                totalForThisLocation = 0

                category = selectedLocation.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label)[i]

                treeMapArray.push({
                    name: location,
                    data: []
                })

                for (j = 0; j < result.length; j++) {

                    if (result[j].location === location && result[j].value > 0.001) {

                        treeMapArray[i].data.push({
                            x: [treeMapArray[i].name, result[j].category],
                            y: Number(result[j].value.toFixed(2))
                        },)

                        totalForThisLocation = totalForThisLocation + 1

                    }

                }

                if (totalForThisLocation > 0) {
                    treeMapArrayFiltered.push(treeMapArray[i])
                }

            }

            setTreeMapScores(treeMapArrayFiltered)

            result = [];
            allScores.reduce(function (res, value) {
                if (!res[value.category]) {
                    res[value.category] = {
                        category: value.category,
                        value: 0,
                        responsesCount: 0,
                    };
                    result.push(res[value.category]);
                }
                res[value.category].value +=
                    value.value;
                res[value.category].responsesCount = res[value.category].responsesCount + 1

                return res;
            }, {});

            result.sort((item1, item2) => item1.value / item1.responsesCount > item2.value / item2.responsesCount ? -1 : item1.value / item1.responsesCount < item2.value / item2.responsesCount ? 1 : 0)

            setScoresByCategory(result.map(item => item.value / item.responsesCount))
            setLabelsByCategory(result.map(item => item.category))

            // var departmentLabels = result.map(
            //     (item) => item.department
            // );

            result = [];
            allScores.reduce(function (res, value) {
                if (!res[value.location]) {
                    res[value.location] = {
                        location: value.location,
                        value: 0,
                        responsesCount: 0,
                    };
                    result.push(res[value.location]);
                }
                res[value.location].value +=
                    value.value;
                res[value.location].responsesCount = res[value.location].responsesCount + 1
                return res;
            }, {});

            result.sort((item1, item2) => item1.value / item1.responsesCount > item2.value / item2.responsesCount ? -1 : item1.value / item1.responsesCount < item2.value / item2.responsesCount ? 1 : 0)

            setScoresByLocation(result.map(item => item.value / item.responsesCount))
            setLabelsByLocation(result.map(item => item.location))

            result = [];
            allScores.reduce(function (res, value) {
                if (!res[value.projectType]) {
                    res[value.projectType] = {
                        projectType: value.projectType,
                        value: 0,
                        responsesCount: 0,
                    };
                    result.push(res[value.projectType]);
                }
                res[value.projectType].value +=
                    value.value;
                res[value.projectType].responsesCount = res[value.projectType].responsesCount + 1
                return res;
            }, {});

            result.sort((item1, item2) => item1.value / item1.responsesCount > item2.value / item2.responsesCount ? -1 : item1.value / item1.responsesCount < item2.value / item2.responsesCount ? 1 : 0)

            setScoresByProjectType(result.map(item => item.value / item.responsesCount))
            setLabelsByProjectType(result.map(item => item.projectType))

            result = [];
            allScores.reduce(function (res, value) {
                if (!res[value.region]) {
                    res[value.region] = {
                        region: value.region,
                        value: 0,
                        responsesCount: 0,
                    };
                    result.push(res[value.region]);
                }
                res[value.region].value +=
                    value.value;
                res[value.region].responsesCount = res[value.region].responsesCount + 1
                return res;
            }, {});

            result.sort((item1, item2) => item1.value / item1.responsesCount > item2.value / item2.responsesCount ? -1 : item1.value / item1.responsesCount < item2.value / item2.responsesCount ? 1 : 0)

            setScoresByRegion(result.map(item => item.value / item.responsesCount))
            setLabelsByRegion(result.map(item => item.region))

            result = [];
            allScores.reduce(function (res, value) {
                if (!res[value.attribute]) {
                    res[value.attribute] = {
                        attribute: value.attribute,
                        value: 0,
                        responsesCount: 0,
                    };
                    result.push(res[value.attribute]);
                }
                res[value.attribute].value +=
                    value.value;
                res[value.attribute].responsesCount = res[value.attribute].responsesCount + 1
                return res;
            }, {});

            result.sort((item1, item2) => item1.value / item1.responsesCount > item2.value / item2.responsesCount ? -1 : item1.value / item1.responsesCount < item2.value / item2.responsesCount ? 1 : 0)

            setScoresByAttribute(result.map(item => item.value / item.responsesCount))
            setLabelsByAttribute(result.map(item => item.attribute))

    }, [surveyRecords, selectedProjectType, selectedLocation, selectedRegion, numberOfRecordsToShow, numberOfRecordsToShowAttributes, selectedCategory, selectedAttribute]);

    if (
        surveyRecords.length > 0 &&
        !loading
    ) {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-congress-blue"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-white"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="flex bg-slate-100 h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Occupant Survey Analysis" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' />

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-5">
                                    <div>
                                        <label>Region</label>
                                        <MultiSelect
                                            options={aggregateRegions(surveyRecords).map((item) => {
                                                return {
                                                    label: item.region,
                                                    value: item.region
                                                }
                                            })}
                                            value={selectedRegion}
                                            onChange={setSelectedRegion}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Project Type</label>
                                        <MultiSelect
                                            options={aggregateProjectTypes(surveyRecords).map((item) => {
                                                return {
                                                    label: item.projectType,
                                                    value: item.projectType
                                                }
                                            })}
                                            value={selectedProjectType}
                                            onChange={setSelectedProjectType}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Location </label>
                                        <MultiSelect
                                            options={aggregateLocations(surveyRecords).map((item) => {
                                                return {
                                                    label: item.location,
                                                    value: item.location
                                                }
                                            })}
                                            value={selectedLocation}
                                            onChange={setSelectedLocation}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Category</label>
                                        <MultiSelect
                                            options={aggregateCategories(surveyRecords).map((item) => {
                                                return {
                                                    label: item.category,
                                                    value: item.category
                                                }
                                            })}
                                            value={selectedCategory}
                                            onChange={setSelectedCategory}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Attribute</label>
                                        <MultiSelect
                                            options={aggregateAttributes(surveyRecords).map((item) => {
                                                return {
                                                    label: item.attribute,
                                                    value: item.attribute
                                                }
                                            })}
                                            value={selectedAttribute}
                                            onChange={setSelectedAttribute}
                                            labelledBy="Select"
                                        />
                                    </div>
                                   
                                </div>
                            </div>
                        </div>


                        <div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid gap-2 sm:gap-7 sm:grid-cols-4">

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Average Score: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={(
                                                        surveyRecordsFullyFiltered.map(item => item.value).reduce(
                                                            (prev, curr) => Number(prev) + Number(curr),
                                                            0
                                                        ) / surveyRecordsFullyFiltered.length
                                                    ).toFixed(2)}
                                                    separator=","
                                                    decimals={2}
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                                        </svg>

                                            </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                    <div>
                                            <div className="text-sm text-gray-500 ">Number of Responses: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={aggregateRespondents(surveyRecordsFullyFiltered).length}
                                                    separator=","
                                                    // decimals={2}
                                                /></div>
                                            </div>
                                        </div>
                                    <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                    </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <ReactApexChart options={treeMapOptions} series={treeMapScores} type="treemap" height={700} />
                        </div> */}

                        {/* <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <ReactApexChart options={treeMapOptionsByCategory} series={treeMapScoresByCategory} type="treemap" height={700} />
                        </div> */}

                        {/* <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart5} options={pieOptions} />
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart5} options={barOptionsHorizontal} />
                            </div>
                        </div> */}
                        
                        {/* <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-3">
                                    <div className="text-center">
                                        <br></br>
                                    <Button text="Top 10" onClick={() => setNumberOfRecordsToShow(10)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button  text="Top 20" onClick={() => setNumberOfRecordsToShow(20)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button text="Top 50" onClick={() => setNumberOfRecordsToShow(50)} />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {numberOfRecordsToShow===50 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart7b} options={pieOptionsByCategory} height={400}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7} options={barOptionsHorizontalByCategory} height={400}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShow===20 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart7b} options={pieOptionsByCategory} height={300}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7} options={barOptionsHorizontalByCategory} height={300}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShow===10 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart7b} options={pieOptionsByCategory} height={200}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7} options={barOptionsHorizontalByCategory} height={200}/>
                            </div>
                        </div>
                        }

                        {/* <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-3">
                                    <div className="text-center">
                                        <br></br>
                                    <Button text="Top 10" onClick={() => setNumberOfRecordsToShow(10)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button  text="Top 20" onClick={() => setNumberOfRecordsToShow(20)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button text="Top 50" onClick={() => setNumberOfRecordsToShow(50)} />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {numberOfRecordsToShow===50 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart6b} options={pieOptionsByLocation} height={400}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart6} options={barOptionsHorizontalByLocation} height={400}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShow===20 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart6b} options={pieOptionsByLocation} height={300}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart6} options={barOptionsHorizontalByLocation} height={300}/>
                            </div>
                        </div>
                        }
                        {numberOfRecordsToShow===10 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart6b} options={pieOptionsByLocation} height={200}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart6} options={barOptionsHorizontalByLocation} height={200}/>
                            </div>
                        </div>
                        }

                        {/* <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-3">
                                    <div className="text-center">
                                        <br></br>
                                    <Button text="Top 10" onClick={() => setNumberOfRecordsToShow(10)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button  text="Top 20" onClick={() => setNumberOfRecordsToShow(20)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button text="Top 50" onClick={() => setNumberOfRecordsToShow(50)} />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {numberOfRecordsToShow===50 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart8} options={pieOptionsByRegion} height={400}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart8} options={barOptionsHorizontalByRegion} height={400}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShow===20 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart8} options={pieOptionsByRegion} height={300}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart8} options={barOptionsHorizontalByRegion} height={300}/>
                            </div>
                        </div>
                        }
                        {numberOfRecordsToShow===10 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart8} options={pieOptionsByRegion} height={200}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart8} options={barOptionsHorizontalByRegion} height={200}/>
                            </div>
                        </div>
                        }

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-4">
                                    <div className="text-center">
                                        <br></br>
                                    <Button text="Top 5" onClick={() => setNumberOfRecordsToShowAttributes(5)} />
                                    </div>
                                    <div className="text-center">
                                        <br></br>
                                    <Button text="Top 10" onClick={() => setNumberOfRecordsToShowAttributes(10)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button  text="Top 20" onClick={() => setNumberOfRecordsToShowAttributes(20)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button text="Top 50" onClick={() => setNumberOfRecordsToShowAttributes(50)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {numberOfRecordsToShowAttributes===50 &&
                        <div className="grid lg:grid-cols-1 grid-cols-1">

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart9} options={barOptionsHorizontalByAttribute} height={400}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShowAttributes===20 &&
                        <div className="grid lg:grid-cols-1 grid-cols-1">

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart9} options={barOptionsHorizontalByAttribute} height={300}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShowAttributes===10 &&
                        <div className="grid lg:grid-cols-1 grid-cols-1">

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart9} options={barOptionsHorizontalByAttribute} height={200}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShowAttributes===5 &&
                        <div className="grid lg:grid-cols-1 grid-cols-1">

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart9} options={barOptionsHorizontalByAttribute} height={100}/>
                            </div>
                        </div>
                        }


                        {/* <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart8} options={pieOptionsByRegion} />
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart8} options={barOptionsHorizontalByRegion} />
                            </div>
                        </div> */}

                        <br></br>
                        <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(surveyRecordsFullyFiltered)} />

                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                    <Header title="Occupant Survey Analysis" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' />
                        <p>Data is loading...</p>
                    </div>

                </div>
            </div>
        );
    }
};

export default DashboardOccupantSatisfaction;
