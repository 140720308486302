import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Sidebar from '../UI/Sidebar'
import ReactSelect from 'react-select'
import ProgressBar from '../UI/ProgressBar'
import { ExportToCsv } from 'export-to-csv'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import AuthService from '../../services/AuthService'
import Moment from 'react-moment'

const ProjectsTrackerActivities = () => {

    const [activities, setActivities] = useState([])
    const [activitiesFiltered, setActivitiesFiltered] = useState([])

    const [users, setUsers] = useState([])

    const [services, setServices] = useState([])
    const [timesheets, setTimesheets] = useState([])
    const [projects, setProjects] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)

    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [servicesLoading, setServicesLoading] = useState(true)
    const [projectsLoading, setProjectsLoading] = useState(true)

    const [selectedService, setSelectedService] = useState('All')
    const [selectedProjectName, setSelectedProjectName] = useState('All')
    const [selectedProjectNumber, setSelectedProjectNumber] = useState('All')
    const [selectedWorkStage, setSelectedWorkStage] = useState('All')
    const [selectedActivity, setSelectedActivity] = useState('All')
    const [selectedTaskNotes, setSelectedTaskNotes] = useState('All')
    const [selectedDepartment, setSelectedDepartment] = useState('All')
    const [selectedUser, setSelectedUser] = useState('All')

    const moment = require('moment');

    let date = new Date()
    let dayOfMonth = date.getDate().toString().length === 2 ? date.getDate() : "0" + date.getDate()
    let monthOfYear = (date.getMonth()+1).toString().length === 2 ? date.getMonth()+1 : "0" + (date.getMonth()+1)

    const [fromActivityDate, setFromActivityDate] = useState(moment(date.getFullYear() + "-" + monthOfYear + "-" + dayOfMonth).subtract(90, 'days').locale('en').format("YYYY-MM-DD"))
    const [toActivityDate, setToActivityDate] = useState(date.getFullYear() + "-" + monthOfYear + "-" + dayOfMonth)

    const [earliestDatePulledDown, setEarliestDatePulledDown] = useState(moment(date.getFullYear() + "-" + monthOfYear + "-" + dayOfMonth).subtract(90, 'days').locale('en').format("YYYY-MM-DD"))

    const [sortedField, setSortedField] = useState(null);
    const [sortedOrder, setSortedOrder] = useState(null);


    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const navigate = useNavigate()

    const viewProject = (id) => {
        window.open(`/viewClientProject/${id}`);
    }

    function stringToDate(_date, _format, _delimiter) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
        return formatedDate;
    }

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)
    const getUserProperties = async () => {

        // console.log("getUserProperties")

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const noAccess = () => {

        if (company && role && !userPropertiesLoading) {
            // Since admins can have both roles and users only have 1 role
            // we have to exclude if this person has an admin role as well
            // noAccess is going to be true if the person is not admin
            
            const admin = [2001, 2002, 2003, 2004]
            const notadmin = !admin.includes(role)
            return notadmin
        }
    }

    const arrayToCsv = (data) => {


        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Activities Tracker',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data)

    }

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
    }

    const prev100Records = () => {
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }
    }

    const next100Records = () => {

        if (lastRecord < activitiesFiltered.length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

    }

    const clearFilters = () => {

        setSelectedService('All')
        setSelectedProjectName('All')
        setSelectedProjectNumber('All')
        setSelectedWorkStage('All')
        setSelectedActivity('All')
        setSelectedTaskNotes('All')
        setSelectedDepartment('All')
        const admin = [2001, 2002, 2003, 2004]
        const notadmin = !admin.includes(role)
        if (notadmin===false){
            setSelectedUser('All')
        }        
        setSortedField('projectNumber')
        setSortedOrder('asc')
        setFromActivityDate('')
        setToActivityDate('')

    }

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: val.service,
                });
            };
            return acc;
        }, []);
    };

    const aggregateProjectNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectName === val.projectName);
            if (index === -1) {
                acc.push({
                    projectName: val.projectName,
                });
            };
            return acc.sort((item1, item2) => item1.projectName < item2.projectName ? -1 : 0)
        }, []);
    };

    const aggregateProjectNumberArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectNumber === val.projectNumber);
            if (index === -1) {
                acc.push({
                    projectNumber: val.projectNumber,
                });
            };
            return acc.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)
        }, []);
    };

    const aggregateActivityArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.activity === val.activity);
            if (index === -1) {
                acc.push({
                    activity: val.activity,
                });
            };
            return acc.sort((item1, item2) => item1.activity < item2.activity ? -1 : 0)
        }, []);
    };

    const aggregateTaskNotesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.timesheetNotes === val.timesheetNotes);
            if (index === -1) {
                acc.push({
                    timesheetNotes: val.timesheetNotes,
                });
            };
            return acc.sort((item1, item2) => item1.timesheetNotes < item2.timesheetNotes ? -1 : 0)
        }, []);
    };

    const aggregateWorkStagesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.workStage === val.workStage);
            if (index === -1) {
                if (val.workStage.length > 0) {
                    acc.push({
                        workStage: val.workStage
                    });
                }
            };
            return acc;
        }, []);
    };

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department,
                });
            };
            return acc.sort((item1, item2) => item1.department < item2.department ? -1 : 0)
        }, []);
    };

    const aggregateUsersArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.user === val.user);
            if (index === -1) {
                acc.push({
                    user: val.user,
                });
            };
            return acc.sort((item1, item2) => item1.user < item2.user ? -1 : 0)
        }, []);
    };

    const fetchMoreData = async () => {

        setLoading(true)

        // let date = new Date()

        // setFromActivityDate(moment(date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate()).subtract(daysWorthOfData, 'days').locale('en').format("YYYY-MM-DD"))
        // setToActivityDate(date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate())

        await axios.post(process.env.REACT_APP_API_URL + '/timesheets_by_date', {startdate: fromActivityDate, enddate: toActivityDate})
            .then((res) => {
                setTimesheets(res.data.filter(item => item.timesheetDate > "2022-08-29"))
                setEarliestDatePulledDown(fromActivityDate)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }

    useEffect(() => {

        //let date = new Date()

        // setFromActivityDate(moment(date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate()).subtract(90, 'days').locale('en').format("YYYY-MM-DD"))
        // setToActivityDate(date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate())
        // setEarliestDatePulledDown(moment(date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate()).subtract(90, 'days').locale('en').format("YYYY-MM-DD"))

        // console.log(dayOfMonth)
        // console.log(fromActivityDate)
        // console.log(toActivityDate)
        // console.log(earliestDatePulledDown)

        // let date = new Date()
        // console.log(date.getMonth())
        // console.log(date.getMonth().toString().length)
        
        axios.get(process.env.REACT_APP_API_URL + '/projects')
            .then((res) => {
                setProjects(res.data.filter((item) => item.toBeDeleted === "NO"))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setProjectsLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/projects_services')
            .then((res) => {
                setServices(res.data.filter((item) => item.toBeDeleted === "NO"))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setServicesLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                setSelectedUser({ value: res.data.find(item => item.emailId === AuthService.getCurrentUserName()).firstName + " " + res.data.find(item => item.emailId === AuthService.getCurrentUserName()).lastName, label: res.data.find(item => item.emailId === AuthService.getCurrentUserName()).firstName + " " + res.data.find(item => item.emailId === AuthService.getCurrentUserName()).lastName })
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });


        axios.post(process.env.REACT_APP_API_URL + '/timesheets_by_date', {startdate: fromActivityDate, enddate: toActivityDate})
            .then((res) => {
                setTimesheets(res.data.filter(item => item.timesheetDate > "2022-08-29"))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

            // console.log("initial useEffect")

    }, [])

    useEffect(() => {

        if (projects.length > 0 && services.length > 0 && timesheets.length > 0 && users.length > 0 && !loading && !usersLoading && !servicesLoading && !projectsLoading) {

            var fullTimesheetDataSet = []
            var i

            for (i = 0; i < timesheets.length; i++) {

                if (services.find(item => item.id === timesheets[i].serviceId).activities.length > 0 && timesheets[i].timesheetMonday + timesheets[i].timesheetTuesday + timesheets[i].timesheetWednesday + timesheets[i].timesheetThursday + timesheets[i].timesheetFriday + timesheets[i].timesheetSaturday + timesheets[i].timesheetSunday > 0) {

                    // Un-comment the below console logs to debug if an activity has been somehow deleted
                    // console.log(timesheets[i].timesheetDate)
                    // console.log(users.find(item => item.id === timesheets[i].userId).firstName + ' ' + users.find(item => item.id === timesheets[i].userId).lastName)
                    // console.log(projects.find(item => item.id === timesheets[i].projectId).projectNumber)

                    fullTimesheetDataSet.push({
                        uniqueKey: uuidv4(),
                        projectId: projects.find(item => item.id === timesheets[i].projectId).id,
                        department: services.find(item => item.id === timesheets[i].serviceId).department,
                        projectNumber: projects.find(item => item.id === timesheets[i].projectId).projectNumber,
                        projectName: projects.find(item => item.id === timesheets[i].projectId).projectName,
                        workStage: services.find(item => item.id === timesheets[i].serviceId).workStage,
                        service: services.find(item => item.id === timesheets[i].serviceId).service,
                        activity: services.find(item => item.id === timesheets[i].serviceId).activities.find(item => item.activityId === timesheets[i].activityId).activity,
                        date: timesheets[i].timesheetDate,
                        actualHours: Number(timesheets[i].timesheetMonday) + Number(timesheets[i].timesheetTuesday) + Number(timesheets[i].timesheetWednesday) + Number(timesheets[i].timesheetThursday) + Number(timesheets[i].timesheetFriday) + Number(timesheets[i].timesheetSaturday) + Number(timesheets[i].timesheetSunday),
                        estimatedHours: projects.find(item => item.id === timesheets[i].projectId).projectNumber === 0 ? 0 : services.find(item => item.id === timesheets[i].serviceId).activities.find(item => item.activityId === timesheets[i].activityId).estimatedHours,                
                        user: users.find(item => item.id === timesheets[i].userId).firstName + ' ' + users.find(item => item.id === timesheets[i].userId).lastName,
                        timesheetNotes: timesheets[i].timesheetNotes
                    });

                }

            }

            // console.log(fullTimesheetDataSet.filter(item => item.projectId === "63088f888a43b5042478a8eb"))

            // console.log("dataset creation useEffect")

            setActivities(fullTimesheetDataSet.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : item1.projectNumber > item2.projectNumber ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : item1.user < item2.user ? -1 : item1.user > item2.user ? 1 : item1.date < item2.date ? -1 : item1.date > item2.date ? 1 : 0))
            setActivitiesFiltered(fullTimesheetDataSet.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : item1.projectNumber > item2.projectNumber ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : item1.user < item2.user ? -1 : item1.user > item2.user ? 1 : item1.date < item2.date ? -1 : item1.date > item2.date ? 1 : 0))
        
        }

    }, [projects, services, timesheets, users, loading, usersLoading, projectsLoading, servicesLoading])

    useEffect(() => {

        if (activities.length > 0 && projects.length > 0 && services.length > 0 && timesheets.length > 0 && users.length > 0 && !loading && !usersLoading && !servicesLoading && !projectsLoading) {

        setActivitiesFiltered(activities)

        if (selectedService !== "All") {
            setActivitiesFiltered(activities => activities.filter((item) => item.service === selectedService.value))
        }

        if (selectedProjectName !== "All") {
            setActivitiesFiltered(activities => activities.filter((item) => item.projectName === selectedProjectName.value))
        }

        if (selectedProjectNumber !== "All") {
            setActivitiesFiltered(activities => activities.filter((item) => item.projectNumber === selectedProjectNumber.value))
        }

        if (selectedWorkStage !== "All") {
            setActivitiesFiltered(activities => activities.filter((item) => item.workStage === selectedWorkStage.value))
        }

        if (selectedActivity !== "All") {
            setActivitiesFiltered(activities => activities.filter((item) => item.activity === selectedActivity.value))
        }

        if (selectedTaskNotes !== "All") {
            setActivitiesFiltered(activities => activities.filter((item) => item.timesheetNotes === selectedTaskNotes.value))
        }

        if (selectedUser !== "All") {
            setActivitiesFiltered(activities => activities.filter((item) => item.user === selectedUser.value))
        }

        if (selectedDepartment !== "All") {
            setActivitiesFiltered(activities => activities.filter((item) => item.department === selectedDepartment.value))
        }

        if (fromActivityDate !== "") {
            setActivitiesFiltered(activities => activities.filter((item) => stringToDate(item.date, "YYYY-MM-DD", "-") >= stringToDate(fromActivityDate, "YYYY-MM-DD", "-")))
        }

        if (toActivityDate !== "") {
            setActivitiesFiltered(activities => activities.filter((item) => stringToDate(item.date, "YYYY-MM-DD", "-") <= stringToDate(toActivityDate, "YYYY-MM-DD", "-")))
        }

        // console.log("filtering useEffect")
    }

    }, [selectedService, selectedProjectName, selectedProjectNumber, activities, selectedWorkStage, selectedActivity, selectedTaskNotes, selectedUser, selectedDepartment, fromActivityDate, toActivityDate])

    useEffect(() => {

        setUserPropertiesLoading(true)
        getUserProperties()

    }, [])

    if (activities.length > 0 && !loading && !usersLoading && !projectsLoading && !servicesLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Projects" section="Activities Tracker" showUserMenu='true' />

                            <hr></hr>
                            <br></br>


                            <div className="flex items-center">
                                <div className="w-full mx-auto m-5 px-3">
                                    <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8">
                                        <div>
                                            <p className="text-xs">Service</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedService}
                                                onChange={setSelectedService}
                                                options={aggregateServicesArray(activities).map((item) => {
                                                    return {
                                                        label: item.service,
                                                        value: item.service
                                                    }
                                                })} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Project Name</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedProjectName}
                                                onChange={setSelectedProjectName}
                                                options={aggregateProjectNameArray(activities).map((item) => {
                                                    return {
                                                        label: item.projectName,
                                                        value: item.projectName
                                                    }
                                                })} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Project Number</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedProjectNumber}
                                                onChange={setSelectedProjectNumber}
                                                options={aggregateProjectNumberArray(activities).map((item) => {
                                                    return {
                                                        label: item.projectNumber,
                                                        value: item.projectNumber
                                                    }
                                                })} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Work Stage</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedWorkStage}
                                                onChange={setSelectedWorkStage}
                                                options={aggregateWorkStagesArray(activities).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : 0).map((item) => {
                                                    return {
                                                        label: item.workStage,
                                                        value: item.workStage
                                                    }
                                                })} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Activity</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedActivity}
                                                onChange={setSelectedActivity}
                                                options={aggregateActivityArray(activities).sort((item1, item2) => item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0).map((item) => {
                                                    return {
                                                        label: item.activity,
                                                        value: item.activity
                                                    }
                                                })} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Task / Notes</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedTaskNotes}
                                                onChange={setSelectedTaskNotes}
                                                options={aggregateTaskNotesArray(activities).sort((item1, item2) => item1.timesheetNotes < item2.timesheetNotes ? -1 : item1.timesheetNotes > item2.timesheetNotes ? 1 : 0).map((item) => {
                                                    return {
                                                        label: item.timesheetNotes,
                                                        value: item.timesheetNotes
                                                    }
                                                })} />
                                        </div>
                                        <div>
                                            <p className="text-xs">Department</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedDepartment}
                                                onChange={setSelectedDepartment}
                                                options={aggregateDepartmentsArray(activities).sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : 0).map((item) => {
                                                    return {
                                                        label: item.department,
                                                        value: item.department
                                                    }
                                                })} />
                                        </div>
                                        <div>
                                            <p className="text-xs">User</p>
                                            <ReactSelect
                                                styles={styles}
                                                value={selectedUser}
                                                isDisabled={noAccess()}
                                                onChange={setSelectedUser}
                                                options={aggregateUsersArray(activities).sort((item1, item2) => item1.user < item2.user ? -1 : item1.user > item2.user ? 1 : 0).map((item) => {
                                                    return {
                                                        label: item.user,
                                                        value: item.user
                                                    }
                                                })} />
                                        </div>
                                        {/* <div className="pl-3">
                                            <p className="text-xs font-bold">Filters</p>
                                            <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center">
                                <div className="w-full mx-auto m-5 px-3">
                                    <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8">
                                        <div>
                                            <p className="text-xs">From Activity Date</p>
                                            <input className="text-xs border-2 p-2 w-full" type="date" placeholder="Enter Activity Date Here" value={fromActivityDate} onChange={(e) => setFromActivityDate(e.target.value)} />
                                        </div>
                                        <div>
                                            <p className="text-xs">To Activity Date</p>
                                            <input className="text-xs border-2 p-2 w-full" type="date" placeholder="Enter Activity Date Here" value={toActivityDate} onChange={(e) => setToActivityDate(e.target.value)} />
                                        </div>                                         
                                        <div className="pl-3">
                                            <p className="text-xs font-bold">Filters</p>
                                            <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                        </div>
                                        {/* <div>
                                            <p className="text-xs">Days' worth of data</p>
                                            <input className="text-xs border-2 p-2 w-full" type="text" placeholder="Enter Number of days to fetch" value={daysWorthOfData} onChange={(e) => setDaysWorthOfData(e.target.value)} />
                                        </div>  */}
                                    </div>
                                </div>
                            </div>

                            <br></br>

                            <hr></hr>

                            <br></br>

                            {fromActivityDate >= earliestDatePulledDown &&
                            <div className="flex flex-col py-2">
                                <div className="overflow-x-auto items-holder-8A">
                                    <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                        <div className="shadow-md shadow-slate-300 rounded-2xl">
                                            <table className="objects-8A min-w-full divide-y divide-gray-200 table-fixed" style={{ width: '2000px' }}>

                                                <thead className="bg-pacific-blue-50 sticky top-0">
                                                    <tr>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '400px' }}> Department</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '120px' }}> <button className="uppercase" type="button" onClick={() => { setSortedField('projectNumber'); sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc') }}>{sortedField === 'projectNumber' && sortedOrder === 'asc' ? 'Project Number ↑' : sortedField === 'projectNumber' && sortedOrder === 'desc' ? 'Project Number ↓' : 'Project Number ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '400px' }}> Project Name</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '300px' }}> Service</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '200px' }}> Work Stage</th>
                                                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '200px' }}> <button className="uppercase" type="button" onClick={() => { setSortedField('projectStatus'); sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc') }}>{sortedField === 'projectStatus' && sortedOrder === 'asc' ? 'Project Status ↑' : sortedField === 'projectStatus' && sortedOrder === 'desc' ? 'Project Status ↓' : 'Project Status ⇅'}</button></th> */}
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '300px' }}> Activity</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '200px' }}> Task / Notes</th>
                                                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '170px' }}> <button className="uppercase" type="button" onClick={() => { setSortedField('activityValue'); sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc') }}>{sortedField === 'activityValue' && sortedOrder === 'asc' ? 'Activity Value ↑' : sortedField === 'activityValue' && sortedOrder === 'desc' ? 'Activity Value ↓' : 'Activity Value ⇅'}</button></th> */}
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Estimated Hours</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Actual Hours</th>
                                                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '200px' }}> % Complete</th> */}
                                                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Activity Complete</th> */}
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> User</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '200px' }}> Date</th>

                                                    </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {activitiesFiltered.slice(firstRecord, lastRecord).map((activity) => (
                                                        <tr key={activity.uniqueKey}>
                                                            {/* <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {users.find(user => user.id === activity.serviceManager).firstName + ' ' + users.find(user => user.id === activity.serviceManager).lastName}</td> */}
                                                            {/* <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {activity.projectNumber}</td> */}
                                                            <td className="px-4 md:px-6  py-2"> {activity.department}</td>
                                                            <td onClick={() => viewProject(activity.projectId)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                                                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                                            </svg> {activity.projectNumber}</td>
                                                            <td className="px-4 md:px-6  py-2"> {activity.projectName}</td>
                                                            <td className="px-4 md:px-6  py-2"> {activity.service}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {activity.workStage}</td>
                                                            {/* <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {activity.projectStatus}</td> */}
                                                            <td className="px-4 md:px-6  py-2"> {activity.activity}</td>
                                                            <td className="px-4 md:px-6  py-2"> {activity.timesheetNotes}</td>
                                                            {/* <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {activity.projectCurrency + " " + internationalNumberFormat.format(activity.activityValue.toFixed(2))}</td> */}
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {activity.estimatedHours.toFixed(2)}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {activity.actualHours}</td>
                                                            {/* <td className="px-4 md:px-2  py-2"> <ProgressBar bgcolor={activity.activityComplete === "NO" && ((activity.actualHours / activity.estimatedHours) * 100) >= 100 ? "#ff0000" : "#0091ca"} completed={isNaN(activity.actualHours / activity.estimatedHours) ? 100 : Math.round(((activity.actualHours / activity.estimatedHours) * 100)) > 100 ? 100 : Math.round(((activity.actualHours / activity.estimatedHours) * 100))} completedLabel={isNaN(activity.actualHours / activity.estimatedHours) ? 100 : Math.round(((activity.actualHours / activity.estimatedHours) * 100)) === Infinity ? 100 : Math.round(((activity.actualHours / activity.estimatedHours) * 100))}></ProgressBar></td> */}
                                                            {/* <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {activity.activityComplete}</td> */}
                                                            <td className="px-4 md:px-6  py-2"> {activity.user}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {activity.date}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid gap-7 sm:grid-cols-3 ">
                                <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                                <div className="text-sm text-gray-500 text-center pt-2 ">{firstRecord + " to " + lastRecord + " of " + activitiesFiltered.length}</div>
                                <Button text="Next 100 >>" onClick={() => next100Records()}></Button>
                            </div>
                            </div>
                            }

                            {fromActivityDate >= earliestDatePulledDown &&

                            <div>
                                <div className="flex items-center bg-slate-100">
                                    <div className="w-full px-5 mx-auto my-5">
                                        <div className="grid gap-7 sm:grid-cols-5 ">
                                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                                <div>
                                                    <div className="text-sm text-gray-500 ">Total Hours</div>
                                                    <div className="flex items-center pt-1">
                                                        {/* <div className="text-xl font-medium text-indigo-500 "><p className="my-1">£ {internationalNumberFormat.format(servicesFiltered.filter(item => item.projectCurrency === "£").map(item => item.totalFee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div> */}
                                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{internationalNumberFormat.format(activitiesFiltered.map(item => item.actualHours).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                                    </div>
                                                </div>
                                                <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <br></br>
                            {fromActivityDate < earliestDatePulledDown && 
                                <Button color="bg-bondi-blue" text="Fetch Data" onClick={() => fetchMoreData()} />
                            }

                            {fromActivityDate >= earliestDatePulledDown && 
                                <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(activitiesFiltered)} />
                            }
                            <br></br>

                        </div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Projects" section="Activities Tracker" showUserMenu='true' />
                        <br></br>
                        <hr></hr>
                        <br></br>
                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7">

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                </div>
                            </div>
                        </div>

                        <br></br>
                        <hr></hr>
                        <br></br>

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div role="status" className="  bg-white w-full p-4 space-y-4 border border-slate-200 divide-y divide-slate-200 rounded-2xl shadow-slate-300 shadow-md animate-pulse ">

                                        <div className="flex items-center justify-between">
                                            <div>
                                                <div className="h-5 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-5 bg-slate-300 rounded-full w-48"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )

    }

}

export default ProjectsTrackerActivities
