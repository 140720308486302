import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import Options from '../UI/Options'
import ReactSelect from 'react-select';
import ModalEmailInfo from '../UI/ModalEmailInfo'
import AuthService from '../../services/AuthService';

const ListContacts = () => {

    const [contacts, setContacts] = useState([])
    const [contactsFiltered, setContactsFiltered] = useState([])
    const [clients, setClients] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)
    const [clientsLoading, setClientsLoading] = useState(true)

    const [selectedClient, setSelectedClient] = useState("All") 

    const [selectedContact, setSelectedContact] = useState("All") 

    const [contactsToSelect, setContactsToSelect] = useState([]) 
    const [clientsToSelect, setClientsToSelect] = useState([]) 

    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)

    const [showModalEmailInfo, setShowModalEmailInfo] = useState(false)
    const [dataToSendToModalInfo, setDataToSendToModalInfo] = useState('')

    const navigate = useNavigate()

    const styles = {
        option: provided => ({
          ...provided,
          color: 'black'
        }),
        control: provided => ({
          ...provided,
          color: 'black',
          width: 160,
          fontSize: 13
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'black'
        }),
        menu: provided => ({
          ...provided,
          color: 'black',
          width: 160,
          fontSize: 13
        }),
      }

    const prev100Records = () => {
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }
    }

    const next100Records = () => {

        if (lastRecord < contactsFiltered.length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

    }

    const clearFilters = () => {

        setSelectedClient('All')
        setSelectedContact('All')
        setContactsFiltered(contacts)

    }

    const getEmails = async (contactEmailAddress) => {
    
        setDataToSendToModalInfo(contactEmailAddress);            
        setShowModalEmailInfo(true);

    }

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/clients')
            .then((res) => {
                setClients(res.data)
                setClientsToSelect(res.data.map((item) => {
                    return {
                    label: item.clientName,
                    value: item.id
                    }
                }))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setClientsLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/contacts')
            .then((res) => {
                setContacts(res.data.sort((item1, item2) => item1.contactName.toLowerCase() < item2.contactName.toLowerCase() ? -1 : 0))
                setContactsFiltered(res.data.sort((item1, item2) => item1.contactName.toLowerCase() < item2.contactName.toLowerCase() ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    useEffect(() => {

        setContactsFiltered(contacts)
        setFirstRecord(0)
        setLastRecord(100)

        if (clients.length > 0) {
    
            if (selectedClient !== "All") {
                setContactsFiltered(contactsFiltered => contactsFiltered.filter((item) => item.clientId === selectedClient.value))
                setSelectedContact("All")

                setContactsToSelect(contacts.filter(item => item.clientId === selectedClient.value).map((item) => {
                    return {
                    label: item.contactName + " (" + clients.find(client => client.id === item.clientId).clientName + ")",
                    value: item.contactName
                    }
                }))

            } else {
                setContactsToSelect(contacts.map((item) => {
                    return {
                    label: item.contactName + " (" + clients.find(client => client.id === item.clientId).clientName + ")",
                    value: item.contactName
                    }
                }))
            }
        }
      
      }, [selectedClient, contacts, clients])


      useEffect(() => {

        if (selectedContact !== "All") {
            setContactsFiltered(contactsFiltered => contactsFiltered.filter((item) => item.contactName === selectedContact.value))
        }

        setFirstRecord(0)
        setLastRecord(100)
      
      }, [selectedContact, contacts])
    

    const deleteContact = (id) => {
        // axios.delete(process.env.REACT_APP_API_URL + '/contacts/' + id)
        // setContacts(contacts.filter((contact) => contact.id !== id))
    }

    const editContact = (id) => {
        navigate(`/addOrUpdateContact/${id}`);
    }

    if (clients.length > 0 && contacts.length > 0 && !loading && !clientsLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Contact" section="Select Contact" showUserMenu='true' />

                        {/* <div className="flex flex-wrap">
                        <table className="objects-8A">

                            <thead>
                                <tr>
                                    <th> Client</th>
                                    <th> Contact Name</th>
                                    <th> Contact Email</th>
                                    <th> Contact Telephone Number</th>
                                    <th> Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {contacts.map((contact) => (
                                    <tr key={contact.id}>
                                        <td> {clients.find(client => client.id === contact.clientId).clientName} </td>
                                        <td> {contact.contactName}</td>
                                        <td> {contact.contactEmail}</td>
                                        <td> {contact.contactTelephoneNumber}</td>
                                        <td> <Button color="black" text="Edit" onClick={() => editContact(contact.id)} /><Button color="black" text="Delete" onClick={() => deleteContact(contact.id)} /></td>
                                    </tr>
                                ))}
                            </tbody>

                            </table>
                        </div> */}

                        <br></br>

                        {/* <Button color="bg-bondi-blue" text="Add New Contact" onClick={() => navigate('/addOrUpdateContact/_add')} /> */}

                        {/* <br></br> */}

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                <div className="grid grid-cols-3 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8 pt-1">
                                    <div className="pb-2">
                                        <Button color="bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                    </div>

                                    <div>
                                        <Button color="bg-bondi-blue" text=" << Prev 100 Records" onClick={() => prev100Records(firstRecord)} />
                                    </div>

                                    <div className="text-right">
                                        <Button color="bg-bondi-blue" text="Next 100 Records >>" onClick={() => next100Records(firstRecord)} />
                                    </div>
                                    </div>

                                    <div className="shadow-md shadow-slate-300 rounded-2xl">
                                        <table className="objects-8A min-w-full divide-y divide-gray-200">

                                            <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                                <tr>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Name<ReactSelect
                                                        styles={styles}
                                                        value={selectedClient}
                                                        onChange={setSelectedClient}
                                                        options={clientsToSelect} /></th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Contact Name<ReactSelect
                                                        styles={styles}
                                                        value={selectedContact}
                                                        onChange={setSelectedContact}
                                                        options={contactsToSelect} /></th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase"> Contact Email</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase"> Contact Telephone No</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase"> Check Emails</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right"> Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {contactsFiltered.slice(firstRecord,lastRecord).map((contact) => (
                                                    <tr key={contact.id}>
                                                        <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {clients.find(client => client.id === contact.clientId).clientName}</td>
                                                        <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {contact.contactName}</td>
                                                        <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {contact.contactEmail}</td>
                                                        <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {contact.contactTelephoneNumber}</td>
                                                        <td className="text-center whitespace-nowrap"><Button className='bg-bondi-blue' text="Check proposals@" onClick={() => getEmails(contact.contactEmail)}/></td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right">
                                                            {/* <Options edit={`/addOrUpdateContact/${contact.clientId}/${contact.id}`} deleteFunction={deleteContact} deleteObject={contact.id}></Options> */}
                                                            <Options edit={`/addOrUpdateContact/${contact.clientId}/${contact.id}`}></Options>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                <ModalEmailInfo data={dataToSendToModalInfo} toFrom = {true} userEmailAddress={AuthService.getCurrentUserName()} onClose={() => setShowModalEmailInfo(false)} show={showModalEmailInfo} title={"Project Related Email Communication to / from " + dataToSendToModalInfo} />
                            </div>

                        </div>

                        <br></br>
                        {/* <Button color="bg-bondi-blue" text="Add New Contact" onClick={() => navigate('/addOrUpdateContact/_add')} /> */}
                    </div>
                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Contact" section="Select Contact" showUserMenu='true' />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListContacts
