import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import authHeader from './services/AuthHeader';

const localStorageValue = JSON.parse(localStorage.getItem("user"))
let authTokenRequest;

// This function makes a call to get the auth token
// or it returns the same promise as an in-progress call to get the auth token
function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = getIsTokenValid();
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }
  return authTokenRequest;
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}
function getIsTokenValid() {
  axios.post(process.env.REACT_APP_API_URL + "/token", {}, { headers: authHeader() })
    .then((response) => {
      if (response.status === 200) {
        return localStorageValue.access_token
      }
      else {
        return false
      }
    })
}

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const token = JSON.parse(localStorage.getItem("user"))?.access_token
    if (token) {
      // console.log(config)
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)
axios.interceptors.response.use(
  response => {
    return response
  },
  function (err) {
    const error = err.response;
    if (
      error.status === 422 || error.status === 401// &&
    ) {
      window.location.href = process.env.REACT_APP_URL + '/login'
    }

    return Promise.reject(error)
  }
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
