import Button from './Button'
import axios from 'axios'
import authHeader from '../../services/AuthHeader'
import ReactSelect from 'react-select';
import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

const Modal = (props) => {

    const [activityId, setActivityId] = useState(0)
    const [activity, setActivity] = useState('')
    const [activityOwner, setActivityOwner] = useState('6303a09f7ba3f683fef4e25a')
    const [activityComplete, setActivityComplete] = useState('NO')
    const [activityToBeInvoiced, setActivityToBeInvoiced] = useState('')
    const [canIssueDeliverables, setCanIssueDeliverables] = useState('')
    const [contractAgreed, setContractAgreed] = useState('')
    const [estimatedHours, setEstimatedHours] = useState(0)
    const [fee, setFee] = useState(0)
    const [hourlyRate, setHourlyRate] = useState(0)
    const [cost, setCost] = useState(0)
    const [costCategory, setCostCategory] = useState('')
    const [loading, setLoading] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL;

    const onSubmit = async (e) => {
        e.preventDefault()

        if (activity) {

            var newProjectsServices

            setLoading(true)
    
            await axios.get(API_URL + '/projects_services/' + props.serviceIdToUpdate, { headers: authHeader() })
            .then((response) => {
                newProjectsServices = response.data
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
            
            updateForm(newProjectsServices)

        }
    }

    const updateForm = async (form) => {

        if (props.modalUse==="Add") {
            
            await props.arrayToUpdate.push({activityId, activity, activityOwner, activityComplete, activityToBeInvoiced, canIssueDeliverables, contractAgreed, estimatedHours, fee, hourlyRate, cost, costCategory})
            await form.activities.push({activityId, activity, activityOwner, activityComplete, activityToBeInvoiced, canIssueDeliverables, contractAgreed, estimatedHours, fee, hourlyRate, cost, costCategory})

        }else if (props.modalUse==="Edit") {

            //find and update the activity
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).activityId = activityId
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).activity = activity
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).activityOwner = activityOwner
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).activityComplete = activityComplete
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).activityToBeInvoiced = activityToBeInvoiced
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).canIssueDeliverables = canIssueDeliverables
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).contractAgreed = contractAgreed
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).estimatedHours = estimatedHours
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).fee = fee
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).hourlyRate = hourlyRate
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).cost = cost
            form.activities.find(activity => activity.activityId === props.activityIdToUpdate).costCategory = costCategory

            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).activityId = activityId
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).activity = activity
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).activityOwner = activityOwner
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).activityComplete = activityComplete
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).activityToBeInvoiced = activityToBeInvoiced
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).canIssueDeliverables = canIssueDeliverables
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).contractAgreed = contractAgreed
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).estimatedHours = estimatedHours
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).fee = fee
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).hourlyRate = hourlyRate
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).cost = cost
            props.arrayToUpdate.find(activity => activity.activityId === props.activityIdToUpdate).costCategory = costCategory

            props.onClose()

        }

        await axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + props.serviceIdToUpdate, form)
        await props.onClose()

    }

    useEffect(() => {

        if (props.modalUse==="Add") {
            
            setActivityId(uuidv4())
            setActivity('')
            setActivityOwner('6303a09f7ba3f683fef4e25a')
            setActivityComplete('NO')
            setActivityToBeInvoiced('')
            setCanIssueDeliverables('')
            setContractAgreed('')
            setEstimatedHours(0)
            setHourlyRate(85)
            setFee(0)
            setCost(0)
            setCostCategory('')

        }else if (props.modalUse==="Edit") {

            var selectedActivity = props.arrayToUpdate.filter(activity => activity.activityId === props.activityIdToUpdate)
            setActivityId(selectedActivity[0].activityId)
            setActivity(selectedActivity[0].activity)
            setActivityOwner(selectedActivity[0].activityOwner)
            setActivityComplete(selectedActivity[0].activityComplete)
            setActivityToBeInvoiced(selectedActivity[0].activityToBeInvoiced)
            setCanIssueDeliverables(selectedActivity[0].canIssueDeliverables)
            setContractAgreed(selectedActivity[0].contractAgreed)
            setEstimatedHours(selectedActivity[0].estimatedHours)
            setHourlyRate(selectedActivity[0].hourlyRate)
            setFee(selectedActivity[0].fee)
            setCost(selectedActivity[0].cost)
            setCostCategory(selectedActivity[0].costCategory)

        }

    }, [props.show])

    useEffect(() => {

        setFee(Number((estimatedHours * hourlyRate).toFixed(2)))

    }, [estimatedHours, hourlyRate])

    if (!props.show) {
        return null
    }
    return (

        <div className="update-value-modal z-50">
            <div className="update-value-modal-content">
                <div className="update-value-modal-header">
                    <p className="update-value-modal-title text-congress-blue text-xl">{props.title}</p>
                </div>
                <div className="update-value-modal-body">
                <form className="add-form-8A" onSubmit={onSubmit}>
                <div className="flex pb-2">
                            <label className="pr-2">Activity:</label>
                            <input className="w-full" type="text" placeholder="Enter Activity Here" value={activity} onChange={(e) => setActivity(e.target.value)} />
                        </div>
                        <div className="flex pb-2">
                            <label className="pr-2">Activity Owner: </label>
                            <select value={activityOwner} onChange={(e) => setActivityOwner(e.target.value)}>
                                {props.users.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0).map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>

                        <div className="flex pb-2">
                            <label className="pr-2">Activity Complete: </label>
                            <select value={activityComplete} onChange={(e) => setActivityComplete(e.target.value)}>
                                {/* <option value="">Please Select...</option> */}
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>
                        <div className="flex pb-2">
                            <label className="pr-2">Estimated Hours:</label>
                            <input readOnly={cost !== 0 ? true : false} type="number" placeholder="Enter estimatedHours Here" value={estimatedHours} onChange={(e) => setEstimatedHours(Number(e.target.value))} />
                        </div>

                        <div className="flex pb-2">
                            <label className="pr-2">Hourly Rate:</label>
                            <input readOnly={true} type="number" placeholder="Enter hourlyRate Here" value={hourlyRate} onChange={(e) => setHourlyRate(Number(e.target.value))} />
                        </div>

                        <div className="flex pb-2">
                            <label className="pr-2">Fee:</label>
                            <input readOnly={true} type="number" placeholder="Enter fee Here" value={fee} onChange={(e) => setFee(Number(e.target.value))} />
                        </div>

                        <div className="flex pb-2">
                            <label className="pr-2">Cost:</label>
                            <input readOnly={estimatedHours !== 0 ? true : false} type="number" placeholder="Enter cost Here" value={cost} onChange={(e) => {setCost(Number(e.target.value)); setFee(Number(e.target.value))}} />
                        </div>
                        <div className="flex pb-10">
                            <label className="pr-2">Cost Category: </label>
                            <select value={costCategory} onChange={(e) => setCostCategory(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Certifications Fees">Certifications Fees</option>
                                <option value="Shipping and Couriers">Shipping and Couriers</option>
                                <option value="Data Search">Data Search</option>
                                <option value="Labs and Testing">Labs and Testing</option>
                                <option value="Travel and Accomodation">Travel</option>
                                <option value="Other">Other</option>
                                <option value="Freelance Environmental Consultants">Freelance Environmental Consultants</option>
                            </select>
                        </div>
                        <div>
                        {(loading) &&                        
                            <input disabled={true} type="submit" value="Please Wait..." className="cursor-pointer inline-block rounded-2xl w-full text-white bg-bondi-blue" />
                        }
                        {(!loading) &&
                            <input type="submit" value="Save" className="cursor-pointer inline-block rounded-2xl w-full text-white bg-bondi-blue" />
                        }
                        <button className="bg-congress-blue inline-block rounded-2xl w-full text-white mt-2 bottom-0" onClick={props.onClose}>Cancel</button></div>
                    </form>
                </div>
                
            </div>
        </div>

    )

}

export default Modal