import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Sidebar from '../UI/Sidebar'
import { ExportToCsv } from 'export-to-csv'
import { useNavigate } from 'react-router-dom'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import { MultiSelect } from "react-multi-select-component";
import { useLayersControlElement } from 'react-leaflet/LayersControl'
import {Table} from "antd"
import '../UI/antd.css';


const ProjectsTrackerOpportunities = () => {

    const [services, setServices] = useState([])
    const [servicesAmended, setServicesAmended] = useState([])
    const [servicesFiltered, setServicesFiltered] = useState([])
    const [clients, setClients] = useState([])
    const [currentId, setCurrentId] = useState('')

    const [invoices, setInvoices] = useState([])

    const [timesheets, setTimesheets] = useState([])

    const [servicesInvoiced, setServicesInvoiced] = useState([])

    const [users, setUsers] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)

    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [clientsLoading, setClientsLoading] = useState(true)
    const [invoicesLoading, setInvoicesLoading] = useState(true)
    const [servicesInvoicedLoading, setServicesInvoicedLoading] = useState(true)
    const [timesheetsLoading, setTimesheetsLoading] = useState(true)

    const [showWIP, setShowWIP] = useState(false)

    const [selectedServiceManager, setSelectedServiceManager] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [selectedProjectManager, setSelectedProjectManager] = useState([])
    const [selectedProjectName, setSelectedProjectName] = useState([])
    const [selectedDealName, setSelectedDealName] = useState([])
    const [selectedProjectNumber, setSelectedProjectNumber] = useState([])
    const [selectedServiceStatus, setSelectedServiceStatus] = useState([])
    const [selectedProjectStatus, setSelectedProjectStatus] = useState([])
    const [selectedBillingPlanStatus, setSelectedBillingPlanStatus] = useState([])
    const [selectedWorkStage, setSelectedWorkStage] = useState([])
    const [selectedServiceCreatedMonth, setSelectedServiceCreatedMonth] = useState([])
    const [selectedServiceWinPendingMonth, setSelectedServiceWinPendingMonth] = useState([])
    const [selectedServiceLiveMonth, setSelectedServiceLiveMonth] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState([])
    const [selectedBDNotes, setSelectedBDNotes] = useState([])
    const [selectedProjectDescriptions, setSelectedProjectDescriptions] = useState([])
    const [selectedClientBDStatus, setSelectedClientBDStatus] = useState([])
    const [selectedCustomerPo, setSelectedCustomerPo] = useState([])
    const [selectedServiceInvoiceClient, setSelectedServiceInvoiceClient] = useState([])
    const [selectedProjectClient, setSelectedProjectClient] = useState([])
    const [selectedProjectBillingPlanAndFeesChecked, setSelectedProjectBillingPlanAndFeesChecked] = useState([])
    const [selectedProjectInvoicingChecked, setSelectedProjectInvoicingChecked] = useState([])

    const [serviceManagersToSelect, setServiceManagersToSelect] = useState([])
    const [servicesToSelect, setServicesToSelect] = useState([])
    const [projectManagersToSelect, setProjectManagersToSelect] = useState([])
    const [projectNamesToSelect, setProjectNamesToSelect] = useState([])
    const [BDNotesToSelect, setBDNotesToSelect] = useState([])
    const [projectDescriptionsToSelect, setProjectDescriptionsToSelect] = useState([])
    const [clientBDStatusesToSelect, setClientBDStatusesToSelect] = useState([])
    const [dealNamesToSelect, setDealNamesToSelect] = useState([])
    const [projectNumbersToSelect, setProjectNumbersToSelect] = useState([])
    const [serviceStatusesToSelect, setServiceStatusesToSelect] = useState([])
    const [projectStatusesToSelect, setProjectStatusesToSelect] = useState([])
    const [billingPlanStatusesToSelect, setBillingPlanStatusesToSelect] = useState([])
    const [workStagesToSelect, setWorkStagesToSelect] = useState([])
    const [serviceCreatedMonthsToSelect, setServiceCreatedMonthsToSelect] = useState([])
    const [serviceWinPendingMonthsToSelect, setServiceWinPendingMonthsToSelect] = useState([])
    const [serviceLiveMonthsToSelect, setServiceLiveMonthsToSelect] = useState([])
    const [departmentsToSelect, setDepartmentsToSelect] = useState([])
    const [customerPosToSelect, setCustomerPosToSelect] = useState([])
    const [serviceInvoiceClientsToSelect, setServiceInvoiceClientsToSelect] = useState([])
    const [projectClientsToSelect, setProjectClientsToSelect] = useState([])
    const [projectBillingPlanAndFeesCheckedToSelect, setProjectBillingPlanAndFeesCheckedToSelect] = useState([])
    const [projectInvoicingCheckedToSelect, setProjectInvoicingCheckedToSelect] = useState([])

    const [totalFeeValueGBP, setTotalFeeValueGBP] = useState(0)
    const [totalFeeValueEUR, setTotalFeeValueEUR] = useState(0)
    const [totalOnBillingPlanValueGBP, setTotalOnBillingPlanValueGBP] = useState(0)
    const [totalOnBillingPlanValueEUR, setTotalOnBillingPlanValueEUR] = useState(0)

    const [sortedField, setSortedField] = useState('projectNumber');
    const [sortedOrder, setSortedOrder] = useState('desc');

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')

    const currentMonth = date => {

        const dateConverted = new Date(date)

        const yyyy = dateConverted.getFullYear();
        let mm = dateConverted.getMonth() + 1; // Months start at 0!

        if (mm < 10) mm = '0' + mm;

        const formattedMonth = yyyy + '-' + mm

        return formattedMonth
    }

    // const internationalNumberFormat = new Intl.NumberFormat('en-US')
    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    // Step 1: Group data by department and service and sum the fees
    const groupedData = [];
    const serviceLiveMap = {}; 

    servicesFiltered.forEach(item => {
    const key = `${item.dealName}`;

    if (serviceLiveMap[key]) {
        // Add to existing total fees
        serviceLiveMap[key].totalFees += item.activities.reduce((total, activity) => total + activity.fee, 0);
        serviceLiveMap[key].totalCosts += item.activities.reduce((total, activity) => total + activity.cost, 0);
        serviceLiveMap[key].totalConsultancyFees += item.activities.reduce((total, activity) => total + activity.fee, 0) - item.activities.reduce((total, activity) => total + activity.cost, 0);
        if (!serviceLiveMap[key].serviceStatus.includes(item.serviceStatus)) {serviceLiveMap[key].serviceStatus = serviceLiveMap[key].serviceStatus + "; " + item.serviceStatus}
        if (serviceLiveMap[key].serviceCreatedDate < item.serviceCreatedDate) {
            serviceLiveMap[key].serviceCreatedDate = item.serviceCreatedDate
        }
        if (serviceLiveMap[key].serviceLiveDate < item.serviceLiveDate) {
            serviceLiveMap[key].serviceLiveDate = item.serviceLiveDate
        }
        if (serviceLiveMap[key].serviceWinPendingDate < item.serviceWinPendingDate) {
            serviceLiveMap[key].serviceWinPendingDate = item.serviceWinPendingDate
        }
    } else {
        // Create a new entry
        const totalFees = item.activities.reduce((total, activity) => total + activity.fee, 0);
        const totalCosts = item.activities.reduce((total, activity) => total + activity.cost, 0);
        const totalConsultancyFees = totalFees - totalCosts
        serviceLiveMap[key] = { 
        dealName: item.dealName, 
        clientName: item.dealName === "" ? "" : item.clientName, 
        clientContactName: item.dealName === "" ? "" : item.clientContactName,
        serviceStatus: item.serviceStatus,
        serviceCreatedDate: item.serviceCreatedDate,
        serviceWinPendingDate: item.serviceWinPendingDate,
        serviceLiveDate: item.serviceLiveDate,
        projectNumber: item.dealName === "" ? "" : item.projectNumber,
        projectId: item.projectId,
        projectLocation: item.projectLocation,
        firstService: item.id,
        totalFees ,
        totalCosts,
        totalConsultancyFees
        };
        groupedData.push(serviceLiveMap[key]);
    }
    });

    const navigate = useNavigate()

    const arrayToCsv = (data) => {

        var dataToExport = []
        var j
        var projectManagerFirstName, projectManagerLastName, serviceManagerFirstName, serviceManagerLastName

        for (j = 0; j < data.length; j++) {

            projectManagerFirstName = users.find(item => item.id === data[j].projectManager).firstName
            projectManagerLastName = users.find(item => item.id === data[j].projectManager).lastName
            serviceManagerFirstName = users.find(item => item.id === data[j].serviceManager).firstName
            serviceManagerLastName = users.find(item => item.id === data[j].serviceManager).lastName

            dataToExport.push({
                ...data[j],
                "Project Manager First Name" : projectManagerFirstName,
                "Project Manager Last Name" : projectManagerLastName,
                "Service Manager First Name" : serviceManagerFirstName,
                "Service Manager Last Name" : serviceManagerLastName
            })
            
        }
        var newArrayToExport = []
        var lineItem

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Services Tracker',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        for (lineItem = 0; lineItem < dataToExport.length; lineItem++) {

            newArrayToExport.push({...dataToExport[lineItem], activities: []})

        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(newArrayToExport)

    }

    // const serviceIncludedInBillingPlan = (id) => {

    //     //checks to see if serviceId is present on an invoice and
    //     //checks total fee by service against total value added to billing plan by service

    //     var invoice
    //     var invoiceLine
    //     var totalFeeOnBillingPlan = 0

    //     for (invoice = 0; invoice < invoices.length; invoice++) {
    //         for (invoiceLine = 0; invoiceLine < invoices[invoice].invoiceLines.length; invoiceLine++) {

    //             if (
    //                 invoices[invoice].invoiceLines[invoiceLine].serviceId === id
    //             ) {
    //                 totalFeeOnBillingPlan = totalFeeOnBillingPlan + invoices[invoice].invoiceLines[invoiceLine].value
    //             }
    //         }
    //     }

    //     return totalFeeOnBillingPlan

    // }

    // const calculcateAndShowWIP = () => {

    //     if (showWIP) {

    //         setShowWIP(false)

    //     } else {

    //         if (window.confirm('This may take several minutes to calculate.  Would you like to proceed?')) {

    //             setShowWIP(true)

    //         }
    //     }

    // }

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            // width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            // width: 160,
            fontSize: 13
        }),
    }

    const prev100Records = () => {
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }

    }

    const next100Records = () => {

        if (lastRecord <= groupedData.length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

    }

    const clearFilters = () => {

        setSelectedServiceManager(aggregateServiceManagerArray(services).map((item) => {
            return {
                label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                value: item.serviceManager
            }
        }))


        setSelectedDealName(aggregateDealNameArray(services.filter(item => item.dealName !== "")).map((item) => {
            return {
                label: item.dealName,
                value: item.dealName
            }
        }))

        setSelectedService(aggregateServicesArray(services).map((item) => {
            return {
                label: item.service,
                value: item.service
            }
        }))

        setSelectedProjectManager(aggregateProjectManagerArray(services).map((item) => {
            return {
                label: item.projectManager === "" ? "" : users.find(user => user.id === item.projectManager).firstName + " " + users.find(user => user.id === item.projectManager).lastName,
                value: item.projectManager
            }
        }))

        setSelectedProjectName(aggregateProjectNameArray(services).map((item) => {
            return {
                label: item.projectName,
                value: item.projectName
            }
        }))

        setSelectedProjectNumber(aggregateProjectNumberArray(services).map((item) => {
            return {
                label: item.projectNumber,
                value: item.projectNumber
            }
        }))

        setSelectedServiceStatus(aggregateServiceStatusArray(services.filter(item => item.serviceStatus.slice(0,5) === "Quote")).map((item) => {
            return {
                label: item.serviceStatus,
                value: item.serviceStatus
            }
        }))

        setSelectedProjectStatus(aggregateProjectStatusArray(services).map((item) => {
            return {
                label: item.projectStatus,
                value: item.projectStatus
            }
        }))

        // setSelectedBillingPlanStatus([{label: "On Billing Plan", value: "On Billing Plan"}, {label: "Not On Billing Plan", value: "Not On Billing Plan"}, {label: "Partially On Billing Plan", value: "Partially On Billing Plan"}, {label: "Too Much On Billing Plan", value: "Too Much On Billing Plan"}])

        setSelectedWorkStage(aggregateWorkStagesArray(services).map((item) => {
            return {
                label: item.workStage,
                value: item.workStage
            }
        }))
        setSelectedServiceCreatedMonth(aggregateServiceCreatedMonthsArray(services).map((item) => {
            return {
                label: item.serviceCreatedMonth,
                value: item.serviceCreatedMonth
            }
        }))

        setSelectedServiceWinPendingMonth(aggregateServiceWinPendingMonthsArray(services).map((item) => {
            return {
                label: item.serviceWinPendingMonth,
                value: item.serviceWinPendingMonth
            }
        }))

        setSelectedServiceLiveMonth(aggregateServiceLiveMonthsArray(services).map((item) => {
            return {
                label: item.serviceLiveMonth,
                value: item.serviceLiveMonth
            }
        }))

        setSelectedDepartment(aggregateDepartmentsArray(services).map((item) => {
            return {
                label: item.department,
                value: item.department
            }
        }))

        setSelectedBDNotes(aggregateBDNotesArray(services).map((item) => {
            return {
                label: item.projectLocation,
                value: item.projectLocation
            }
        }))

        setSelectedProjectDescriptions(aggregateProjectDescriptionsArray(services).map((item) => {
            return {
                label: item.projectDescription,
                value: item.projectDescription
            }
        }))

        setSelectedClientBDStatus(aggregateClientBDStatusesArray(services).map((item) => {
            return {
                label: item.clientBDStatus,
                value: item.clientBDStatus
            }
        }))

        setSelectedCustomerPo(aggregateCustomerPoArray(services).map((item) => {
            return {
                label: item.customerPo,
                value: item.customerPo
            }
        }))

        setSelectedServiceInvoiceClient(aggregateServiceInvoiceClientArray(services).map((item) => {
            return {
                label: item.serviceInvoiceClient === "" ? "" : clients.find(client => client.id === item.serviceInvoiceClient).clientName,
                value: item.serviceInvoiceClient
            }
        }))

        setSelectedProjectClient(aggregateProjectClientArray(services).map((item) => {
            return {
                label: item.clientName,
                value: item.clientName
            }
        }))

        setSelectedProjectBillingPlanAndFeesChecked(aggregateBillingPlanAndFeesCheckedArray(services).map((item) => {
            return {
                label: item.projectBillingPlanAndFeesChecked === "NO" ? "NO" : users.find(user => user.id === item.projectBillingPlanAndFeesChecked).firstName + " " + users.find(user => user.id === item.projectBillingPlanAndFeesChecked).lastName,
                value: item.projectBillingPlanAndFeesChecked
            }
        }))

        setSelectedProjectInvoicingChecked(aggregateProjectInvoicingCheckedArray(services).map((item) => {
            return {
                label: item.projectInvoicingChecked === "NO" ? "NO" : users.find(user => user.id === item.projectInvoicingChecked).firstName + " " + users.find(user => user.id === item.projectInvoicingChecked).lastName,
                value: item.projectInvoicingChecked
            }
        }))
        
        setSortedField('projectNumber')
        setSortedOrder('desc')

    }

    const aggregateDealNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.dealName === val.dealName);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        dealName: val.dealName
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateProjectManagerArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectManager === val.projectManager);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        projectManager: val.projectManager
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateServiceManagerArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceManager === val.serviceManager);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        serviceManager: val.serviceManager
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: val.service,
                });
            };
            return acc;
        }, []);
    };

    const aggregateProjectNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectName === val.projectName);
            if (index === -1) {
                acc.push({
                    projectName: val.projectName,
                });
            };
            return acc.sort((item1, item2) => item1.projectName < item2.projectName ? -1 : 0)
        }, []);
    };

    const aggregateProjectNumberArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectNumber === val.projectNumber);
            if (index === -1) {
                acc.push({
                    projectNumber: val.projectNumber,
                });
            };
            return acc.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)
        }, []);
    };

    const aggregateWorkStagesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.workStage === val.workStage);
            if (index === -1) {
                if (val.workStage.length > 0) {
                    acc.push({
                        workStage: val.workStage
                    });
                }
            };
            return acc;
        }, []);
    };

    const aggregateServiceCreatedMonthsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceCreatedMonth === val.serviceCreatedMonth);
            if (index === -1) {
                if (val.serviceCreatedMonth.length > 0) {
                    acc.push({
                        serviceCreatedMonth: val.serviceCreatedMonth
                    });
                }
            };
            return acc;
        }, []);
    };

    const aggregateServiceWinPendingMonthsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceWinPendingMonth === val.serviceWinPendingMonth);
            if (index === -1) {
                // if (val.serviceWinPendingMonth.length > 0) {
                    acc.push({
                        serviceWinPendingMonth: val.serviceWinPendingMonth
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateServiceLiveMonthsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceLiveMonth === val.serviceLiveMonth);
            if (index === -1) {
                // if (val.serviceWinPendingMonth.length > 0) {
                    acc.push({
                        serviceLiveMonth: val.serviceLiveMonth
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department,
                });
            };
            return acc.sort((item1, item2) => item1.department < item2.department ? -1 : 0)
        }, []);
    };

    const aggregateBDNotesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectLocation === val.projectLocation);
            if (index === -1) {
                acc.push({
                    projectLocation: val.projectLocation,
                });
            };
            return acc.sort((item1, item2) => item1.projectLocation < item2.projectLocation ? -1 : 0)
        }, []);
    };

    const aggregateProjectDescriptionsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectDescription === val.projectDescription);
            if (index === -1) {
                acc.push({
                    projectDescription: val.projectDescription,
                });
            };
            return acc.sort((item1, item2) => item1.projectDescription < item2.projectDescription ? -1 : 0)
        }, []);
    };

    const aggregateClientBDStatusesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientBDStatus === val.clientBDStatus);
            if (index === -1) {
                acc.push({
                    clientBDStatus: val.clientBDStatus,
                });
            };
            return acc.sort((item1, item2) => item1.clientBDStatus < item2.clientBDStatus ? -1 : 0)
        }, []);
    };

    const aggregateCustomerPoArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.customerPo === val.customerPo);
            if (index === -1) {
                // if (val.customerPo.length > 0) {
                    acc.push({
                        customerPo: val.customerPo
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateServiceStatusArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceStatus === val.serviceStatus);
            if (index === -1) {
                acc.push({
                    serviceStatus: val.serviceStatus,
                });
            };
            return acc.sort((item1, item2) => item1.serviceStatus < item2.serviceStatus ? -1 : 0)
        }, []);
    };

    const aggregateProjectStatusArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectStatus === val.projectStatus);
            if (index === -1) {
                acc.push({
                    projectStatus: val.projectStatus,
                });
            };
            return acc.sort((item1, item2) => item1.projectStatus < item2.projectStatus ? -1 : 0)
        }, []);
    };

    const aggregateServiceInvoiceClientArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceInvoiceClient === val.serviceInvoiceClient);
            if (index === -1) {
                acc.push({
                    serviceInvoiceClient: val.serviceInvoiceClient,
                });
            };
            return acc;
        }, []);
    };

    const aggregateProjectClientArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientName === val.clientName);
            if (index === -1) {
                acc.push({
                    clientName: val.clientName,
                });
            };
            return acc;
        }, []);
    };

    const aggregateBillingPlanAndFeesCheckedArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectBillingPlanAndFeesChecked === val.projectBillingPlanAndFeesChecked);
            if (index === -1) {
                acc.push({
                    projectBillingPlanAndFeesChecked: val.projectBillingPlanAndFeesChecked,
                });
            };
            return acc;
        }, []);
    };

    const aggregateProjectInvoicingCheckedArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectInvoicingChecked === val.projectInvoicingChecked);
            if (index === -1) {
                acc.push({
                    projectInvoicingChecked: val.projectInvoicingChecked,
                });
            };
            return acc;
        }, []);
    };

    const viewProject = (id) => {
        window.open(`/viewClientProject/${id}`);
    }

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userDepartment, setUserDepartment] = useState("")
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
                setUserDepartment(response.data.department)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        console.log("useEffect to load the data")

        // axios.get(process.env.REACT_APP_API_URL + '/services_invoiced')
        //     .then((res) => res.data)
        //     .then((res) => {
        //         setServicesInvoiced(res)
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     }).finally(() => {
        //         setServicesInvoicedLoading(false);
        //         // console.log("services_invoiced loaded")
        //     });

        axios.get(process.env.REACT_APP_API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
                // console.log("users loaded")
            });

        // axios.get(process.env.REACT_APP_API_URL + '/invoices')
        //     .then((res) => {
        //         setInvoices(res.data)
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     }).finally(() => {
        //         setInvoicesLoading(false);
        //         // console.log("invoices loaded")
        //     });

        // setSelectedServiceStatus({ value: "Live", label: "Live" })

        axios.get(process.env.REACT_APP_API_URL + '/clients')
        .then((res) => {
            setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0))
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setClientsLoading(false);
            // console.log("clients loaded")
        });

        // axios.get(process.env.REACT_APP_API_URL + '/timesheets')
        // .then((res) => {
        //     setTimesheets(res.data)
        // })
        // .catch((err) => {
        //     console.log(err);
        // }).finally(() => {
        //     setTimesheetsLoading(false);
        //     // console.log("timesheets loaded")
        // });

    }, [])

    useEffect(() => {

        if (users.length > 0 && !usersLoading && !clientsLoading && clients.length > 0) {

            console.log("useEffect to get services report")

            // console.log("get services report")

        axios.get(process.env.REACT_APP_API_URL + '/services_report')
            .then((res) => res.data)
            .then((res) => {
                console.log(res)
                setServices(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => Number(item1.projectNumber) >Number(item2.projectNumber) ? -1 : 0))
                setServicesFiltered(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => Number(item1.projectNumber) > Number(item2.projectNumber) ? -1 : 0))
                setSelectedProjectManager(aggregateProjectManagerArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                    return {
                        label: item.projectManager === "" ? "" : users.find(user => user.id === item.projectManager).firstName + " " + users.find(user => user.id === item.projectManager).lastName,
                        value: item.projectManager
                    }
                }))
                setSelectedServiceManager(aggregateServiceManagerArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                    return {
                        label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                        value: item.serviceManager
                    }
                }))
                setSelectedProjectName(aggregateProjectNameArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectName < item2.projectN ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectName,
                        value: item.projectName
                    }
                }))
                setSelectedDealName(aggregateDealNameArray(res.filter((item) => item.dealName !== "" && item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.dealName < item2.dealName ? -1 : 0)).map((item) => {
                    return {
                        label: item.dealName,
                        value: item.dealName
                    }
                }))
                setSelectedProjectNumber(aggregateProjectNumberArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                    return {
                        label: item.projectNumber,
                        value: item.projectNumber
                    }
                }))
                setSelectedServiceStatus(aggregateServiceStatusArray(res.filter((item) => item.serviceStatus.slice(0,5) === "Quote" && item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceStatus,
                        value: item.serviceStatus
                    }
                }))
                setSelectedProjectStatus(aggregateProjectStatusArray( res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectStatus,
                        value: item.projectStatus
                    }
                }))

                // setSelectedBillingPlanStatus([{label: "On Billing Plan", value: "On Billing Plan"}, {label: "Not On Billing Plan", value: "Not On Billing Plan"}, {label: "Partially On Billing Plan", value: "Partially On Billing Plan"}, {label: "Too Much On Billing Plan", value: "Too Much On Billing Plan"}])

                setSelectedWorkStage(aggregateWorkStagesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.workStage,
                        value: item.workStage
                    }
                }))

                setSelectedService(aggregateServicesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.service,
                        value: item.service
                    }
                }))

                setSelectedServiceCreatedMonth(aggregateServiceCreatedMonthsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceCreatedMonth,
                        value: item.serviceCreatedMonth
                    }
                }))

                setSelectedServiceWinPendingMonth(aggregateServiceWinPendingMonthsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceWinPendingMonth,
                        value: item.serviceWinPendingMonth
                    }
                }))

                setSelectedServiceLiveMonth(aggregateServiceLiveMonthsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceLiveMonth,
                        value: item.serviceLiveMonth
                    }
                }))

                // setSelectedServiceCreatedMonth([{label: currentMonth(Date()), value: currentMonth(Date())}])

                setSelectedDepartment(aggregateDepartmentsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.department,
                        value: item.department
                    }
                }))

                setSelectedBDNotes(aggregateBDNotesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectLocation,
                        value: item.projectLocation
                    }
                }))

                setSelectedProjectDescriptions(aggregateProjectDescriptionsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectDescription,
                        value: item.projectDescription
                    }
                }))

                setSelectedClientBDStatus(aggregateClientBDStatusesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.clientBDStatus,
                        value: item.clientBDStatus
                    }
                }))

                setSelectedCustomerPo(aggregateCustomerPoArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.customerPo,
                        value: item.customerPo
                    }
                }))

                setSelectedServiceInvoiceClient(aggregateServiceInvoiceClientArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceInvoiceClient === "" ? "" : clients.find(client => client.id === item.serviceInvoiceClient).clientName,
                        value: item.serviceInvoiceClient
                    }
                }))

                setSelectedProjectClient(aggregateProjectClientArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.clientName,
                        value: item.clientName
                    }
                }))

                setSelectedProjectBillingPlanAndFeesChecked(aggregateBillingPlanAndFeesCheckedArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectBillingPlanAndFeesChecked === "NO" ? "NO" : users.find(user => user.id === item.projectBillingPlanAndFeesChecked).firstName + " " + users.find(user => user.id === item.projectBillingPlanAndFeesChecked).lastName,
                        value: item.projectBillingPlanAndFeesChecked
                    }
                }))

                setSelectedProjectInvoicingChecked(aggregateProjectInvoicingCheckedArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectInvoicingChecked === "NO" ? "NO" : users.find(user => user.id === item.projectInvoicingChecked).firstName + " " + users.find(user => user.id === item.projectInvoicingChecked).lastName,
                        value: item.projectInvoicingChecked
                    }
                }))

                //*****************************************************************************************************************************

                setProjectManagersToSelect(aggregateProjectManagerArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                    return {
                        label: item.projectManager === "" ? "" : users.find(user => user.id === item.projectManager).firstName + " " + users.find(user => user.id === item.projectManager).lastName,
                        value: item.projectManager
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))
                setServiceManagersToSelect(aggregateServiceManagerArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                        value: item.serviceManager
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))
                setProjectNamesToSelect(aggregateProjectNameArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectName,
                        value: item.projectName
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))
                setDealNamesToSelect(aggregateDealNameArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.dealName < item2.dealName ? -1 : 0)).map((item) => {
                    return {
                        label: item.dealName,
                        value: item.dealName
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))
                setProjectNumbersToSelect(aggregateProjectNumberArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                    return {
                        label: item.projectNumber,
                        value: item.projectNumber
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))
                setServiceStatusesToSelect(aggregateServiceStatusArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceStatus,
                        value: item.serviceStatus
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))
                setProjectStatusesToSelect(aggregateProjectStatusArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectStatus,
                        value: item.projectStatus
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                // setBillingPlanStatusesToSelect([{label: "On Billing Plan", value: "On Billing Plan"}, {label: "Not On Billing Plan", value: "Not On Billing Plan"}, {label: "Partially On Billing Plan", value: "Partially On Billing Plan"}, {label: "Too Much On Billing Plan", value: "Too Much On Billing Plan"}])

                setWorkStagesToSelect(aggregateWorkStagesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.workStage,
                        value: item.workStage
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                setServicesToSelect(aggregateServicesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.service,
                        value: item.service
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                setServiceCreatedMonthsToSelect(aggregateServiceCreatedMonthsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceCreatedMonth,
                        value: item.serviceCreatedMonth
                    }
                }).sort((item1, item2) => item1.label + item1.label > item2.label + item2.label ? -1 : 0))

                setServiceWinPendingMonthsToSelect(aggregateServiceWinPendingMonthsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceWinPendingMonth,
                        value: item.serviceWinPendingMonth
                    }
                }).sort((item1, item2) => item1.label + item1.label > item2.label + item2.label ? -1 : 0))

                setServiceLiveMonthsToSelect(aggregateServiceLiveMonthsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceLiveMonth,
                        value: item.serviceLiveMonth
                    }
                }).sort((item1, item2) => item1.label + item1.label > item2.label + item2.label ? -1 : 0))

                // setSelectedServiceCreatedMonth([{label: currentMonth(Date()), value: currentMonth(Date())}])

                setDepartmentsToSelect(aggregateDepartmentsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.department,
                        value: item.department
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                setBDNotesToSelect(aggregateBDNotesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectLocation,
                        value: item.projectLocation
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                setProjectDescriptionsToSelect(aggregateProjectDescriptionsArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectDescription,
                        value: item.projectDescription
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))


                setClientBDStatusesToSelect(aggregateClientBDStatusesArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.clientBDStatus,
                        value: item.clientBDStatus
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                setCustomerPosToSelect(aggregateCustomerPoArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.customerPo,
                        value: item.customerPo
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                setServiceInvoiceClientsToSelect(aggregateServiceInvoiceClientArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.serviceInvoiceClient === "" ? "" : clients.find(client => client.id === item.serviceInvoiceClient).clientName,
                        value: item.serviceInvoiceClient
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                setProjectClientsToSelect(aggregateProjectClientArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.clientName,
                        value: item.clientName
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                setProjectBillingPlanAndFeesCheckedToSelect(aggregateBillingPlanAndFeesCheckedArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectBillingPlanAndFeesChecked === "NO" ? "NO" : users.find(user => user.id === item.projectBillingPlanAndFeesChecked).firstName + " " + users.find(user => user.id === item.projectBillingPlanAndFeesChecked).lastName,
                        value: item.projectBillingPlanAndFeesChecked
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                setProjectInvoicingCheckedToSelect(aggregateProjectInvoicingCheckedArray(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                    return {
                        label: item.projectInvoicingChecked === "NO" ? "NO" : users.find(user => user.id === item.projectInvoicingChecked).firstName + " " + users.find(user => user.id === item.projectInvoicingChecked).lastName,
                        value: item.projectInvoicingChecked
                    }
                }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
                // console.log("report loaded")
            });

        }

    }, [users, clients, usersLoading, clientsLoading])

    useEffect(() => {
        
        if (services.length > 0 && !loading) {
            console.log("useEffect to set filtered services")
            setServicesFiltered(services)

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceManager.find((itemB) => {
                    return itemA.serviceManager === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedService.find((itemB) => {
                    return itemA.service === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectManager.find((itemB) => {
                    return itemA.projectManager === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectName.find((itemB) => {
                    return itemA.projectName === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectNumber.find((itemB) => {
                    return itemA.projectNumber === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceStatus.find((itemB) => {
                    return itemA.serviceStatus === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectStatus.find((itemB) => {
                    return itemA.projectStatus === itemB.value;
                })
            }))

            // setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
            //     return selectedBillingPlanStatus.find((itemB) => {
            //         return itemA.billingPlanStatus === itemB.value;
            //     })
            // }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedWorkStage.find((itemB) => {
                    return itemA.workStage === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceCreatedMonth.find((itemB) => {
                    return itemA.serviceCreatedMonth === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceWinPendingMonth.find((itemB) => {
                    return itemA.serviceWinPendingMonth === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceLiveMonth.find((itemB) => {
                    return itemA.serviceLiveMonth === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedDepartment.find((itemB) => {
                    return itemA.department === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedBDNotes.find((itemB) => {
                    return itemA.projectLocation === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectDescriptions.find((itemB) => {
                    return itemA.projectDescription === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedClientBDStatus.find((itemB) => {
                    return itemA.clientBDStatus === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedCustomerPo.find((itemB) => {
                    return itemA.customerPo === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceInvoiceClient.find((itemB) => {
                    return itemA.serviceInvoiceClient === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectClient.find((itemB) => {
                    return itemA.clientName === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectInvoicingChecked.find((itemB) => {
                    return itemA.projectInvoicingChecked === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectBillingPlanAndFeesChecked.find((itemB) => {
                    return itemA.projectBillingPlanAndFeesChecked === itemB.value;
                })
            }))

            setServicesFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedDealName.find((itemB) => {
                    return itemA.dealName === itemB.value;
                })
            }))

            var servicesFilteredTemp = [...services]

            servicesFilteredTemp = servicesFilteredTemp.filter((itemA) => {
                return selectedServiceManager.find((itemB) => {
                    return itemA.serviceManager === itemB.value;
                })
            }).filter((itemA) => {
                return selectedService.find((itemB) => {
                    return itemA.service === itemB.value;
                })
            }).filter((itemA) => {
                return selectedProjectManager.find((itemB) => {
                    return itemA.projectManager === itemB.value;
                })
            }).filter((itemA) => {
                return selectedProjectName.find((itemB) => {
                    return itemA.projectName === itemB.value;
                })
            }).filter((itemA) => {
                return selectedProjectNumber.find((itemB) => {
                    return itemA.projectNumber === itemB.value;
                })
            }).filter((itemA) => {
                return selectedServiceStatus.find((itemB) => {
                    return itemA.serviceStatus === itemB.value;
                })
            }).filter((itemA) => {
                return selectedProjectStatus.find((itemB) => {
                    return itemA.projectStatus === itemB.value;
                })
            // }).filter((itemA) => {
            //     return selectedBillingPlanStatus.find((itemB) => {
            //         return itemA.billingPlanStatus === itemB.value;
            //     })
            }).filter((itemA) => {
                return selectedWorkStage.find((itemB) => {
                    return itemA.workStage === itemB.value;
                })
            }).filter((itemA) => {
                return selectedServiceCreatedMonth.find((itemB) => {
                    return itemA.serviceCreatedMonth === itemB.value;
                })
            }).filter((itemA) => {
                return selectedServiceWinPendingMonth.find((itemB) => {
                    return itemA.serviceWinPendingMonth === itemB.value;
                })
            }).filter((itemA) => {
                return selectedServiceLiveMonth.find((itemB) => {
                    return itemA.serviceLiveMonth === itemB.value;
                })
            }).filter((itemA) => {
                return selectedDepartment.find((itemB) => {
                    return itemA.department === itemB.value;
                })
            }).filter((itemA) => {
                return selectedBDNotes.find((itemB) => {
                    return itemA.projectLocation === itemB.value;
                })
            }).filter((itemA) => {
                return selectedProjectDescriptions.find((itemB) => {
                    return itemA.projectDescription === itemB.value;
                })
            }).filter((itemA) => {
                return selectedClientBDStatus.find((itemB) => {
                    return itemA.clientBDStatus === itemB.value;
                })
            }).filter((itemA) => {
                return selectedCustomerPo.find((itemB) => {
                    return itemA.customerPo === itemB.value;
                })
            }).filter((itemA) => {
                return selectedServiceInvoiceClient.find((itemB) => {
                    return itemA.serviceInvoiceClient === itemB.value;
                })
            }).filter((itemA) => {
                return selectedProjectClient.find((itemB) => {
                    return itemA.clientName === itemB.value;
                })
            }).filter((itemA) => {
                return selectedProjectInvoicingChecked.find((itemB) => {
                    return itemA.projectInvoicingChecked === itemB.value;
                })
            }).filter((itemA) => {
                return selectedProjectBillingPlanAndFeesChecked.find((itemB) => {
                    return itemA.projectBillingPlanAndFeesChecked === itemB.value;
                })
            }).filter((itemA) => {
                return selectedDealName.find((itemB) => {
                    return itemA.dealName === itemB.value;
                })
            })


            setTotalFeeValueGBP(servicesFilteredTemp.filter(item => item.projectCurrency === "£").map(item => item.totalFee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))
            setTotalFeeValueEUR(servicesFilteredTemp.filter(item => item.projectCurrency === "€").map(item => item.totalFee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))
            setTotalOnBillingPlanValueGBP(servicesFilteredTemp.filter(item => item.projectCurrency === "£").map(item => item.invoicedVal).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))
            setTotalOnBillingPlanValueEUR(servicesFilteredTemp.filter(item => item.projectCurrency === "€").map(item => item.invoicedVal).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))


            setFirstRecord(0)
            setLastRecord(100)

        }

        // setSortedField('projectNumber')
        // setSortedOrder('asc')


    }, [loading, selectedServiceManager, selectedService, selectedProjectManager, selectedProjectName, selectedProjectNumber, services, selectedServiceStatus, selectedProjectStatus, selectedBillingPlanStatus, selectedWorkStage, selectedServiceCreatedMonth, selectedServiceWinPendingMonth, selectedServiceLiveMonth, selectedDepartment, selectedBDNotes, selectedProjectDescriptions, selectedClientBDStatus, selectedCustomerPo, selectedServiceInvoiceClient, selectedProjectClient, selectedProjectBillingPlanAndFeesChecked, selectedProjectInvoicingChecked, selectedDealName])


    // useEffect(() => {


    //     if (services.length > 0 && timesheets.length > 0 && invoices.length > 0 && !loading) {

    //         // console.log("useEffect to calculate totals")
    //         // var service
    //         // var feeOnThisServiceId = 0
    //         // var status = "Not On Billing Plan"
    //         // var estimatedTotalHours = 0
    //         // var actualTotalHours = 0
    //         // var totalFeeEstimated = 0
    //         // var billedProgress = 0
    //         // var timeProgress = 0
    //         // var completionProgress = 0
    //         // var feesComplete = 0
    //         // var wip = 0
    //         // var wipPercentage = 0
    //         // var feesInvoicedAndIssued
    //         // var invoice, invoiceLine
    //         // var allInvoiceLines = []
    //         // var invoicedAndIssuedInvoices
    //         // var progressVsBill
    //         // var labourCostsOfTimeOverProgress
    //         var tempServices = [...services]

    //         // invoicedAndIssuedInvoices = invoices.filter(item => item.invoiceIssued === "YES")

    //         // for (invoice = 0; invoice < invoicedAndIssuedInvoices.length; invoice++) {

    //         //     for (invoiceLine = 0; invoiceLine < invoicedAndIssuedInvoices[invoice].invoiceLines.length; invoiceLine++) {

    //         //         allInvoiceLines.push({serviceId: invoicedAndIssuedInvoices[invoice].invoiceLines[invoiceLine].serviceId, invoicedAndIssuedVal: invoicedAndIssuedInvoices[invoice].invoiceLines[invoiceLine].value })

    //         //     }

    //         // }



    //         // for (service = 0; service < tempServices.length; service++) {

    //         //     feeOnThisServiceId = serviceIncludedInBillingPlan(tempServices[service].id)

    //         //     if (feeOnThisServiceId.toFixed(2) === tempServices[service].totalFee.toFixed(2)) {
    //         //         status = "On Billing Plan"
    //         //     }
    //         //     if (feeOnThisServiceId === 0 && tempServices[service].totalFee > 0) {
    //         //         status = "Not On Billing Plan"
    //         //     }
    //         //     if ((feeOnThisServiceId > 0 && Number(feeOnThisServiceId.toFixed(2)) < Number(tempServices[service].totalFee.toFixed(2)))) {
    //         //         status = "Partially On Billing Plan"
    //         //     }
    //         //     if (Number((feeOnThisServiceId.toFixed(2)) > Number(tempServices[service].totalFee.toFixed(2)))) {
    //         //         status = "Too Much On Billing Plan"
    //         //     }

    //         //     //Get how much has actually been invoiced (invoice ISSUED="YES") here!!!!!!!!

    //         //         if (allInvoiceLines.filter(item => item.serviceId === tempServices[service].id)) {
    //         //             feesInvoicedAndIssued = allInvoiceLines.filter(item => item.serviceId === tempServices[service].id).map(item => item.invoicedAndIssuedVal).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)
    //         //         } else {

    //         //             feesInvoicedAndIssued = 0.00
    
    //         //         }

    //         //     //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    //         //     //Code needed here to get percentage of fees marked as complete === YES vs percentage of fees marked as complete === NO


    //         //     if (showWIP) {

    //         //         // console.log("calculating WIP")

                
    //         //         feesComplete = tempServices[service].activities.filter(item => item.activityComplete === "YES").map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                    
    //         //         actualTotalHours = timesheets.filter(item => item.serviceId === tempServices[service].id).map(item => item.timesheetMonday + item.timesheetTuesday + item.timesheetWednesday + item.timesheetThursday + item.timesheetFriday + item.timesheetSaturday + item.timesheetSunday).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)
    //         //         estimatedTotalHours = tempServices[service].activities.map(item => item.estimatedHours).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)
    //         //         totalFeeEstimated = tempServices[service].activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)
                    
    //         //         if (feesComplete / tempServices[service].activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0) && isFinite(feesComplete / services[service].activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0))) {
    //         //             completionProgress = ((feesComplete / tempServices[service].activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2)
    //         //         } else {
    //         //             completionProgress = 0.00
    //         //         }

    //         //         if (feesInvoicedAndIssued / totalFeeEstimated && isFinite(feesInvoicedAndIssued / totalFeeEstimated)) {
    //         //             billedProgress = ((feesInvoicedAndIssued / totalFeeEstimated)*100).toFixed(2)
    //         //         } else {
    //         //             billedProgress = 0.00
    //         //         }
    //         //         if (actualTotalHours / estimatedTotalHours && isFinite(actualTotalHours / estimatedTotalHours)) {
    //         //             timeProgress = ((actualTotalHours / estimatedTotalHours)*100).toFixed(2)
    //         //         } else {
    //         //             timeProgress = 0.00
    //         //         }

    //         //         progressVsBill = completionProgress - billedProgress

    //         //         if (timeProgress < completionProgress) {
    //         //             labourCostsOfTimeOverProgress = 0
    //         //         } else {
    //         //             labourCostsOfTimeOverProgress = (timeProgress-completionProgress)*0.5
    //         //         }

    //         //         wip =(totalFeeEstimated*(progressVsBill/100))+(totalFeeEstimated*(labourCostsOfTimeOverProgress/100))

    //         //         if (wip / totalFeeEstimated && isFinite(wip / estimatedTotalHours)) {
    //         //             wipPercentage = (wip / totalFeeEstimated).toFixed(2)
    //         //         } else {
    //         //             wipPercentage = 0.00
    //         //         }

    //         //         wip = wip.toFixed(2)

    //         //     }

    //         //     tempServices[service] = { ...tempServices[service], invoicedVal: feeOnThisServiceId, billingPlanStatus: status, feesInvoicedAndIssued, actualTotalHours, estimatedTotalHours, totalFeeEstimated, billedProgress, timeProgress, wip, wipPercentage, completionProgress, feesComplete }

    //         // }

    //         setServicesAmended([...tempServices])

    //     }

    // }, [services, invoices, timesheets, showWIP, loading])

    useEffect(() => {

        console.log("useEffect set order")

        let sortedData = [...servicesFiltered];

        if (sortedField === "projectNumber" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : 0))
        } else if (sortedField === "projectNumber" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? 1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? -1 : 0))
        } else if (sortedField === "serviceCreatedDate" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceCreatedDate < item2.serviceCreatedDate ? -1 : item1.serviceCreatedDate > item2.serviceCreatedDate ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : 0))
        } else if (sortedField === "serviceCreatedDate" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceCreatedDate < item2.serviceCreatedDate ? 1 : item1.serviceCreatedDate > item2.serviceCreatedDate ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : 0))
        } else if (sortedField === "serviceLiveDate" && sortedOrder === "asc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceLiveDate < item2.serviceLiveDate ? -1 : item1.serviceLiveDate > item2.serviceLiveDate ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : 0))
        } else if (sortedField === "serviceLiveDate" && sortedOrder === "desc") {
            setServicesFiltered(sortedData.sort((item1, item2) => item1.serviceLiveDate < item2.serviceLiveDate ? 1 : item1.serviceLiveDate > item2.serviceLiveDate ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : 0))
        }

    }, [sortedField, sortedOrder])

    if (services.length > 0 && users.length > 0 && !loading  && !usersLoading && !clientsLoading && clients.length > 0) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Opportunities" section="Opportunities Tracker" showUserMenu='true' />

                            <hr></hr>
                            <br></br>


                            <div className="flex items-center">
                                <div className="w-full mx-auto m-5 px-3">
                                    <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-9">

                                    <div>
                                        <p className="text-xs">Opportunity Name</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={dealNamesToSelect}
                                            value={selectedDealName}
                                            onChange={setSelectedDealName}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Project Client</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectClientsToSelect}
                                            value={selectedProjectClient}
                                            onChange={setSelectedProjectClient}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Service Status</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={serviceStatusesToSelect}
                                            value={selectedServiceStatus}
                                            onChange={setSelectedServiceStatus}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        {/* <div>
                                        <p className="text-xs">Project Manager</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectManagersToSelect}
                                            value={selectedProjectManager}
                                            onChange={setSelectedProjectManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div> */}

                                        {/* <div>
                                        <p className="text-xs">Service Manager</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={serviceManagersToSelect}
                                            value={selectedServiceManager}
                                            onChange={setSelectedServiceManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div> */}

                                        {/* <div>
                                        <p className="text-xs">Service</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={servicesToSelect}
                                            value={selectedService}
                                            onChange={setSelectedService}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div> */}

                                        <div>
                                        <p className="text-xs">Project Name</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectNamesToSelect}
                                            value={selectedProjectName}
                                            onChange={setSelectedProjectName}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Number</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectNumbersToSelect}
                                            value={selectedProjectNumber}
                                            onChange={setSelectedProjectNumber}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        {/* <div>
                                        <p className="text-xs">Project Status</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectStatusesToSelect}
                                            value={selectedProjectStatus}
                                            onChange={setSelectedProjectStatus}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div> */}

                                        {/* <div>
                                        <p className="text-xs">Billing Plan Status</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={billingPlanStatusesToSelect}
                                            value={selectedBillingPlanStatus}
                                            onChange={setSelectedBillingPlanStatus}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div> */}

                                        {/* <div>
                                        <p className="text-xs">Work Stage</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={workStagesToSelect}
                                            value={selectedWorkStage}
                                            onChange={setSelectedWorkStage}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div> */}

                                        <div>
                                        <p className="text-xs">Service Created Month</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={serviceCreatedMonthsToSelect}
                                            value={selectedServiceCreatedMonth}
                                            onChange={setSelectedServiceCreatedMonth}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Quote Win Pending Month</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={serviceWinPendingMonthsToSelect}
                                            value={selectedServiceWinPendingMonth}
                                            onChange={setSelectedServiceWinPendingMonth}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Live Month</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={serviceLiveMonthsToSelect}
                                            value={selectedServiceLiveMonth}
                                            onChange={setSelectedServiceLiveMonth}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Department</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={departmentsToSelect}
                                            value={selectedDepartment}
                                            onChange={setSelectedDepartment}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        {/* <div>
                                        <p className="text-xs">Client PO</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={customerPosToSelect}
                                            value={selectedCustomerPo}
                                            onChange={setSelectedCustomerPo}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div> */}

                                        {/* <div>
                                        <p className="text-xs">Client to Invoice</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={serviceInvoiceClientsToSelect}
                                            value={selectedServiceInvoiceClient}
                                            onChange={setSelectedServiceInvoiceClient}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div> */}
                                        {/* <div>
                                        <p className="text-xs">QA Checked - Fees</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectBillingPlanAndFeesCheckedToSelect}
                                            value={selectedProjectBillingPlanAndFeesChecked}
                                            onChange={setSelectedProjectBillingPlanAndFeesChecked}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">QA Checked - Appointment</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectInvoicingCheckedToSelect}
                                            value={selectedProjectInvoicingChecked}
                                            onChange={setSelectedProjectInvoicingChecked}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div> */}

                                        {/* <div className="grid grid-cols-2 gap-2">
                                        <div className="">
                                            <p className="text-xs font-bold">Quoted</p>
                                            <Button color="bg-bondi-blue" text="3 months" onClick={() => clearFilters()} />
                                        </div>
                                        <div className="">
                                            <p className="text-xs font-bold">Quoted</p>
                                            <Button color="bg-bondi-blue" text="6 months" onClick={() => clearFilters()} />
                                        </div>
                                        </div> */}

                                        <div>
                                            <p className="text-xs">Project Descriptions</p>
                                            <div className = "text-xs">
                                            <MultiSelect
                                                options={projectDescriptionsToSelect}
                                                value={selectedProjectDescriptions}
                                                onChange={setSelectedProjectDescriptions}
                                                labelledBy="Select"
                                            />
                                            </div>
                                        </div>

                                        <div>
                                            <p className="text-xs">BD Notes</p>
                                            <div className = "text-xs">
                                            <MultiSelect
                                                options={BDNotesToSelect}
                                                value={selectedBDNotes}
                                                onChange={setSelectedBDNotes}
                                                labelledBy="Select"
                                            />
                                            </div>
                                        </div>

                                        <div>
                                            <p className="text-xs">Client BD Status</p>
                                            <div className = "text-xs">
                                            <MultiSelect
                                                options={clientBDStatusesToSelect}
                                                value={selectedClientBDStatus}
                                                onChange={setSelectedClientBDStatus}
                                                labelledBy="Select"
                                            />
                                            </div>
                                        </div>

                                        <div className="pl-3">
                                            <p className="text-xs font-bold">Filters</p>
                                            <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                        </div>

                                        <div className="flex items-center justify-between p-3 bg-white rounded-2xl shadow-md shadow-slate-300 col-span-2">
                                            <div>
                                                <div className="text-xs text-gray-500 ">Total Fee £</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xs font-medium text-indigo-500 "><p className="my-1">£ {internationalNumberFormat.format(totalFeeValueGBP)}</p></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-xs text-gray-500 ">Total Fee €</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xs font-medium text-indigo-500 "><p className="my-1">€ {internationalNumberFormat.format(totalFeeValueEUR)}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="pl-3">
                                            <p className="text-xs font-bold">{sortedField === 'projectNumber' && sortedOrder === 'asc' ? 'Sorted by Project Number ↑' : sortedField === 'projectNumber' && sortedOrder === 'desc' ? 'Sorted by Project Number ↓' : 'Sort by Project Number ⇅'}</p>
                                            <Button color="bg-bondi-blue" text={sortedField === 'projectNumber' && sortedOrder === 'asc' ? 'Project Number ↑' : sortedField === 'projectNumber' && sortedOrder === 'desc' ? 'Project Number ↓' : 'Project Number ⇅'} onClick={() => {sortedField==="serviceCreatedDate" ? setSortedOrder('desc') : sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc'); setSortedField('projectNumber') }} />
                                        </div>

                                        <div className="pl-3">
                                            <p className="text-xs font-bold">{sortedField ==='serviceCreatedDate' && sortedOrder==='asc' ? 'Sorted by Created Date ↑' : sortedField==='serviceCreatedDate' && sortedOrder==='desc' ? 'Sorted by Created Date ↓' : 'Sort by Created Date ⇅'}</p>
                                            <Button color="bg-bondi-blue" text={sortedField ==='serviceCreatedDate' && sortedOrder==='asc' ? 'Created Date ↑' : sortedField==='serviceCreatedDate' && sortedOrder==='desc' ? 'Created Date ↓' : 'Created Date ⇅'} onClick={() => {sortedField==="projectNumber" ? setSortedOrder('desc') : sortedOrder==='desc' ? setSortedOrder('asc') : setSortedOrder('desc'); setSortedField('serviceCreatedDate')}} />
                                        </div>

                                        <div className="pl-3">
                                            <p className="text-xs font-bold">{sortedField ==='serviceLiveDate' && sortedOrder==='asc' ? 'Sorted by Live Date ↑' : sortedField==='serviceLiveDate' && sortedOrder==='desc' ? 'Sorted by Live Date ↓' : 'Sort by Live Date ⇅'}</p>
                                            <Button color="bg-bondi-blue" text={sortedField ==='serviceLiveDate' && sortedOrder==='asc' ? 'Live Date ↑' : sortedField==='serviceLiveDate' && sortedOrder==='desc' ? 'Live Date ↓' : 'Live Date ⇅'} onClick={() => {sortedField==="projectNumber" ? setSortedOrder('desc') : sortedOrder==='desc' ? setSortedOrder('asc') : setSortedOrder('desc'); setSortedField('serviceLiveDate')}} />
                                        </div>

                                    </div>
                                </div>

                            </div>

                            {/* <div className="flex items-center bg-slate-100">
                                <div className="w-full px-5 mx-auto my-5">
                                    <div className="grid gap-7 sm:grid-cols-2 ">


                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Total Fee Value £</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-indigo-500 "><p className="my-1">£ {internationalNumberFormat.format(totalFeeValueGBP)}</p></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-sm text-gray-500 ">Total Fee Value €</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-indigo-500 "><p className="my-1">€ {internationalNumberFormat.format(totalFeeValueEUR)}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> */}

                            <hr></hr>

                            <div className="flex flex-col py-2">
                                <div className="overflow-x-auto items-holder-8A">
                                    <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                        <div className="shadow-md shadow-slate-300 rounded-2xl">
                                            <Table
                                                size="small"
                                                columns={[
                                                    {
                                                        title: 'Opportunity Name',
                                                        dataIndex: 'dealName'
                                                    },
                                                    {
                                                        title: 'Project Number',
                                                        dataIndex: 'projectNumber',
                                                        render: (text, record) => record.dealName !== "" ? <td onClick={() => viewProject(record.projectId)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                                        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                                    </svg> {record.projectNumber}</td> : <td></td>,
                                                    },
                                                    {
                                                        title: 'Client',
                                                        dataIndex: 'clientName'
                                                    },
                                                    {
                                                        title: 'Client Contact',
                                                        dataIndex: 'clientContactName'
                                                    },
                                                    {
                                                        title: 'Status',
                                                        dataIndex: 'serviceStatus',
                                                        render: (text, record) => <td onClick={!record.serviceStatus.includes(";") ? () => { setShowModal(true); setValueToSendToModal(record.serviceStatus); setFieldToSendToModal("serviceStatus"); setObjectIdToSendToModal(record.firstService); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") } : () => {}} className={!record.serviceStatus.includes(";") ? "px-2 md:px-2 hover:cursor-pointer whitespace-nowrap" : "px-2 md:px-2 "}> {!record.serviceStatus.includes(";") ? <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg> : <></>}{record.serviceStatus}</td>
                                                        
                                                    },
                                                    {
                                                        title: 'BD Notes',
                                                        dataIndex: 'projectLocation',
                                                        render: (text, record) => <p onClick={() => { setShowModal(true); setValueToSendToModal(record.projectLocation); setFieldToSendToModal("projectLocation"); setObjectIdToSendToModal(record.projectId); setCollectionToSendToModal("projects"); setCollectionArrayIdToSendToModal("") }} className="w-96 max-h-[200px] text-ellipsis overflow-hidden hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{record.projectLocation}</p>

                                                    },
                                                    {
                                                        title: 'Created Date',
                                                        dataIndex: 'serviceCreatedDate',
                                                        render: (serviceCreatedDate) => <div className="whitespace-nowrap">{serviceCreatedDate}</div>
                                                    },
                                                    {
                                                        title: 'Quote win pending Date',
                                                        dataIndex: 'serviceWinPendingDate',
                                                        render: (serviceWinPendingDate) => <div className="whitespace-nowrap">{serviceWinPendingDate}</div>
                                                    },
                                                    {
                                                        title: 'Live Date',
                                                        dataIndex: 'serviceLiveDate',
                                                        render: (serviceLiveDate) => <div className="whitespace-nowrap">{serviceLiveDate}</div>
                                                    },
                                                    {
                                                        title: 'Total Fees',
                                                        dataIndex: 'totalFees',
                                                        render: (totalFees) => <div className="font-bold">{internationalNumberFormat.format(totalFees.toFixed(2))}</div>
                                                    },
                                                    {
                                                        title: 'Consultancy Fees',
                                                        dataIndex: 'totalConsultancyFees',
                                                        render: (totalConsultancyFees) => `${internationalNumberFormat.format(totalConsultancyFees.toFixed(2))}`
                                                    },
                                                    {
                                                        title: 'Costs',
                                                        dataIndex: 'totalCosts',
                                                        render: (totalCosts) => `${internationalNumberFormat.format(totalCosts.toFixed(2))}`
                                                    },

                                                ]}
                                                dataSource={groupedData.slice(firstRecord, lastRecord)}
                                                rowKey={(record) => `${record.dealName}`}

                                                // rowClassName={(_, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}

                                                rowClassName={(record, index) => {
                                                    return index % 2 === 0 ? "service-total-row-even" : "service-total-row-odd"
                                                }}

                                                pagination={false}
                                                expandable={{
                                                    rowExpandable: (record) => true,
                                                    indentSize: 0,
                                                    expandedRowRender: (serviceRecord) => {
                                                        const departmentServices = servicesFiltered.filter(service => service.dealName === serviceRecord.dealName);
                                                        
                                                        // Process services data (including calculating total fees)
                                                        const processedServicesData = departmentServices.map(service => ({
                                                            ...service,
                                                            totalActivityFees: service.activities.reduce((sum, activity) => sum + activity.fee, 0),
                                                            totalConsultancyFees: service.activities.reduce((sum, activity) => (sum + activity.fee)-activity.cost, 0),
                                                            totalCosts: service.activities.reduce((sum, activity) => sum + activity.cost, 0),
                                                        }));

                                                        const columnsWithTotalFees = [
                                                            {
                                                                title: 'Department',
                                                                dataIndex: 'department'
                                                            },
                                                            {
                                                                title: 'Service',
                                                                dataIndex: 'service'
                                                            },
                                                            {
                                                                title: 'Comments',
                                                                dataIndex: 'serviceComments',
                                                                key: 'id',
                                                                render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceComments ); setFieldToSendToModal("serviceComments"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                </svg>{record.serviceComments}</td>,
                                                            },
                                                            {
                                                                title: 'Project Number',
                                                                dataIndex: 'projectNumber',
                                                                render: (text, record) => <td onClick={() => viewProject(record.projectId)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                                                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                                            </svg> {record.projectNumber}</td>,
                                                            },
                                                            {
                                                                title: 'Work Stage',
                                                                dataIndex: 'workStage'
                                                            },
                                                            {
                                                                title: 'Service Manager',
                                                                dataIndex: 'serviceManager',
                                                                key: 'serviceManager',
                                                                render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceManager); setFieldToSendToModal("serviceManager"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2  py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                </svg>{users.find(user => user.id === record.serviceManager).firstName + ' ' + users.find(user => user.id === record.serviceManager).lastName}</td>,
                                                            },
                                                            {
                                                                title: 'Service Status',
                                                                dataIndex: 'serviceStatus',
                                                                key: 'serviceStatus',                                                
                                                                onCell: () => ({
                                                                    style: { whiteSpace: 'nowrap' },
                                                                }),
                                                                render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceStatus); setFieldToSendToModal("serviceStatus"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                </svg>{record.serviceStatus}</td>
                                                            },
                                                            {
                                                                title: 'Total Fees',
                                                                dataIndex: 'totalActivityFees',
                                                                key: 'totalActivityFees',
                                                                render: (totalActivityFees) => <div className="font-bold">{internationalNumberFormat.format(totalActivityFees.toFixed(2))}</div>, // Show total fees with currency formatting
                                                            },
                                                            {
                                                                title: 'Consultancy Fees',
                                                                dataIndex: 'totalConsultancyFees',
                                                                key: 'totalConsultancyFees',
                                                                render: (totalConsultancyFees, record) => {
                                                                    if (role.toString().slice(0,1) !== "2") {
                                                                        return `${internationalNumberFormat.format(totalConsultancyFees.toFixed(2))}`
                                                                    }else if (role.toString().slice(0,1) === "2") {
                                                                        return <td onDoubleClick={() => { setShowModal(true); setValueToSendToModal(totalConsultancyFees); setFieldToSendToModal("consultancyFee"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2  py-2 whitespace-nowrap"> {`${internationalNumberFormat.format(totalConsultancyFees.toFixed(2))}`} </td>
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                title: 'Costs',
                                                                dataIndex: 'totalCosts',
                                                                key: 'totalCosts',
                                                                render: (totalCosts) => `${internationalNumberFormat.format(totalCosts.toFixed(2))}`, // Show total fees with currency formatting
                                                            },
                                                            
                                                            {
                                                                title: 'Created Date',
                                                                dataIndex: 'serviceCreatedDate',
                                                                render: (serviceCreatedDate) => <p className="whitespace-nowrap">{serviceCreatedDate}</p>
                                                            },
                                                            {
                                                                title: 'Quote win pending Date',
                                                                dataIndex: 'serviceWinPendingDate',
                                                                render: (serviceWinPendingDate) => <p className="whitespace-nowrap">{serviceWinPendingDate}</p>
                                                            },
                                                            {
                                                                title: 'Live Date',
                                                                dataIndex: 'serviceLiveDate',
                                                                render: (serviceLiveDate) => <p className="whitespace-nowrap">{serviceLiveDate}</p>
                                                            },

                                                            // {
                                                            //     title: 'HubSpot Deal Number',
                                                            //     dataIndex: 'dealNumber',
                                                            //     key: 'dealNumber',
                                                            //     render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.dealNumber); setFieldToSendToModal("dealNumber"); setObjectIdToSendToModal(currentId); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                            //     <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            //     <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            //     </svg>{record.dealNumber ? record.dealNumber + " - (" + record.dealName +")" : ""}</td>,
                                                            // },
                                                            {
                                                                dataIndex: 'id',
                                                                key: 'id',
                                                                render: () => null,
                                                            }
                                                            ];

                                                            return (
                                                                <Table 
                                                                    size="small"
                                                                    columns={columnsWithTotalFees}
                                                                    dataSource={processedServicesData}
                                                                    rowKey="id"
                                                                    pagination={false}
                                                                    rowClassName={(record, index) => {
                                                                        return index % 2 === 0 ? "service-row-even" : "service-row-odd"
                                                                    }}
                                                                    onRow={(record, rowIndex) => ({
                                                                        onFocus: (event) => {
                                                                            setCurrentId(record.id);
                                                                        },
                                                                    })}
                                                                    expandable={{
                                                                        rowExpandable: (record) => true,
                                                                        indentSize: 0,
                                                                        expandedRowRender: (serviceRecord) => {
                                                                            const columnsActivities = [
                                                                                {
                                                                                    title: 'Activity',
                                                                                    dataIndex: 'activity'
                                                                                },
                                                                                {
                                                                                    title: 'Estimated Hours',
                                                                                    dataIndex: 'estimatedHours',
                                                                                    render: (estimatedHours, record) => 
                                                                                        <div>
                                                                                            {record.cost === 0 && role.toString().slice(0,1) === "2" &&
                                                                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(record.estimatedHours.toFixed(2)); setFieldToSendToModal("estimatedHours"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                                                </svg>{record.estimatedHours.toFixed(2)}</td>
                                                                                            }
                                                                                            {(record.cost !== 0 || role.toString().slice(0,1) !== "2") &&
                                                                                                <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap">{record.estimatedHours.toFixed(2)}</td>
                                                                                            }                                                                    
                                                                                        </div>
                                                                                },
                                                                                {
                                                                                    title: 'Fees',
                                                                                    dataIndex: 'fee',
                                                                                    render: (fee) => {
                                                                                        return internationalNumberFormat.format(fee.toFixed(2));
                                                                                    }
                                                                                },
                                                                                {
                                                                                    title: 'Costs',
                                                                                    dataIndex: 'cost',
                                                                                    render: (cost, record) => 
                                                                                        <div>
                                                                                            {record.estimatedHours === 0 && role.toString().slice(0,1) === "2" &&
                                                                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(record.cost.toFixed(2)); setFieldToSendToModal("cost"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                                            </svg>{internationalNumberFormat.format(record.cost.toFixed(2))}</td>
                                                                                            }
                                                                                            {(record.estimatedHours !== 0 || role.toString().slice(0,1) !== "2") &&
                                                                                                <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {internationalNumberFormat.format(record.cost.toFixed(2))}</td>
                                                                                            }
                                                                                        </div>
                                                                                },
                                                                                {
                                                                                    title: 'Cost Category',
                                                                                    dataIndex: 'costCategory',
                                                                                    render: (costCategory, record) => 
                                                                                        <div>
                                                                                            {record.estimatedHours === 0 && role.toString().slice(0,1) === "2" &&
                                                                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(record.costCategory); setFieldToSendToModal("costCategory"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                                            </svg>{record.costCategory}</td>
                                                                                            }
                                                                                            {(record.estimatedHours !== 0 || role.toString().slice(0,1) !== "2") &&
                                                                                                <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {record.costCategory}</td>
                                                                                            }
                                                                                        </div>
                                                                                },
                                                                                {
                                                                                    dataIndex: 'activityId',
                                                                                    render: () => null,
                                                                                }
                                                                            ]
                                                                            //Activities
                                                                            return (
                                                                                <div style={{ width: '800px', margin: '0', paddingBottom:'5px' }}>
                                                                                    <Table 
                                                                                        size="small"
                                                                                        columns={columnsActivities} 
                                                                                        dataSource={serviceRecord.activities}
                                                                                        rowKey="activityId"
                                                                                        rowClassName={(record, index) => {
                                                                                            return 'activity-row'
                                                                                        }}
                                                                                        pagination={false}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        },
                                                                    }}
                                                                />
                                                            );
                                                        },
                                                    }}
                                                >
                                            </Table>
                                            
                                            <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={services} role={role} userDepartment={userDepartment} onClose={() => setShowModal(false)} show={showModal} title="Update Service" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid gap-7 sm:grid-cols-3 ">
                                <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                                <div className="text-sm text-gray-500 text-center pt-2 ">{firstRecord + " to " + lastRecord + " of " + groupedData.length}</div>
                                <Button text="Next 100 >>" onClick={() => next100Records()}></Button>
                            </div>
                            
                            <br></br>
                            {/* {!showWIP &&
                                <Button color="bg-bondi-blue" text="Calculate and Show WIP" onClick={() => calculcateAndShowWIP()} />
                            }
                            {showWIP &&
                                <Button color="bg-bondi-blue" text="Hide WIP" onClick={() => calculcateAndShowWIP()} /> 
                            } */}
                            <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(servicesFiltered)} />
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Opportunities" section="Opportunities Tracker" showUserMenu='true' />

                        <br></br>
                        <hr></hr>
                        <br></br>
                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7">

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                </div>
                            </div>
                        </div>

                        <br></br>
                        <hr></hr>
                        <br></br>

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div role="status" className=" bg-white w-full p-4 space-y-4 border border-slate-200 divide-y divide-slate-200 rounded-2xl shadow-slate-300 shadow-md animate-pulse ">

                                        <div className="flex items-center justify-between">
                                            <div>
                                                <div className="h-5 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-5 bg-slate-300 rounded-full w-48"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )

    }

}

export default ProjectsTrackerOpportunities
