import { useEffect, useState } from "react";
import axios from "axios";
import Button from "../UI/Button";
import { useNavigate, useMatch } from "react-router-dom";
import AuthService from '../../services/AuthService'

const ListClientOrganisations = () => {
    const match = useMatch("/viewClient/:id");

    const [organisations, setOrganisations] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        axios.get(process.env.REACT_APP_API_URL + "/organisations_by_clientId/" + match.params.id)
            .then((res) => res.data)
            .then((res) => {
                //setOrganisations(data)
                setOrganisations(
                    res.filter((client) => client.clientId === match.params.id)
                );
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios.post(process.env.REACT_APP_API_URL + "/log", {message: AuthService.getCurrentUserName() + " ListClientOrganisations"})

    }, [match.params.id]);

    const navigate = useNavigate();
    const deleteOrganisation = (id) => {
        window.confirm('Are you sure you want to delete it?')
        // axios.delete(process.env.REACT_APP_API_URL + "/organisations/" + id);
        // setOrganisations(
        //     organisations.filter((organisation) => organisation.id !== id)
        // );
    };

    const editOrganisation = (id) => {
        navigate(`/addOrUpdateOrganisation/${id}`);
    };

    const viewOrganisation = (id) => {
        navigate(`/viewOrganisation/${id}`);
    };

    if (organisations.length > 0 && !loading) {
        return (
            <div>
                <div>
                    <div>
                        <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 mx-auto header-8A'>Organisations</h1>

                            <div>
                                <div className="flex items-center">
                                    <div className="w-full mx-auto mb-5 px-3">

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                                
                                                <div className="text-gray-400">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672Zm-7.518-.267A8.25 8.25 0 1 1 20.25 10.5M8.288 14.212A5.25 5.25 0 1 1 17.25 10.5" />
                                                    </svg>
                                                </div>

                                                <div className="grid gap-2 w-full grid-cols-4">
                                                    <div className="text-center">
                                                        <Button color="bondi-blue" text="Data Collection Tracker" onClick={() => {sessionStorage.removeItem("organisationId"); sessionStorage.removeItem("footprintId"); navigate(`/viewDataCollectionTracker/`)}} />
                                                    </div>
                                                    <div className="text-center">
                                                        <Button color="bondi-blue" text="Interactive Report" onClick={() => navigate(`/dashboardOrganisationsInteractiveReport/`)} />
                                                    </div>
                                                    <div className="text-center">
                                                        <Button color="bondi-blue" text="Results Dashboard" onClick={() => navigate(`/dashboardOrganisationsWithoutTargetCharts/`)} />
                                                    </div>
                                                    <div className="text-center">
                                                        <Button color="bondi-blue" text="Targets Dashboard" onClick={() => navigate(`/dashboardOrganisationsTargetChartsOnly/`)} />
                                                    </div>
                                                </div>

                                            <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672Zm-7.518-.267A8.25 8.25 0 1 1 20.25 10.5M8.288 14.212A5.25 5.25 0 1 1 17.25 10.5" />
                                            </svg>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        <div className="flex flex-wrap">
                            {organisations.map((organisation) => (

                                <div className=' md:w-1/4 lg:w-1/6 max-w-[17rem]' key={organisation.id}>
                                    <div className=" group flex relative bg-slate-200 aspect-square shadow-xl m-2  overflow-hidden content-center rounded-2xl" key={organisation.id}>
                                        <div className='bg-slate-200 w-full pb-2'>
                                            <img className="object-cover w-full aspect-square text-center rounded-2xl" src={`https://storage.googleapis.com/public_images_bucket/${organisation.id}/` + organisation.organisationName + ".jpg"} onError={(e) => { e.target.src = "/images/organisation_generic.png" }} alt="loading..." />
                                        </div>

                                        <div className="absolute hover:cursor-pointer bg-congress-blue w-full h-full bg-opacity-0 transition-bg-opacity duration-500  hover:bg-opacity-80 z-1">

                                            {/* <div className='group-hover:cursor-pointer' onClick={() => deleteOrganisation(organisation.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="opacity-0 inline-block stroke-red-600 h-6 w-6 m-1 group-hover:cursor-pointer group-hover:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg>
                                                <p className="opacity-0 inline-block text-red-600 text-md group-hover:cursor-pointer group-hover:opacity-100 hover:underline">Delete</p>
                                            </div> */}

                                            <div className='group-hover:cursor-pointer pt-2 pl-2' onClick={() => editOrganisation(organisation.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="opacity-0 inline-block stroke-slate-50 h-6 w-6 m-1 group-hover:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                </svg>
                                                <p className="opacity-0 inline-block text-slate-50 text-md group-hover:opacity-100 hover:underline">Edit Organisation</p>
                                            </div>

                                            <div className='group-hover:cursor-pointer pl-2' onClick={() => viewOrganisation(organisation.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="opacity-0 inline-block stroke-slate-50 h-6 w-6 m-1 group-hover:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                                <p className="opacity-0 inline-block text-slate-50 text-md group-hover:opacity-100 hover:underline">View Footprints</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='pb-2 m-2 mx-4 text-eight-text'>
                                        <p onClick={() => viewOrganisation(organisation.id)} className="text-md pt-1 hover:cursor-pointer hover:underline">{organisation.organisationName}</p>
                                        <p className="text-xs">{organisation.organisationAddress}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <br></br>
                        {/* <Button
                            color="bondi-blue"
                            text="Add New Organisation"
                            onClick={() =>
                                navigate("/addOrUpdateOrganisation/_add")
                            }
                        /> */}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                {/* <div>
                    <div>
                        <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 mx-auto header-8A'>Organisations</h1>
                        <p>No Organisations</p>
                    </div>
                    <br></br>
                    <Button
                        color="bondi-blue"
                        text="Add New Organisation"
                        onClick={() =>
                            navigate("/addOrUpdateOrganisation/_add")
                        }
                    />
                </div> */}
            </div>
        );
    }
};

export default ListClientOrganisations;
