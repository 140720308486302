import { useEffect, useState } from 'react'
import Header from '../UI/Header'
import ReactTooltip from "react-tooltip"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
// import { Tab } from '@headlessui/react'



const ListProjectsPublic = () => {

    const navigate = useNavigate()

    const [projects, setProjects] = useState([])
    const [loadingProjects, setLoadingProjects] = useState(false)

    useEffect(() => {

        setLoadingProjects(true);
        axios.get(process.env.REACT_APP_API_URL + '/offset_projects')
            .then((res)  => {
                setProjects(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingProjects(false);
            });

    }, []);

    const viewProject = (id) => {
        navigate(`/viewProjectPublic/${id}`);
    }

    if (projects.length > 0 && !loadingProjects) {

        return (

            <div className="bg-slate-100 flex h-screen overflow-hidden">

                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                        <Header title="Carbon Offset Registry" section="Projects" showButtons={true} buttonToggle="Projects" isPublic="Public" />
                        {/* <div className="w-full max-w-md px-2 py-16 sm:px-0">
                            <Tab.Group>
                                <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                                    <Tab className=
                                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                                    // ? 'bg-white shadow'
                                    // : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                                    >Tab 1</Tab>
                                    <Tab>Tab 2</Tab>
                                </Tab.List>
                                <Tab.Panels>
                                    <Tab.Panel className=
                                        'rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                                    ></Tab.Panel>
                                    <Tab.Panel>Content 2</Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div> */}

                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2">
                                        <table className="objects-8A min-w-full divide-y divide-gray-200">
                                            <thead className="bg-pacific-blue-50">
                                                <tr>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  NCS ID</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Project Name</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Project Methodology</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Project Status</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Country</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  Project Start Date</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider">  SDGs</th>
                                                </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {projects.map((project) => (
                                                    <tr key={project.id}>
                                                        <td onClick={() => viewProject(project.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {project.projectNCSId} </td>
                                                        <td onClick={() => viewProject(project.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {project.projectName}</td>
                                                        <td onClick={() => viewProject(project.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {project.projectType}</td>
                                                        <td onClick={() => viewProject(project.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {project.projectStatus}</td>
                                                        <td onClick={() => viewProject(project.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {project.projectLocationCountry}</td>
                                                        <td onClick={() => viewProject(project.id)} className="px-4 md:px-2 py-2 whitespace-nowrap hover:cursor-pointer"> {project.projectStartDate}</td>
                                                        {/* <td> {project.projectSDGs.map((image) => (<img className="sdg-8A" src={"/images/SDG" + image + ".png"} key={image}></img>))}</td> */}
                                                        <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {project.projectSDGs.map((image) => (<div className="sdg-8A" data-tip data-for={"SDGTip" + image} key={image}><img src={"/images/SDG" + image + ".png"} alt=""></img>
                                                            <ReactTooltip id={"SDGTip" + image} place="top" effect="solid">
                                                                <div>{image === "1" && <p>1: No Poverty</p>}</div>
                                                                <div>{image === "2" && <p>2: Zero Hunger</p>}</div>
                                                                <div>{image === "3" && <p>3: Good Health and Well-being</p>}</div>
                                                                <div>{image === "4" && <p>4: Quality Education</p>}</div>
                                                                <div>{image === "5" && <p>5: Gender Equality</p>}</div>
                                                                <div>{image === "6" && <p>6: Clean Water and Sanitation</p>}</div>
                                                                <div>{image === "7" && <p>7: Affordable and Clean Energy</p>}</div>
                                                                <div>{image === "8" && <p>8: Decent Work and Economic Growth</p>}</div>
                                                                <div>{image === "9" && <p>9: Industry, Innovation and Infrastructure</p>}</div>
                                                                <div>{image === "10" && <p>10: Reduced Inequality</p>}</div>
                                                                <div>{image === "11" && <p>11: Sustainable Cities and Communities</p>}</div>
                                                                <div>{image === "12" && <p>12: Responsible Consumption and Production</p>}</div>
                                                                <div>{image === "13" && <p>13: Climate Action</p>}</div>
                                                                <div>{image === "14" && <p>14: Life Below Water</p>}</div>
                                                                <div>{image === "15" && <p>15: Life on Land</p>}</div>
                                                                <div>{image === "16" && <p>16: Peace and Justice Strong Institutions</p>}</div>
                                                                <div>{image === "17" && <p>17: Partnerships to achieve the Goal</p>}</div>
                                                            </ReactTooltip></div>))}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>

        )

    } else {

        return (
            <div>

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Carbon Offset Registry" section="Projects" showButtons={true} buttonToggle="Projects" isPublic="Public" />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }
}

export default ListProjectsPublic