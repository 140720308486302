import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Sidebar from '../UI/Sidebar'
import { ExportToCsv } from 'export-to-csv'
import { useNavigate } from 'react-router-dom'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import { MultiSelect } from "react-multi-select-component";
import AuthService from '../../services/AuthService'
import { Doughnut, Bar, Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
  } from "chart.js";
  
  ChartJS.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

const ProjectsTrackerProjects = () => {

    const [projects, setProjects] = useState([])
    const [projectsFiltered, setProjectsFiltered] = useState([])
    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)

    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [usersLoading, setUsersLoading] = useState(true)
    const [clients, setClients] = useState([])
    const [clientsLoading, setClientsLoading] = useState(true)
    const [userId, setUserId] = useState('')

    const [selectedServiceManager, setSelectedServiceManager] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [selectedProjectManager, setSelectedProjectManager] = useState([])
    const [selectedProjectName, setSelectedProjectName] = useState([])
    const [selectedProjectNumber, setSelectedProjectNumber] = useState([])
    const [selectedStage, setSelectedStage] = useState([])
    const [selectedServiceInvoiceClient, setSelectedServiceInvoiceClient] = useState([])
    const [selectedProjectClient, setSelectedProjectClient] = useState([])
    
    const [serviceManagersToSelect, setServiceManagersToSelect] = useState([])
    const [servicesToSelect, setServicesToSelect] = useState([])
    const [projectManagersToSelect, setProjectManagersToSelect] = useState([])
    const [projectNamesToSelect, setProjectNamesToSelect] = useState([])
    const [projectNumbersToSelect, setProjectNumbersToSelect] = useState([])
    const [workStagesToSelect, setWorkStagesToSelect] = useState([])
    const [serviceInvoiceClientsToSelect, setServiceInvoiceClientsToSelect] = useState([])
    const [projectClientsToSelect, setProjectClientsToSelect] = useState([])
    const [stagesToSelect, setStagesToSelect] = useState([])

    const [sortedField, setSortedField] = useState('projectNumber');
    const [sortedOrder, setSortedOrder] = useState('asc');

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')

    const [projectIdsToExpand, setProjectIdsToExpand] = useState([])

    const [projectIdsCommentThreadsToExpand, setProjectIdsCommentThreadsToExpand] = useState([])

    const chartColours = [
        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
    ];
    const chartHoverColours = [
        "#10475C",
        "#007980",
        "#0E2343",
        "#96abb3",
        "#036860",
        "#0B4516",
        "#052827",
        "#3C6F26",
        "#3A4535",
        "#61703F",
        "#a16b1a",
        "#CEB691",
        "#2E211C",
        "#802741",
        "#732912",
        "#7A6009",
        "#4d6875",
        "#303372",
        "#3B2061",
        "#413F5C",
        "#481436",
        "#783378",
    ];

    const barOptionsHorizontal = {

        layout: {
            padding: {
                right: 100
            }
        },

        // indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'WLC: A-C, excluding B6 -B7 (kgCO2e/m2)',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: 'auto',
                color: 'white'
            }
            // datalabels: {
            //     // display: false,
            //     anchor: 'end',
            //     align: 'right',
            //     formatter: (value, ctx) => {
            //         // let sum = 0;
            //         // let dataArr = ctx.chart.data.datasets[0].data;
            //         // dataArr.map(data => {
            //         //     sum += data;
            //         // });
            //         // let percentage = (value*100 / carbonEmissions.reduce(
            //         //     (prev, curr) => Number(prev) + Number(curr),
            //         //     0
            //         // )).toFixed(2)+"%";
            //         return internationalNumberFormat.format(value.toFixed(2));
            //     }
            // }
        },
        

        responsive: true,

    };
    
    const barOptionsHorizontalWater = {

        layout: {
            padding: {
                right: 100
            }
        },

        // indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Water Consumption (m3/person/yr)',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: 'auto',
                color: 'white'
            }
            // datalabels: {
            //     // display: false,
            //     anchor: 'end',
            //     align: 'right',
            //     formatter: (value, ctx) => {
            //         // let sum = 0;
            //         // let dataArr = ctx.chart.data.datasets[0].data;
            //         // dataArr.map(data => {
            //         //     sum += data;
            //         // });
            //         // let percentage = (value*100 / carbonEmissions.reduce(
            //         //     (prev, curr) => Number(prev) + Number(curr),
            //         //     0
            //         // )).toFixed(2)+"%";
            //         return internationalNumberFormat.format(value.toFixed(2));
            //     }
            // }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalPV = {

        layout: {
            padding: {
                right: 100
            }
        },

        // indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'PV energy production (kWh/m2)',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: 'auto',
                color: 'white'
            }
            // datalabels: {
            //     // display: false,
            //     anchor: 'end',
            //     align: 'right',
            //     formatter: (value, ctx) => {
            //         // let sum = 0;
            //         // let dataArr = ctx.chart.data.datasets[0].data;
            //         // dataArr.map(data => {
            //         //     sum += data;
            //         // });
            //         // let percentage = (value*100 / carbonEmissions.reduce(
            //         //     (prev, curr) => Number(prev) + Number(curr),
            //         //     0
            //         // )).toFixed(2)+"%";
            //         return internationalNumberFormat.format(value.toFixed(2));
            //     }
            // }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalBREEAMScore = {

        layout: {
            padding: {
                right: 100
            }
        },

        // indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Completed BREEAM Score',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: 'auto',
                color: 'white'
            }
            // datalabels: {
            //     // display: false,
            //     anchor: 'end',
            //     align: 'right',
            //     formatter: (value, ctx) => {
            //         // let sum = 0;
            //         // let dataArr = ctx.chart.data.datasets[0].data;
            //         // dataArr.map(data => {
            //         //     sum += data;
            //         // });
            //         // let percentage = (value*100 / carbonEmissions.reduce(
            //         //     (prev, curr) => Number(prev) + Number(curr),
            //         //     0
            //         // )).toFixed(2)+"%";
            //         return internationalNumberFormat.format(value.toFixed(2));
            //     }
            // }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalBREEAMScoreInProgress = {

        layout: {
            padding: {
                right: 100
            }
        },

        // indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'In Progress BREEAM Score',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: 'auto',
                color: 'white'
            }
            // datalabels: {
            //     // display: false,
            //     anchor: 'end',
            //     align: 'right',
            //     formatter: (value, ctx) => {
            //         // let sum = 0;
            //         // let dataArr = ctx.chart.data.datasets[0].data;
            //         // dataArr.map(data => {
            //         //     sum += data;
            //         // });
            //         // let percentage = (value*100 / carbonEmissions.reduce(
            //         //     (prev, curr) => Number(prev) + Number(curr),
            //         //     0
            //         // )).toFixed(2)+"%";
            //         return internationalNumberFormat.format(value.toFixed(2));
            //     }
            // }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalEPC = {

        layout: {
            padding: {
                right: 100
            }
        },

        // indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'EPC / Total emissions (kg/m2)',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: 'auto',
                // color: 'white',
                anchor: 'end',
                align: 'top',
                offset: 0
            }
            // datalabels: {
            //     // display: false,
            //     anchor: 'end',
            //     align: 'right',
            //     formatter: (value, ctx) => {
            //         // let sum = 0;
            //         // let dataArr = ctx.chart.data.datasets[0].data;
            //         // dataArr.map(data => {
            //         //     sum += data;
            //         // });
            //         // let percentage = (value*100 / carbonEmissions.reduce(
            //         //     (prev, curr) => Number(prev) + Number(curr),
            //         //     0
            //         // )).toFixed(2)+"%";
            //         return internationalNumberFormat.format(value.toFixed(2));
            //     }
            // }
        },
        

        responsive: true,

    };

    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Assessments by Type',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                // formatter: (value, ctx) => {
                //     let sum = 0;
                //     let dataArr = ctx.chart.data.datasets[0].data;
                //     dataArr.map(data => {
                //         sum += data;
                //     });
                //     let percentage = (value*100 / sum).toFixed(0)+"%";
                //     if ((value*100 / sum) > 5) {
                //         return percentage;
                //     }

                //     return null
                // },
                display: 'auto',
                color: 'white'

            },

        },
        
    };

    const pieOptionsByStatus = {
        plugins: {
            title: {
                display: true,
                text: 'Design Assessments by Status',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: 'auto',
                color: 'white'

            },

        },
        
    };

    const pieOptionsByStatusPlanning = {
        plugins: {
            title: {
                display: true,
                text: 'Planning Assessments by Status',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: 'auto',
                color: 'white'

            },

        },
        
    };

    const pieOptionsByAchievedRating = {
        plugins: {
            title: {
                display: true,
                text: 'Completed Assessments by Achieved Rating',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: 'auto',
                color: 'white',

            },

        },
        
    };

    const pieOptionsByTargetRating = {
        plugins: {
            title: {
                display: true,
                text: 'In Progress Assessments by Target Rating',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: 'auto',
                color: 'white'

            },

        },
        
    };

    const KPIData = () => { 

        var projectInstance, KPIInstance
        var KPIDataArray = []

        var projectsFilteredTemp = [...projectsFiltered]

        for (projectInstance = 0; projectInstance < projectsFilteredTemp.length; projectInstance++) {

            for (KPIInstance = 0; KPIInstance < projectsFilteredTemp[projectInstance].projectCurrentRIBAStage.clientKPIs.length; KPIInstance++) {

                projectsFilteredTemp[projectInstance].projectCurrentRIBAStage.clientKPIs[KPIInstance].projectName = projectsFilteredTemp[projectInstance].projectName

            }

            KPIDataArray = KPIDataArray.concat(projectsFilteredTemp[projectInstance].projectCurrentRIBAStage.clientKPIs)
        }

        console.log(KPIDataArray)

        return KPIDataArray

    }

    const chartByType = {
        labels: ["Planning", "Design", "Final"],
        datasets: [
            {
                data: [KPIData().filter(item => item.KPIType === "Planning" && item.KPIDescription === "BREEAM").length, KPIData().filter(item => item.KPIType === "Design" && item.KPIDescription === "BREEAM").length, KPIData().filter(item => item.KPIType === "Final" && item.KPIDescription === "BREEAM").length],
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartByStatus = {
        labels: ["In Progress", "Complete"],
        datasets: [
            {
                data: [KPIData().filter(item => item.KPIAchieved === "" && item.KPIDescription === "BREEAM" && item.KPIType === "Design").length, KPIData().filter(item => item.KPIAchieved !== "" && item.KPIDescription === "BREEAM" && item.KPIType === "Design").length],
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartByStatusPlanning = {
        labels: ["In Progress", "Complete"],
        datasets: [
            {
                data: [KPIData().filter(item => item.KPIAchieved === "" && item.KPIDescription === "BREEAM" && item.KPIType === "Planning").length, KPIData().filter(item => item.KPIAchieved !== "" && item.KPIDescription === "BREEAM" && item.KPIType === "Planning").length],
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartByAchievedRating = {
        labels: ["Pass", "Good", "Very Good", "Excellent", "Outstanding"],
        datasets: [
            {
                data: [KPIData().filter(item => item.KPIAchieved === "Pass" && item.KPIDescription === "BREEAM").length, KPIData().filter(item => item.KPIAchieved === "Good" && item.KPIDescription === "BREEAM").length, KPIData().filter(item => item.KPIAchieved === "Very Good" && item.KPIDescription === "BREEAM").length, KPIData().filter(item => item.KPIAchieved === "Excellent" && item.KPIDescription === "BREEAM").length, KPIData().filter(item => item.KPIAchieved === "Outstanding" && item.KPIDescription === "BREEAM").length],
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartByTargetRating = {
        labels: ["Pass", "Good", "Very Good", "Excellent", "Outstanding"],
        datasets: [
            {
                data: [KPIData().filter(item => item.KPITarget === "Pass" && item.KPIDescription === "BREEAM" && item.KPIAchieved === "").length, KPIData().filter(item => item.KPITarget === "Good" && item.KPIDescription === "BREEAM" && item.KPIAchieved === "").length, KPIData().filter(item => item.KPITarget === "Very Good" && item.KPIDescription === "BREEAM" && item.KPIAchieved === "").length, KPIData().filter(item => item.KPITarget === "Excellent" && item.KPIDescription === "BREEAM" && item.KPIAchieved === "").length, KPIData().filter(item => item.KPITarget === "Outstanding" && item.KPIDescription === "BREEAM" && item.KPIAchieved === "").length],
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    // const chartByTypeEPC = {
    //     labels: ["Planning", "Design", "Final"],
    //     datasets: [
    //         {
    //             data: [KPIData().filter(item => item.KPIType === "Planning" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length, KPIData().filter(item => item.KPIType === "Design" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length, KPIData().filter(item => item.KPIType === "Final" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length],
    //             backgroundColor: chartColours,
    //             hoverBackgroundColor: chartHoverColours,
    //         },
    //     ],
    // };

    // const chartByStatusEPC = {
    //     labels: ["In Progress", "Complete"],
    //     datasets: [
    //         {
    //             data: [KPIData().filter(item => item.KPIAchieved === "" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length, KPIData().filter(item => item.KPIAchieved !== "" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length],
    //             backgroundColor: chartColours,
    //             hoverBackgroundColor: chartHoverColours,
    //         },
    //     ],
    // };

    const chartByAchievedRatingEPC = {
        labels: ["A+", "A", "B", "C", "D"],
        datasets: [
            {
                data: [KPIData().filter(item => item.KPIAchieved === "A+" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length, KPIData().filter(item => item.KPIAchieved === "A" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length, KPIData().filter(item => item.KPIAchieved === "B" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length, KPIData().filter(item => item.KPIAchieved === "C" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length, KPIData().filter(item => item.KPIAchieved === "D" && item.KPIDescription === "EPC / Total emissions (kg/m2)").length],
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartLCAEntiresByProject = {
        labels: KPIData().filter(item => item.KPIDescription === "WLC: A-C, excluding B6 -B7 (kgCO2e/m2)").map(item => item.projectName),
        datasets: [
            {
                data: KPIData().filter(item => item.KPIDescription === "WLC: A-C, excluding B6 -B7 (kgCO2e/m2)").map(item => item.KPIScore),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartWaterEntiresByProject = {
        labels: KPIData().filter(item => item.KPIDescription === "BREEAM water consumption (m3/person/yr)").map(item => item.projectName),
        datasets: [
            {
                data: KPIData().filter(item => item.KPIDescription === "BREEAM water consumption (m3/person/yr)").map(item => item.KPIScore),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartPVEntiresByProject = {
        labels: KPIData().filter(item => item.KPIDescription === "PV energy production (kWh/m2)").map(item => item.projectName),
        datasets: [
            {
                data: KPIData().filter(item => item.KPIDescription === "PV energy production (kWh/m2)").map(item => item.KPIScore),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartBREEAMScoreEntiresByProject = {
        labels: KPIData().filter(item => item.KPIDescription === "BREEAM" && item.KPIAchieved !== "").map(item => item.projectName),
        datasets: [
            {
                data: KPIData().filter(item => item.KPIDescription === "BREEAM" && item.KPIAchieved !== "").map(item => item.KPIScore),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartBREEAMScoreEntiresByProjectInProgress = {
        labels: KPIData().filter(item => item.KPIDescription === "BREEAM" && item.KPIAchieved === "").map(item => item.projectName),
        datasets: [
            {
                data: KPIData().filter(item => item.KPIDescription === "BREEAM" && item.KPIAchieved === "").map(item => item.KPIScore),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const chartEPCEntiresByProject = {
        labels: KPIData().filter(item => item.KPIDescription === "EPC / Total emissions (kg/m2)").map(item => item.projectName),
        datasets: [
            {
                data: KPIData().filter(item => item.KPIDescription === "EPC / Total emissions (kg/m2)").map(item => item.KPIScore),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    // const internationalNumberFormat = new Intl.NumberFormat('en-US')
    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            // width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            // width: 160,
            fontSize: 13
        }),
    }

    const expandToggle = (e) => {

    
        var projectIdsToExpandTemp = [...projectIdsToExpand]

        if (projectIdsToExpand.filter(item => item.id === e).length > 0) {
            projectIdsToExpandTemp = projectIdsToExpandTemp.filter(item => item.id !== e)
        }

        if (projectIdsToExpand.filter(item => item.id === e).length === 0) {
            projectIdsToExpandTemp.push({id: e})
        }

        setProjectIdsToExpand(projectIdsToExpandTemp)

    };

    const expandToggleForCommentThread = (e) => {

    
        var projectIdsToExpandTemp = [...projectIdsCommentThreadsToExpand]

        if (projectIdsCommentThreadsToExpand.filter(item => item.id === e).length > 0) {
            projectIdsToExpandTemp = projectIdsToExpandTemp.filter(item => item.id !== e)
        }

        if (projectIdsCommentThreadsToExpand.filter(item => item.id === e).length === 0) {
            projectIdsToExpandTemp.push({id: e})
        }

        setProjectIdsCommentThreadsToExpand(projectIdsToExpandTemp)

    };

    const prev100Records = () => {
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }

    }

    const next100Records = () => {

        if (lastRecord <= projectsFiltered.length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

    }

    const updateStageStatus = async (stage, projectId) => {

        var newProjectData
        var allProjectsData = [...projects]
        var stageValue

        await axios.get(process.env.REACT_APP_API_URL + "/projects/" + projectId)
        .then((res) => {

            newProjectData = {...res.data}

            if (stage === "2") {

                if (newProjectData.projectCurrentRIBAStage.stage2 === "grey" || newProjectData.projectCurrentRIBAStage.stage2 === "") {
                    stageValue = "yellow"
                }

                if (newProjectData.projectCurrentRIBAStage.stage2 === "yellow") {
                    stageValue = "green"
                }

                if (newProjectData.projectCurrentRIBAStage.stage2 === "green") {
                    stageValue = "red"
                }

                if (newProjectData.projectCurrentRIBAStage.stage2 === "red") {
                    stageValue = "grey"
                }

                newProjectData.projectCurrentRIBAStage.stage2 = stageValue
                allProjectsData.find(item => item.projectId === projectId).projectCurrentRIBAStage.stage2 = stageValue
                axios.put(process.env.REACT_APP_API_URL + '/projects/' + projectId, {...newProjectData})

            }

            if (stage === "3") {

                if (newProjectData.projectCurrentRIBAStage.stage3 === "grey" || newProjectData.projectCurrentRIBAStage.stage3 === "") {
                    stageValue = "yellow"
                }

                if (newProjectData.projectCurrentRIBAStage.stage3 === "yellow") {
                    stageValue = "green"
                }

                if (newProjectData.projectCurrentRIBAStage.stage3 === "green") {
                    stageValue = "red"
                }

                if (newProjectData.projectCurrentRIBAStage.stage3 === "red") {
                    stageValue = "grey"
                }

                newProjectData.projectCurrentRIBAStage.stage3 = stageValue
                allProjectsData.find(item => item.projectId === projectId).projectCurrentRIBAStage.stage3 = stageValue
                axios.put(process.env.REACT_APP_API_URL + '/projects/' + projectId, {...newProjectData})

            }

            if (stage === "4") {

                if (newProjectData.projectCurrentRIBAStage.stage4 === "grey" || newProjectData.projectCurrentRIBAStage.stage4 === "") {
                    stageValue = "yellow"
                }

                if (newProjectData.projectCurrentRIBAStage.stage4 === "yellow") {
                    stageValue = "green"
                }

                if (newProjectData.projectCurrentRIBAStage.stage4 === "green") {
                    stageValue = "red"
                }

                if (newProjectData.projectCurrentRIBAStage.stage4 === "red") {
                    stageValue = "grey"
                }

                newProjectData.projectCurrentRIBAStage.stage4 = stageValue
                allProjectsData.find(item => item.projectId === projectId).projectCurrentRIBAStage.stage4 = stageValue
                axios.put(process.env.REACT_APP_API_URL + '/projects/' + projectId, {...newProjectData})

            }

            if (stage === "5") {

                if (newProjectData.projectCurrentRIBAStage.stage5 === "grey" || newProjectData.projectCurrentRIBAStage.stage5 === "") {
                    stageValue = "yellow"
                }

                if (newProjectData.projectCurrentRIBAStage.stage5 === "yellow") {
                    stageValue = "green"
                }

                if (newProjectData.projectCurrentRIBAStage.stage5 === "green") {
                    stageValue = "red"
                }

                if (newProjectData.projectCurrentRIBAStage.stage5 === "red") {
                    stageValue = "grey"
                }

                newProjectData.projectCurrentRIBAStage.stage5 = stageValue
                allProjectsData.find(item => item.projectId === projectId).projectCurrentRIBAStage.stage5 = stageValue
                axios.put(process.env.REACT_APP_API_URL + '/projects/' + projectId, {...newProjectData})

            }

            if (stage === "6") {

                if (newProjectData.projectCurrentRIBAStage.stage6 === "grey" || newProjectData.projectCurrentRIBAStage.stage6 === "") {
                    stageValue = "yellow"
                }

                if (newProjectData.projectCurrentRIBAStage.stage6 === "yellow") {
                    stageValue = "green"
                }

                if (newProjectData.projectCurrentRIBAStage.stage6 === "green") {
                    stageValue = "red"
                }

                if (newProjectData.projectCurrentRIBAStage.stage6 === "red") {
                    stageValue = "grey"
                }

                newProjectData.projectCurrentRIBAStage.stage6 = stageValue
                allProjectsData.find(item => item.projectId === projectId).projectCurrentRIBAStage.stage6 = stageValue
                axios.put(process.env.REACT_APP_API_URL + '/projects/' + projectId, {...newProjectData})

            }

            if (stage === "7") {

                if (newProjectData.projectCurrentRIBAStage.stage7 === "grey" || newProjectData.projectCurrentRIBAStage.stage7 === "") {
                    stageValue = "yellow"
                }

                if (newProjectData.projectCurrentRIBAStage.stage7 === "yellow") {
                    stageValue = "green"
                }

                if (newProjectData.projectCurrentRIBAStage.stage7 === "green") {
                    stageValue = "red"
                }

                if (newProjectData.projectCurrentRIBAStage.stage7 === "red") {
                    stageValue = "grey"
                }

                newProjectData.projectCurrentRIBAStage.stage7 = stageValue
                allProjectsData.find(item => item.projectId === projectId).projectCurrentRIBAStage.stage7 = stageValue
                axios.put(process.env.REACT_APP_API_URL + '/projects/' + projectId, {...newProjectData})

            }

            setProjects(allProjectsData)

        })
        .catch((err) => {
            console.log(err);
        })

    }

    const commentThread = (e, projectId) => {
                
        var commentThreadFiltered = e

        if (commentThreadFiltered.length > 0) {

        commentThreadFiltered = e.sort((item1, item2) => item1.commentDate > item2.commentDate ? -1 : 0)

        if (!projectIdsCommentThreadsToExpand.find(item => item.id === projectId)) {

            commentThreadFiltered = [commentThreadFiltered[0]]

        }

        return (
        <div className="pb-2 w-96">
            <table className="w-full">

                <tbody>
                    {commentThreadFiltered.map((commentInThread, i) => (
                        <tr key={commentInThread.id} className="bg-transparent">
                            <td className = "pr-2 w-16 border-b-2">{commentInThread.commentDate.slice(0,21)}</td>
                            <td className = "pr-2 w-36 border-b-2">{commentInThread.comment}</td>
                            <td className = "w-14 border-b-2">{users.find(item => item.id === commentInThread.commentUser) ? users.find(item => item.id === commentInThread.commentUser).firstName + " " + users.find(item => item.id === commentInThread.commentUser).lastName : ""}</td>
                            <td className = "pr-2 w-14 border-b-2">{userId === commentInThread.commentUser ? <button className="hover:cursor-pointer float-right" onClick={() => { deleteCommentFromThread(commentInThread.id, projectId) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg></button> : <></>}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
        )
        }

    };

    const clientKPIs = (e, projectId) => {
                
        const KPIsFiltered = e

        if (KPIsFiltered.length > 0) {

        return (
        <div className="pb-2 w-96">
            <table className="w-full">
                <thead>
                    <tr>
                        <th scope="col" className="px-1 md:px-1  py-1 text-left text-xs font-medium text-congress-blue uppercase">KPI</th>
                        <th scope="col" className="px-1 md:px-1  py-1 text-left text-xs font-medium text-congress-blue uppercase">Type</th>
                        <th scope="col" className="px-1 md:px-1  py-1 text-left text-xs font-medium text-congress-blue uppercase">Target</th>
                        <th scope="col" className="px-1 md:px-1  py-1 text-left text-xs font-medium text-congress-blue uppercase">Achieved</th>
                        <th scope="col" className="px-1 md:px-1  py-1 text-left text-xs font-medium text-congress-blue uppercase">Score</th>
                        <th scope="col" className="px-1 md:px-1  py-1 text-left text-xs font-medium text-congress-blue uppercase">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {KPIsFiltered.map((KPIInList, i) => (
                        <tr key={KPIInList.id} className="bg-transparent">
                            <td className = "pr-2 w-38 border-b-2" onClick={() => { setShowModal(true); setValueToSendToModal(KPIInList.KPIDescription); setFieldToSendToModal("projectManagementKPIDescription"); setObjectIdToSendToModal(projectId); setCollectionToSendToModal("projects"); setCollectionArrayIdToSendToModal(KPIInList.id) }}>{KPIInList.KPIDescription}</td>
                            <td className = "pr-2 w-38 border-b-2" onClick={() => { setShowModal(true); setValueToSendToModal(KPIInList.KPIType); setFieldToSendToModal("projectManagementKPIType"); setObjectIdToSendToModal(projectId); setCollectionToSendToModal("projects"); setCollectionArrayIdToSendToModal(KPIInList.id) }}>{KPIInList.KPIType}</td>
                            <td className = "pr-2 w-38 border-b-2" onClick={() => { setShowModal(true); setValueToSendToModal(KPIInList.KPITarget); setFieldToSendToModal("projectManagementKPITarget"); setObjectIdToSendToModal(projectId); setCollectionToSendToModal("projects"); setCollectionArrayIdToSendToModal(KPIInList.id) }}>{KPIInList.KPITarget}</td>
                            <td className = "pr-2 w-38 border-b-2" onClick={() => { setShowModal(true); setValueToSendToModal(KPIInList.KPIAchieved); setFieldToSendToModal("projectManagementKPIAchieved"); setObjectIdToSendToModal(projectId); setCollectionToSendToModal("projects"); setCollectionArrayIdToSendToModal(KPIInList.id) }}>{KPIInList.KPIAchieved}</td>
                            <td className = "pr-2 w-38 border-b-2" onClick={() => { setShowModal(true); setValueToSendToModal(KPIInList.KPIScore); setFieldToSendToModal("projectManagementKPIScore"); setObjectIdToSendToModal(projectId); setCollectionToSendToModal("projects"); setCollectionArrayIdToSendToModal(KPIInList.id) }}>{KPIInList.KPIScore}</td>
                            <td className = "pr-2 w-14 border-b-2 whitespace-nowrap" onClick={() => { setShowModal(true); setValueToSendToModal(KPIInList.KPIAchievedDate); setFieldToSendToModal("projectManagementKPIAchievedDate"); setObjectIdToSendToModal(projectId); setCollectionToSendToModal("projects"); setCollectionArrayIdToSendToModal(KPIInList.id) }}>{KPIInList.KPIAchievedDate}</td>
                            <td className = "pr-2 w-14 border-b-2"><button className="hover:cursor-pointer float-right" onClick={() => { deleteKPIFromList(KPIInList.id, projectId) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg></button></td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
        )
        }

    };

    const deleteCommentFromThread = async (id, projectId) => {

        var newProjectData
        var allProjectsData = [...projectsFiltered]

        await axios.get(process.env.REACT_APP_API_URL + "/projects/" + projectId)
            .then((res) => {

                newProjectData = {...res.data}
                newProjectData.projectCurrentRIBAStage.commentThread = newProjectData.projectCurrentRIBAStage.commentThread.filter(item => item.id !== id)

                axios.put(process.env.REACT_APP_API_URL + '/projects/' + projectId, {...newProjectData})

                allProjectsData.find(item => item.projectId === projectId).projectCurrentRIBAStage.commentThread = newProjectData.projectCurrentRIBAStage.commentThread

                setProjects(allProjectsData)

            })
            .catch((err) => {
                console.log(err);
            })


    }

    const deleteKPIFromList = async (id, projectId) => {

        var newProjectData
        var allProjectsData = [...projectsFiltered]

        await axios.get(process.env.REACT_APP_API_URL + "/projects/" + projectId)
            .then((res) => {

                newProjectData = {...res.data}
                newProjectData.projectCurrentRIBAStage.clientKPIs = newProjectData.projectCurrentRIBAStage.clientKPIs.filter(item => item.id !== id)

                axios.put(process.env.REACT_APP_API_URL + '/projects/' + projectId, {...newProjectData})

                allProjectsData.find(item => item.projectId === projectId).projectCurrentRIBAStage.clientKPIs = newProjectData.projectCurrentRIBAStage.clientKPIs

                setProjects(allProjectsData)

            })
            .catch((err) => {
                console.log(err);
            })


    }

    const expandArrayElementsIntoConcatinatedString = arr => {

        var acc = ""
        // var aggregatedArray = aggregateServicesAndSMArray(arr.filter(item => item.toBeDeleted === "NO"))
        var aggregatedArray = aggregateServicesArray(arr.filter(item => item.toBeDeleted === "NO"))
    
        var i;
        for (i = 0; i < aggregatedArray.length; i++) {
    
            if (i === aggregatedArray.length -1) {
                acc = acc + aggregatedArray[i].service
            } else {
                acc = acc + aggregatedArray[i].service + "\n"
            }
    
        }
    
        return acc
    
    };

    const clearFilters = () => {

        setSelectedServiceManager(serviceManagersToSelect)

        setSelectedService(servicesToSelect)

        setSelectedProjectManager(aggregateProjectManagerArray(projects).map((item) => {
            return {
                label: item.projectManager,
                value: item.projectManager
            }
        }))

        setSelectedProjectName(aggregateProjectNameArray(projects).map((item) => {
            return {
                label: item.projectName,
                value: item.projectName
            }
        }))

        setSelectedProjectNumber(aggregateProjectNumberArray(projects).map((item) => {
            return {
                label: item.projectNumber,
                value: item.projectNumber
            }
        }))

        setSelectedServiceInvoiceClient(serviceInvoiceClientsToSelect)

        setSelectedProjectClient(aggregateProjectClientArray(projects).map((item) => {
            return {
                label: item.clientName,
                value: item.clientName
            }
        }))

        setSelectedStage(aggregateStagesArray(projects).map((item) => {
            return {
                label: item.stage,
                value: item.stage
            }
        }))

        setSortedField('projectNumber')
        setSortedOrder('asc')

    }

    const aggregateProjectManagerArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectManager === val.projectManager);
            if (index === -1) {
                    acc.push({
                        projectManager: val.projectManager
                    });
            };
            return acc;
        }, []);
    };

    const aggregateServiceManagerArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceManager === val.serviceManager);
            if (index === -1) {
                    acc.push({
                        serviceManager: val.serviceManager
                    });
            };
            return acc;
        }, []);
    };

    const aggregateServicesAndSMArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service + obj.serviceManager + obj.serviceStatus === val.service + val.serviceManager + val.serviceStatus);
            if (index === -1) {
                acc.push({
                    service: "● " + val.serviceStatus + " - " + val.service + " (" + users.find(item => item.id === val.serviceManager).firstName + " " + users.find(item => item.id === val.serviceManager).lastName + ")",
                });    
            };
            return acc;
        }, []);
    };

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: val.service,
                });
            };
            return acc;
        }, []);
    };

    const aggregateProjectNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectName === val.projectName);
            if (index === -1) {
                acc.push({
                    projectName: val.projectName,
                });
            };
            return acc.sort((item1, item2) => item1.projectName < item2.projectName ? -1 : 0)
        }, []);
    };

    const aggregateProjectNumberArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectNumber === val.projectNumber);
            if (index === -1) {
                acc.push({
                    projectNumber: val.projectNumber,
                });
            };
            return acc.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)
        }, []);
    };

    const aggregateStagesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectCurrentRIBAStage === val.projectCurrentRIBAStage);
            if (index === -1) {
                acc.push({
                    stage: val.projectCurrentRIBAStage.stage
                });
            };
            return acc;
        }, []);
    };

    const aggregateServiceInvoiceClientArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceInvoiceClient === val.serviceInvoiceClient);
            if (index === -1) {
                acc.push({
                    serviceInvoiceClient: val.serviceInvoiceClient,
                });
            };
            return acc;
        }, []);
    };

    const aggregateProjectClientArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientName === val.clientName);
            if (index === -1) {
                acc.push({
                    clientName: val.clientName,
                });
            };
            return acc;
        }, []);
    };

    const viewProject = (id) => {
        window.open(`/viewClientProject/${id}`);
    }

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    
    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/users_internal_only')
        .then((resUsers) => {
            setUsers(resUsers.data);
            setUserId(resUsers.data.find(user => user.emailId === AuthService.getCurrentUserName()).id)

            axios.get(process.env.REACT_APP_API_URL + '/clients')
            .then((resClients) => {
                setClients(resClients.data);

                axios.get(process.env.REACT_APP_API_URL + '/projects_report')
                .then((res) => res.data)
                .then((res) => {

                    var tempProjectsData = res
                    var project, service
                    var servicesList = []
                    var serviceManagersList = []
                    var serviceInvoiceClientsList = []
                
                    for (project = 0; project < tempProjectsData.length; project++) {

                        servicesList = servicesList.concat(tempProjectsData[project].services.map((item) => {
                            return {
                                service: item.service,
                            }
                        }))
                        serviceManagersList = serviceManagersList.concat(tempProjectsData[project].services.map((item) => {
                            return {
                                serviceManager: resUsers.data.find(user => user.id === item.serviceManager).firstName + " " + resUsers.data.find(user => user.id === item.serviceManager).lastName,
                            }
                        }))

                        serviceInvoiceClientsList = serviceInvoiceClientsList.concat(tempProjectsData[project].services.map((item) => {
                            return {
                                serviceInvoiceClient: resClients.data.find(client => client.id === item.serviceInvoiceClient) ? resClients.data.find(client => client.id === item.serviceInvoiceClient).clientName : "",
                            }
                        }))

                        tempProjectsData[project].serviceManagersString = ""
                        tempProjectsData[project].servicesOnlyString = ""
                        tempProjectsData[project].serviceClientToInvoiceString = ""

                        for (service = 0; service < tempProjectsData[project].services.length; service++) {
                            tempProjectsData[project].serviceManagersString = tempProjectsData[project].serviceManagersString + resUsers.data.find(item => item.id === tempProjectsData[project].services[service].serviceManager).firstName + " " + resUsers.data.find(item => item.id === tempProjectsData[project].services[service].serviceManager).lastName + ";"
                            tempProjectsData[project].servicesOnlyString = tempProjectsData[project].servicesOnlyString + tempProjectsData[project].services[service].service + ";"
                            if (resClients.data.find(item => item.id === tempProjectsData[project].services[service].serviceInvoiceClient)) {
                                if (!tempProjectsData[project].serviceClientToInvoiceString.includes(resClients.data.find(item => item.id === tempProjectsData[project].services[service].serviceInvoiceClient).clientName)) {
                                    tempProjectsData[project].serviceClientToInvoiceString = tempProjectsData[project].serviceClientToInvoiceString + "\n●" + resClients.data.find(item => item.id === tempProjectsData[project].services[service].serviceInvoiceClient).clientName
                                }
                            }
                        }

                    }

                    setProjects(tempProjectsData)
                    setProjectsFiltered(tempProjectsData)

                    setSelectedProjectManager(aggregateProjectManagerArray(tempProjectsData).map((item) => {
                        return {
                            label: item.projectManager,
                            value: item.projectManager
                        }
                    }))
                    setSelectedServiceManager(aggregateServiceManagerArray(serviceManagersList).map((item) => {
                        return {
                            label: item.serviceManager,
                            value: item.serviceManager
                        }
                    }))

                    setSelectedProjectName(aggregateProjectNameArray(tempProjectsData.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                        return {
                            label: item.projectName,
                            value: item.projectName
                        }
                    }))

                    setSelectedProjectNumber(aggregateProjectNumberArray(tempProjectsData).map((item) => {
                        return {
                            label: item.projectNumber,
                            value: item.projectNumber
                        }
                    }))

                    setSelectedStage(aggregateStagesArray(tempProjectsData).map((item) => {
                        return {
                            label: item.stage,
                            value: item.stage
                        }
                    }))

                    setSelectedService(aggregateServicesArray(servicesList).map((item) => {
                        return {
                            label: item.service,
                            value: item.service
                        }
                    }))

                    setSelectedServiceInvoiceClient(aggregateServiceInvoiceClientArray(serviceInvoiceClientsList).map((item) => {
                        return {
                            label: item.serviceInvoiceClient,
                            value: item.serviceInvoiceClient
                        }
                    }))

                    setSelectedProjectClient(aggregateProjectClientArray(tempProjectsData.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                        return {
                            label: item.clientName,
                            value: item.clientName
                        }
                    }))

                    //*****************************************************************************************************************************

                    setProjectManagersToSelect(aggregateProjectManagerArray(tempProjectsData).map((item) => {
                        return {
                            label: item.projectManager,
                            value: item.projectManager
                        }
                    }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                    setServiceManagersToSelect(aggregateServiceManagerArray(serviceManagersList).map((item) => {
                        return {
                            label: item.serviceManager,
                            value: item.serviceManager
                        }
                    }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                    setProjectNamesToSelect(aggregateProjectNameArray(tempProjectsData.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                        return {
                            label: item.projectName,
                            value: item.projectName
                        }
                    }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                    setProjectNumbersToSelect(aggregateProjectNumberArray(tempProjectsData).map((item) => {
                        return {
                            label: item.projectNumber,
                            value: item.projectNumber
                        }
                    }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                    setStagesToSelect(aggregateStagesArray(tempProjectsData).map((item) => {
                        return {
                            label: item.stage,
                            value: item.stage
                        }
                    }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                    setServicesToSelect(aggregateServicesArray(servicesList).map((item) => {
                        return {
                            label: item.service,
                            value: item.service
                        }
                    }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                    setServiceInvoiceClientsToSelect(aggregateServiceInvoiceClientArray(serviceInvoiceClientsList).map((item) => {
                        return {
                            label: item.serviceInvoiceClient,
                            value: item.serviceInvoiceClient
                        }
                    }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                    setProjectClientsToSelect(aggregateProjectClientArray(tempProjectsData.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)).map((item) => {
                        return {
                            label: item.clientName,
                            value: item.clientName
                        }
                    }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setClientsLoading(false);
            });

        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setUsersLoading(false);
        });

    }, [])

    useEffect(() => {
        
        if (projects.length > 0 && !loading) {

            setProjectsFiltered(projects)

            setProjectsFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceManager.find((itemB) => {
                    return itemA.serviceManagersString.includes(itemB.value);
                })
            }))

            setProjectsFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedService.find((itemB) => {
                    return itemA.servicesOnlyString.includes(itemB.value);
                })
            }))

            setProjectsFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectManager.find((itemB) => {
                    return itemA.projectManager === itemB.value;
                })
            }))

            setProjectsFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectName.find((itemB) => {
                    return itemA.projectName === itemB.value;
                })
            }))

            setProjectsFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectNumber.find((itemB) => {
                    return itemA.projectNumber === itemB.value;
                })
            }))

            setProjectsFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedServiceInvoiceClient.find((itemB) => {
                    return itemA.serviceClientToInvoiceString.includes(itemB.value);
                })
            }))

            setProjectsFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedProjectClient.find((itemB) => {
                    return itemA.clientName === itemB.value;
                })
            }))

            setProjectsFiltered(servicesFiltered => servicesFiltered.filter((itemA) => {
                return selectedStage.find((itemB) => {
                    return itemA.projectCurrentRIBAStage.stage === itemB.value;
                })
            }))

            setFirstRecord(0)
            setLastRecord(100)

        }

        // setSortedField('projectNumber')
        // setSortedOrder('asc')


    }, [loading, selectedServiceManager, selectedService, selectedProjectManager, selectedProjectName, selectedProjectNumber, selectedStage, selectedServiceInvoiceClient, selectedProjectClient])


    if (projects.length > 0 && !loading && users.length > 0 && !usersLoading && clients.length > 0 && !clientsLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Projects" section="Clients Tracker" showUserMenu='true' />

                            <hr></hr>
                            <br></br>


                            <div className="flex items-center">
                                <div className="w-full mx-auto m-5 px-3">
                                    <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-9">

                                        <div>
                                        <p className="text-xs">Project Manager</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectManagersToSelect}
                                            value={selectedProjectManager}
                                            onChange={setSelectedProjectManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Service Manager</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={serviceManagersToSelect}
                                            value={selectedServiceManager}
                                            onChange={setSelectedServiceManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Service</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={servicesToSelect}
                                            value={selectedService}
                                            onChange={setSelectedService}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Name</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectNamesToSelect}
                                            value={selectedProjectName}
                                            onChange={setSelectedProjectName}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Number</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectNumbersToSelect}
                                            value={selectedProjectNumber}
                                            onChange={setSelectedProjectNumber}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Stage</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={stagesToSelect}
                                            value={selectedStage}
                                            onChange={setSelectedStage}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Client</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={projectClientsToSelect}
                                            value={selectedProjectClient}
                                            onChange={setSelectedProjectClient}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Client to Invoice</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={serviceInvoiceClientsToSelect}
                                            value={selectedServiceInvoiceClient}
                                            onChange={setSelectedServiceInvoiceClient}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div className="pl-3">
                                            <p className="text-xs font-bold">Filters</p>
                                            <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <br></br>

                            <hr></hr>

                            <br></br>

                            <div className="flex flex-col py-2">
                                <div className="overflow-x-auto items-holder-8A">
                                    <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                        <div className="shadow-md shadow-slate-300 rounded-2xl">
                                            <table className="objects-8A min-w-full divide-y divide-slate-200 text-xs">

                                                <thead className="bg-pacific-blue-50 sticky top-0">
                                                    <tr>
                                                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Client</th> */}
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Name</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Project Manager</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-60"> Services</th>
                                                        <th></th>
                                                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap "> Services List</th> */}
                                                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client to Invoice</th> */}
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Eight Versa Project Manager</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Contractor</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Stage</th>
                                                        <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Stage 0-1: Feasibility</th>
                                                        <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Stage 2: Pre-planning</th>
                                                        <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Stage 3-4: Design</th>
                                                        <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Stage 5: Construction</th>
                                                        <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Stage 6: PC & Handover</th>
                                                        <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Stage 7: In Use</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Progress Notes</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Comments</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client KPIs</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <button className="uppercase" type="button" onClick={() => { setSortedField('projectNumber'); sortedOrder === 'asc' ? setSortedOrder('desc') : setSortedOrder('asc') }}>{sortedField === 'projectNumber' && sortedOrder === 'asc' ? 'Project Number ↑' : sortedField === 'projectNumber' && sortedOrder === 'desc' ? 'Project Number ↓' : 'Project Number ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Project Name</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-slate-200">
                                                    {projectsFiltered.slice(firstRecord, lastRecord).map((project) => (
                                                        <tr key={project.projectId}>
                                                            {/* <td className="px-4 md:px-6  py-2 "> {project.clientName}</td> */}
                                                            <td className="px-4 md:px-6  py-2 "> {project.projectName}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {project.contactName}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-pre-line"><p className="w-44">{expandArrayElementsIntoConcatinatedString(project.services)}</p>
                                                                {projectIdsToExpand.filter(item => item.id === project.projectId).length > 0 &&  
                                                                <table className="text-xs">
                                                                    <thead>
                                                                    <tr>
                                                                        <th scope="col" className="pt-5 px-1 md:px-1  py-1 text-left text-xs font-medium text-congress-blue uppercase w-44">Services Breakdown</th>
                                                                    </tr>
                                                                    </thead>
                                                                <tbody>
                                                                    {project.services.filter(item => item.toBeDeleted === "NO").map((service) => (
                                                                    <tr key={service.serviceComments + service.service}>                               
                                                                        <td className={service.serviceStatus === "Live" ? "px-1 md:px-1 py-1 bg-emerald-200" : service.serviceStatus.slice(0,5) === "Quote" ? "px-1 md:px-1 py-1 bg-yellow-100" : "px-1 md:px-1 py-1 bg-indigo-100"}> {service.serviceComments === "" ? service.service : service.serviceComments}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>}
                                                            </td>
                                                            {/* <td className="px-4 md:px-6  py-2 whitespace-pre-line"> <p className = "w-[300px]">{expandArrayElementsIntoConcatinatedString(project.services)}</p></td> */}
                                                            <td onClick={(e) => {expandToggle(project.projectId)}} className="px-2 md:px-2  py-2">{projectIdsToExpand.filter(item => item.id === project.projectId).length === 0 ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 hover:cursor-pointer">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 hover:cursor-pointer">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                                </svg>}
                                                            </td>
                                                            {/* <td className="px-4 md:px-6  py-2 "> {project.serviceClientToInvoiceString}</td> */}
                                                            <td className="px-4 md:px-6  py-2 "> {project.projectManager}</td>
                                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(project.projectCurrentRIBAStage.contractor); setFieldToSendToModal("projectManagementContractor"); setObjectIdToSendToModal(project.projectId); setCollectionToSendToModal("projects") }} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {project.projectCurrentRIBAStage.contractor !== "" ? clients.find(item => item.id === project.projectCurrentRIBAStage.contractor).clientName : ""}</td>
                                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(project.projectCurrentRIBAStage.stage); setFieldToSendToModal("projectManagementStage"); setObjectIdToSendToModal(project.projectId); setCollectionToSendToModal("projects") }} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {project.projectCurrentRIBAStage.stage}</td>
                                                            <td onDoubleClick={() => {updateStageStatus("2", project.projectId)}}className={project.projectCurrentRIBAStage.stage2 === "green" ? "hover:cursor-pointer bg-emerald-200" : project.projectCurrentRIBAStage.stage2 === "yellow" ? "hover:cursor-pointer bg-yellow-100" : project.projectCurrentRIBAStage.stage2 === "red" ? "hover:cursor-pointer bg-red-300" : "hover:cursor-pointer"}> </td>
                                                            <td onDoubleClick={() => {updateStageStatus("3", project.projectId)}}className={project.projectCurrentRIBAStage.stage3 === "green" ? "hover:cursor-pointer bg-emerald-200" : project.projectCurrentRIBAStage.stage3 === "yellow" ? "hover:cursor-pointer bg-yellow-100" : project.projectCurrentRIBAStage.stage3 === "red" ? "hover:cursor-pointer bg-red-300" : "hover:cursor-pointer"}> </td>
                                                            <td onDoubleClick={() => {updateStageStatus("4", project.projectId)}}className={project.projectCurrentRIBAStage.stage4 === "green" ? "hover:cursor-pointer bg-emerald-200" : project.projectCurrentRIBAStage.stage4 === "yellow" ? "hover:cursor-pointer bg-yellow-100" : project.projectCurrentRIBAStage.stage4 === "red" ? "hover:cursor-pointer bg-red-300" : "hover:cursor-pointer"}> </td>
                                                            <td onDoubleClick={() => {updateStageStatus("5", project.projectId)}}className={project.projectCurrentRIBAStage.stage5 === "green" ? "hover:cursor-pointer bg-emerald-200" : project.projectCurrentRIBAStage.stage5 === "yellow" ? "hover:cursor-pointer bg-yellow-100" : project.projectCurrentRIBAStage.stage5 === "red" ? "hover:cursor-pointer bg-red-300" : "hover:cursor-pointer"}> </td>
                                                            <td onDoubleClick={() => {updateStageStatus("6", project.projectId)}}className={project.projectCurrentRIBAStage.stage6 === "green" ? "hover:cursor-pointer bg-emerald-200" : project.projectCurrentRIBAStage.stage6 === "yellow" ? "hover:cursor-pointer bg-yellow-100" : project.projectCurrentRIBAStage.stage6 === "red" ? "hover:cursor-pointer bg-red-300" : "hover:cursor-pointer"}> </td>
                                                            <td onDoubleClick={() => {updateStageStatus("7", project.projectId)}}className={project.projectCurrentRIBAStage.stage7 === "green" ? "hover:cursor-pointer bg-emerald-200" : project.projectCurrentRIBAStage.stage7 === "yellow" ? "hover:cursor-pointer bg-yellow-100" : project.projectCurrentRIBAStage.stage7 === "red" ? "hover:cursor-pointer bg-red-300" : "hover:cursor-pointer"}> </td>
                                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(project.projectCurrentRIBAStage.progressComments); setFieldToSendToModal("projectManagementProgressComments"); setObjectIdToSendToModal(project.projectId); setCollectionToSendToModal("projects") }} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {project.projectCurrentRIBAStage.progressComments} </td>
                                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer">
                                                                {commentThread(project.projectCurrentRIBAStage.commentThread, project.projectId)}
                                                            
                                                                <span>
                                                                <svg onClick={() => { setShowModal(true); setValueToSendToModal(""); setFieldToSendToModal("projectManagementCommentThread"); setObjectIdToSendToModal(project.projectId); setCollectionToSendToModal("projects") }} data-tip data-for="addCommentToolTip" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 hover:cursor-pointer">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                                </svg>
                                                                {projectIdsCommentThreadsToExpand.filter(item => item.id === project.projectId).length === 0 && project.projectCurrentRIBAStage.commentThread.length > 1 &&
                                                                <svg onClick={() => { expandToggleForCommentThread(project.projectId) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 hover:cursor-pointer float-right">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
                                                                </svg>
                                                                }
                                                                {projectIdsCommentThreadsToExpand.filter(item => item.id === project.projectId).length > 0 &&
                                                                <svg onClick={() => { expandToggleForCommentThread(project.projectId) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 hover:cursor-pointer float-right">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
                                                                </svg>
                                                                }
                                                                </span>
                                                            </td>
                                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer">
                                                                {clientKPIs(project.projectCurrentRIBAStage.clientKPIs, project.projectId)}
                                                            
                                                                <div >
                                                                <svg onClick={() => { setShowModal(true); setValueToSendToModal(""); setFieldToSendToModal("projectManagementKPI"); setObjectIdToSendToModal(project.projectId); setCollectionToSendToModal("projects") }} data-tip data-for="addCommentToolTip" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 hover:cursor-pointer">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                                </svg>
                                                                </div>
                                                            </td>
                                                            <td onClick={() => viewProject(project.projectId)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                                                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                                            </svg> {project.projectNumber}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {project.projectName}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={projects} role={role} onClose={() => setShowModal(false)} show={showModal} title="Update Project" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid gap-7 sm:grid-cols-3 ">
                                <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                                <div className="text-sm text-gray-500 text-center pt-2 ">{firstRecord + " to " + lastRecord + " of " + projectsFiltered.length}</div>
                                <Button text="Next 100 >>" onClick={() => next100Records()}></Button>
                            </div>

                            <div><div className="flex items-center bg-slate-100">
                                <div className="w-full px-5 mx-auto my-5">
                                    <div className="grid gap-7 sm:grid-cols-2 ">

                                    </div>
                                </div>
                            </div>
                            </div>
                            <br></br>
                            <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 header-8A'>{"Total Number of BREEAM Assessments: " + KPIData().filter(item => item.KPIDescription === "BREEAM").length + " at an average score of: " + (KPIData().filter(item => item.KPIDescription === "BREEAM").map((item) => item.KPIScore).reduce((prev, curr) => Number(prev) + Number(curr), 0) / KPIData().filter(item => item.KPIDescription === "BREEAM" && item.KPIScore !== "").length).toFixed(2)}</h1>
                            <div className="flex">
                                    <div className="grid grid-cols-5">
                                        <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Doughnut data={chartByType} options={pieOptions} />
                                        </div>
                                        <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Doughnut data={chartByStatus} options={pieOptionsByStatus} />
                                        </div>
                                        {/* <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Doughnut data={chartByStatusPlanning} options={pieOptionsByStatusPlanning} />
                                        </div> */}
                                        <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Doughnut data={chartByAchievedRating} options={pieOptionsByAchievedRating} />
                                        </div>
                                        <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Doughnut data={chartByTargetRating} options={pieOptionsByTargetRating} />
                                        </div>
                                    </div>
                            </div>

                            <div className="flex">
                                <div className="rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white w-full">
                                    <Bar data={chartBREEAMScoreEntiresByProject} options={barOptionsHorizontalBREEAMScore} height={70}/>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white w-full">
                                    <Bar data={chartBREEAMScoreEntiresByProjectInProgress} options={barOptionsHorizontalBREEAMScoreInProgress} height={70}/>
                                </div>
                            </div>

                            <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 header-8A'>{"Total Number of EPCs: " + KPIData().filter(item => item.KPIDescription === "EPC / Total emissions (kg/m2)").length}</h1>
                            <div className="flex">
                                    <div className="grid lg:grid-cols-3 grid-cols-1">
                                        {/* <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Doughnut data={chartByTypeEPC} options={pieOptions} />
                                        </div>
                                        <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Doughnut data={chartByStatusEPC} options={pieOptionsByStatus} />
                                        </div> */}
                                        <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Doughnut data={chartByAchievedRatingEPC} options={pieOptionsByAchievedRating} />
                                        </div>
                                    </div>
                            </div>

                            <div className="flex">
                                <div className="rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white w-full">
                                    <Bar data={chartEPCEntiresByProject} options={barOptionsHorizontalEPC} height={70}/>
                                </div>
                            </div>

                            <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 header-8A'>{"Total Number of LCA Embodied Carbon Entries: " + KPIData().filter(item => item.KPIDescription === "WLC: A-C, excluding B6 -B7 (kgCO2e/m2)").length + " at an average score of: " + (KPIData().filter(item => item.KPIDescription === "WLC: A-C, excluding B6 -B7 (kgCO2e/m2)").map((item) => item.KPIScore).reduce((prev, curr) => Number(prev) + Number(curr), 0) / KPIData().filter(item => item.KPIDescription === "WLC: A-C, excluding B6 -B7 (kgCO2e/m2)" && item.KPIScore !== "").length).toFixed(2)}</h1>
                            <div className="flex">
                                <div className="rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white w-full">
                                    <Bar data={chartLCAEntiresByProject} options={barOptionsHorizontal} height={70}/>
                                </div>
                            </div>

                            <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 header-8A'>{"Total Number of Water Consumption Entries: " + KPIData().filter(item => item.KPIDescription === "BREEAM water consumption (m3/person/yr)").length + " at an average score of: " + (KPIData().filter(item => item.KPIDescription === "BREEAM water consumption (m3/person/yr)").map((item) => item.KPIScore).reduce((prev, curr) => Number(prev) + Number(curr), 0) / KPIData().filter(item => item.KPIDescription === "BREEAM water consumption (m3/person/yr)" && item.KPIScore !== "").length).toFixed(2)}</h1>
                            <div className="flex">
                                <div className="rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white w-full">
                                    <Bar data={chartWaterEntiresByProject} options={barOptionsHorizontalWater} height={70}/>
                                </div>
                            </div>

                            <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 header-8A'>{"Total Number of PV Production Entries: " + KPIData().filter(item => item.KPIDescription === "PV energy production (kWh/m2)").length + " at an average score of: " + (KPIData().filter(item => item.KPIDescription === "BREEAM water consumption (m3/person/yr)").map((item) => item.KPIScore).reduce((prev, curr) => Number(prev) + Number(curr), 0) / KPIData().filter(item => item.KPIDescription === "BREEAM water consumption (m3/person/yr)" && item.KPIScore !== "").length).toFixed(2)}</h1>
                            <div className="flex">
                                <div className="rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white w-full">
                                    <Bar data={chartPVEntiresByProject} options={barOptionsHorizontalPV} height={70}/>
                                </div>
                            </div>

                            {/* <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(servicesFiltered)} /> */}
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Projects" section="Clients Tracker" showUserMenu='true' />

                        <br></br>
                        <hr></hr>
                        <br></br>
                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7">

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                </div>
                            </div>
                        </div>

                        <br></br>
                        <hr></hr>
                        <br></br>

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div role="status" className=" bg-white w-full p-4 space-y-4 border border-slate-200 divide-y divide-slate-200 rounded-2xl shadow-slate-300 shadow-md animate-pulse ">

                                        <div className="flex items-center justify-between">
                                            <div>
                                                <div className="h-5 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-5 bg-slate-300 rounded-full w-48"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        )

    }

}

export default ProjectsTrackerProjects
