import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'
import Button from './UI/Button'
import { v4 as uuidv4 } from 'uuid'
import AddOrUpdateBuildingBREEAMAssessment from './Buildings/AddOrUpdateBuildingBREEAMAssessment';

const FileUploadAndDownload = (propsIn) => {

    const defaultProps = {
        allowUploads: true,
        showThumbnails: false,
        showCamera: false,
        allowDeletes: false,
        filenamePrefix: "",
        sendNotif: false,
        organisation: "",
        footprint_id: "",
        allowZip: false,
        subcategory: "",
        strictNameCheck: true
    };

    const props = { ...defaultProps, ...propsIn }; 

    const [selectedFile, setSelectedFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [fileUploaded, setFileUploaded] = useState(false)
    const [fileDeleted, setFileDeleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [assessmentsLoading, setAssessmentsLoading] = useState(true)
    const [uploading, setUploading] = useState(false)
    const [deleting, setDeleting] = useState("")
    const [zipping, setZipping] = useState(false)
    // axios instance w/o auth token, because this one is for google storage signed url
    // auth header is NOT added in index.js interceptor
    const instance = axios.create({});

    const [buildingBREEAMAssessments, setBuildingBREEAMAssessments] = useState([]);

    const videoRef = useRef(null)
    const photoRef = useRef(null)

    const [cameraScreenVisable, setCameraScreenVisable] = useState(false)

    const [buildingBREEAMAssessmentForPhoto, setBuildingBREEAMAssessmentForPhoto] = useState('')
    const [buildingBREEAMCreditCatForPhoto, setBuildingBREEAMCreditCatForPhoto] = useState('')
    const [buildingBREEAMCreditForPhoto, setBuildingBREEAMCreditForPhoto] = useState('')
    const [buildingBREEAMDescriptionForPhoto, setBuildingBREEAMDescriptionForPhoto] = useState('')

    const [currentCoordinateLatitude, setCurrentCoordinateLatitude] = useState('')
    const [currentCoordinateLongitude, setCurrentCoordinateLongitude] = useState('')

    useEffect(() => {

        if (props.objectToLink) {

            //Get BREEAM assessment data
            axios.get(process.env.REACT_APP_API_URL + "/buildings_BREEAM_assessments")
                .then((response) => {
                    setBuildingBREEAMAssessments(response.data)

                    // if (props.objectToLink) {

                    setBuildingBREEAMAssessmentForPhoto(props.objectToLink.assessmentId)
                    setBuildingBREEAMCreditCatForPhoto(response.data.find(item => item.id === props.objectToLink.assessmentId).credits.find(item => item.id === props.objectToLink.assessmentItemId).creditCategoryCode)
                    setBuildingBREEAMCreditForPhoto(props.objectToLink.assessmentItemId)
                    // }

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setAssessmentsLoading(false);
                });
        }

    }, [props.objectToLink])

    useEffect(() => {

        if (fileUploaded || fileDeleted || loading || props.filesToShowArray) {
        //console.log("useEffect")
        setFileUploaded(false)
        setFileDeleted(false)
        //console.log(props.organisation, props.subcategory)
        // Alternate between these two lines below depending upon whether to get urls at same time as filnames for that id only
        axios.get(process.env.REACT_APP_API_URL + '/presigned_url/' + props.id)
            // axios.get(process.env.REACT_APP_API_URL + '/presigned_url')
            .then((res) => {
                if (props.filesToShowArray) {
                    if (props.subFolder) {
                        setFiles(res.data.filter((Item) => Item.name.substring(0, props.id.length + 1 + props.subFolder.length) === props.id + "/" + props.subFolder).filter((itemA) => {
                            return props.filesToShowArray.find((itemB) => {
                                return itemA.name.substring(props.id.length + 1 + props.subFolder.length + 1, itemA.name.length) === itemB.filename;
                            })
                        }))
                    } else {
                        setFiles(res.data.filter((Item) => Item.name.substring(0, props.id.length) === props.id && !Item.name.substring(props.id.length + 1, Item.name.length).includes("/")).filter((itemA) => {
                            return props.filesToShowArray.find((itemB) => {
                                return itemA.name.substring(props.id.length + 1, itemA.name.length) === itemB.filename;
                            })
                        }))
                    }

                } else {
                    if (props.subFolder) {
                        setFiles(res.data.filter((Item) => Item.name.substring(0, props.id.length + 1 + props.subFolder.length) === props.id + "/" + props.subFolder))
                    } else {
                        setFiles(res.data.filter((Item) => Item.name.substring(0, props.id.length) === props.id && !Item.name.substring(props.id.length + 1, Item.name.length).includes("/")))
                    }
                }

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
                setUploading(false)
                setZipping(false)
                setDeleting("")
            });

        setSelectedFile(null)

        }

    }, [fileUploaded, fileDeleted, props.id, props.subFolder, props.filesToShowArray, loading])

    useEffect(() => {
        if (cameraScreenVisable) {
            document.getElementById("cameraControlVisible").style.display = "block"
            document.getElementById("photoControlVisible").style.display = "none"
            // document.getElementById("pictureNotes").style.display = "none"
            document.querySelector('#savePictureButton').disabled = true
            document.querySelector('#retakePictureButton').disabled = true
            // document.querySelector('#addNotesToPictureButton').disabled = true  
            getVideo()
        }

    }, [videoRef, cameraScreenVisable])

    const getVideo = () => {

        navigator.mediaDevices.getUserMedia({ video: { width: 1920, height: 1080, facingMode: 'environment' } })
            .then(stream => {
                let video = videoRef.current
                video.srcObject = stream
                video.play()
            })
            .catch(err => {
                console.error(err)
            })
    }

    const stopVideo = () => {

        const video = document.querySelector('video');
        const mediaStream = video.srcObject;
        const tracks = mediaStream.getTracks();
        // There should only be one media stream in the returned array (the video), but in case the mic is also enabled 
        // in different browsers, loop through the returned array of media streams and so stop them all
        tracks.forEach(track => track.stop())

    }

    const takePhoto = () => {

        document.getElementById("cameraControlVisible").style.display = "none"
        document.getElementById("photoControlVisible").style.display = "block"

        document.querySelector('#savePictureButton').disabled = false
        document.querySelector('#retakePictureButton').disabled = false
        // document.querySelector('#addNotesToPictureButton').disabled = false

        const showCoordinates = (pos) => {
            setCurrentCoordinateLatitude(pos.coords.latitude)
            setCurrentCoordinateLongitude(pos.coords.longitude)
        }

        var width = 1920
        var height = 1080

        if (window.innerHeight > window.innerWidth) {
            width = 1080
            height = 1920
        }

        let video = videoRef.current
        let photo = photoRef.current

        photo.width = width
        photo.height = height

        let ctx = photo.getContext('2d')
        ctx.drawImage(video, 0, 0, width, height)

        setSelectedFile(photo.toDataURL("image/jpg"))

        var binary = atob(photo.toDataURL("image/jpg").split(',')[1]);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

        file.name = Math.floor(Date.now() / 1000) + ".jpg"

        setSelectedFile(file);

        navigator.geolocation.getCurrentPosition(showCoordinates)

    }

    const scrollTop = () => {

        //This should scroll to the top of the page
        document.getElementById("pageContainer").scrollIntoView()

    }

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const updateBREEAMPhotoData = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + buildingBREEAMAssessmentForPhoto, form)
    }

    const aggregateBREEAMCategoryCodes = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.creditCategoryCode === val.creditCategoryCode);
            if (index === -1) {
                acc.push({
                    creditCategoryCode: val.creditCategoryCode,
                });
            };
            return acc;
        }, []);
    };


    const zipIt = async (folder_id) => {

        setZipping(true)

        var newFileName = ""

        try {
            const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
            if (uuidPattern.test(props.id) && props.organisation && props.subcategory) {
                await axios.post(process.env.REACT_APP_API_URL + '/bulk_download_backend', { "folder": folder_id, "organisation": props.organisation, "subcategory": props.subcategory })
            }

            if (props.subcategory && props.filesToShowArray) {
                console.log("calling bulk_download_by_filenames")
                newFileName = await axios.post(process.env.REACT_APP_API_URL + '/bulk_download_by_filenames', { "folder": folder_id, "zip_name": props.subcategory, "filenames": props.filesToShowArray.map(item => item.filename) })
                console.log(newFileName)
            }

            if (props.onChange) {
                props.onChange(files.length, props.id, "upload")
            }

        } catch (err) {
            console.log(err);
        }

        setFileUploaded(true)
    }

    const deleteFile = async (file) => {

        var fileReferenced = []

        if (props.performFileRefChecks) {

            await axios.get(process.env.REACT_APP_API_URL + "/buildings_BREEAM_assessments")
                .then((response) => {

                    var assessment, credit

                    var BREEAMAssessmentsForBuilding = response.data.filter(item => item.buildingId === props.id)

                    for (assessment = 0; assessment < BREEAMAssessmentsForBuilding.length; assessment++) {
                        for (credit = 0; credit < BREEAMAssessmentsForBuilding[assessment].credits.length; credit++) {
                            if (BREEAMAssessmentsForBuilding[assessment].credits[credit].files.find(item => item.filename === file.slice(25, file.length))) {
                                fileReferenced.push({ projectName: BREEAMAssessmentsForBuilding[assessment].projectName, creditCategoryCode: BREEAMAssessmentsForBuilding[assessment].credits[credit].creditCategoryCode, creditDescription: BREEAMAssessmentsForBuilding[assessment].credits[credit].creditDescription })
                            }
                        }
                    }

                })
                .catch((err) => {
                    console.log(err);
                })

        }

        if (fileReferenced.length > 0) {

            var stringToShow
            var fileReference

            for (fileReference = 0; fileReference < fileReferenced.length; fileReference++) {

                stringToShow = "Assessment: " + fileReferenced[fileReference].projectName + " " +
                    fileReferenced[fileReference].creditCategoryCode + " " +
                    fileReferenced[fileReference].creditDescription + "\n"

            }

            window.alert("This file has been associated with a specific item.  Please ensure association with this file is removed before deletion:\n" + stringToShow)
            setDeleting("")

        } else {

            setDeleting(file)

            var stringWithoutPrefix = file.slice(37)

            if (window.confirm('Are you sure you wish to delete this item?')) {
                try {
                    await axios.delete(process.env.REACT_APP_API_URL + '/signed_url', { params: { file: file } })
                        .then((res) => {
                        })
                } catch (err) {
                    console.log(err);
                }

                if (props.onChange) {
                    props.onChange(files.length, props.id, "delete")
                }
                if (props.sendNotif) {
                    await axios.post(process.env.REACT_APP_API_URL + '/file_notification',
                        { "file": stringWithoutPrefix, "status": "deleted", "organisation": props.organisation, "footprint_id": props.footprint_id })
                }
                setFileDeleted(true)

            } else {

                setDeleting("")
            }

        }



    }

    const uploadFile = async (e) => {

        setUploading(true)

        let file = e;

        const formData = new FormData();

        formData.append("file", file)
        formData.append("id", props.id)
        formData.append("nameOnly", file.name)
        formData.append("strictNameCheck", props.strictNameCheck)

        if (props.subFolder) {
            formData.append("fileName", props.id + "/" + props.subFolder + "/" + props.filenamePrefix + file.name)

        } else {
            formData.append("fileName", props.id + "/" + props.filenamePrefix + file.name)
        }

        // await axios
        //     .post(process.env.REACT_APP_API_URL + "/signed_url", formData)
        //     .then(res => {
        //         instance.put(res.data, file, { headers: { 'Content-Type': 'application/octet-stream' } })
        //     })
        //     .catch(err => console.warn(err)).finally(() => {
        //         setFileUploaded(true);
        //     });

        var signedURLResponse

        await axios
            .post(process.env.REACT_APP_API_URL + "/signed_url", formData)
            .then(res => {
                signedURLResponse = res.data
            })
            .catch(err => window.alert(err.data))

        await instance.put(signedURLResponse, file, { headers: { 'Content-Type': 'application/octet-stream' } }).then(res => {
        })
        .catch(err => console.warn(err)).finally(() => {
            setFileUploaded(true);
        });

        if (props.onChange) {
            props.onChange(files.length, props.id, "upload")
        }
        if (props.sendNotif) {
            // TODO: if succesful upload/download
            await axios.post(process.env.REACT_APP_API_URL + '/file_notification',
                { "file": props.filenamePrefix + file.name, "status": "uploaded", "organisation": props.organisation, "footprint_id": props.footprint_id })
        }

        if (buildingBREEAMCreditForPhoto) {

            var newBREEAMAssessment = buildingBREEAMAssessments.find(item => item.id === buildingBREEAMAssessmentForPhoto)

            newBREEAMAssessment.credits.find(item => item.id === buildingBREEAMCreditForPhoto).files.push({ id: uuidv4(), filename: file.name, description: buildingBREEAMDescriptionForPhoto })

            updateBREEAMPhotoData(newBREEAMAssessment)

        }

        if (props.filesToShowArray) {

            props.filesToShowArray.push({ id: uuidv4(), description: "", filename: file.name })

        }

        // setFileUploaded(true)
        setCameraScreenVisable(false)
    }

    const getUrlToDownload = async (file) => {

        try {
            const resp = await axios.get(process.env.REACT_APP_API_URL + '/signed_url', { params: { file: file } })
                .then((res) => {
                    window.open(res.data)
                })
        } catch (err) {
            console.log(err);
        }
    }


    if (!loading && files.length > 0) {
        return (
            <div className="h-full">
                {cameraScreenVisable &&
                    <div className="w-[96%] h-auto absolute top-0 left-0 bg-white z-10 border-2 rounded-xl p-3 shadow-2xl shadow-black">
                        {/* <div className="bg-white flex justify-center"> */}
                        <div className="grid gap-2 grid-cols-4">
                            <div id="cameraControlVisible" className="flex items-center justify-between col-span-3 ">
                                <video id="video" className="w-full" autoPlay={true} playsInline={true} muted={true} ref={videoRef} onClick={takePhoto}></video>
                            </div>
                            <div id="photoControlVisible" className="flex items-center justify-between col-span-3 ">
                                <canvas id="snappedPhoto" className="w-full" ref={photoRef}></canvas>
                            </div>
                            <div className="flex flex-col ">
                                <button id="savePictureButton" className='shadow-md shadow-slate-300 rounded-full py-1 m-1 text-white bg-pacific-blue-500 whitespace-nowrap max-w-full disabled:opacity-50' onClick={() => { uploadFile(selectedFile); window.alert("Photo saved"); setCameraScreenVisable(!cameraScreenVisable); stopVideo(); }}>Save</button>
                                <button id="retakePictureButton" className='shadow-md shadow-slate-300 rounded-full py-1 m-1 text-white bg-pacific-blue-500 whitespace-nowrap max-w-full disabled:opacity-50' onClick={() => { document.getElementById("cameraControlVisible").style.display = "block"; document.getElementById("photoControlVisible").style.display = "none"; document.querySelector('#savePictureButton').disabled = true; document.querySelector('#retakePictureButton').disabled = true; }}>Retake</button>
                                {/* <button id="addNotesToPictureButton" className='shadow-md shadow-slate-300 rounded-full py-1 m-1 text-white bg-pacific-blue-500 whitespace-nowrap w-full  disabled:opacity-50' onClick={() => { document.getElementById("pictureNotes").style.display = "block" }}>Add Notes</button> */}
                                <button className='shadow-md shadow-slate-300 rounded-full py-1 m-1 text-white bg-pacific-blue-500 whitespace-nowrap max-w-full' onClick={() => { stopVideo(); setSelectedFile(""); setCameraScreenVisable(!cameraScreenVisable) }}>Close</button>

                                <div id="pictureNotes" className='pt-1'>
                                    <div className="p-1">
                                        <label htmlFor="description">Description:<br /></label>
                                        <textarea id="description" className='border-2 rounded-md max-w-full' name="description" placeholder="Description" rows="3" onChange={(e) => setBuildingBREEAMDescriptionForPhoto(e.target.value)}></textarea>
                                    </div>
                                    <div className="p-1">
                                        <label htmlFor="assessment">BREEAM Assessment: </label>
                                        <select value={buildingBREEAMAssessmentForPhoto} name="assessment" id="assessment" className='max-w-full' onChange={(e) => setBuildingBREEAMAssessmentForPhoto(e.target.value)} onClick={(e) => setBuildingBREEAMAssessmentForPhoto(e.target.value)}>
                                            {buildingBREEAMAssessments.map((assessment) => (
                                                <option key={assessment.id} value={assessment.id}>{assessment.projectName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {buildingBREEAMAssessmentForPhoto &&
                                        <div className="p-1">
                                            <label htmlFor="creditCategoryCode">BREEAM Category: </label>
                                            <select value={buildingBREEAMCreditCatForPhoto} name="creditCategoryCode" id="creditCategoryCode" className='max-w-full' onChange={(e) => setBuildingBREEAMCreditCatForPhoto(e.target.value)} onClick={(e) => setBuildingBREEAMCreditCatForPhoto(e.target.value)}>
                                                {aggregateBREEAMCategoryCodes(buildingBREEAMAssessments.find(item => item.id === buildingBREEAMAssessmentForPhoto).credits).map((credit) => (
                                                    <option key={credit.id} value={credit.creditCategoryCode}>{credit.creditCategoryCode}</option>
                                                ))}
                                            </select>
                                        </div>
                                    }

                                    {buildingBREEAMCreditCatForPhoto &&
                                        <div className="p-1">
                                            <label htmlFor="credit">BREEAM Credit: </label>
                                            <select value={buildingBREEAMCreditForPhoto} name="credit" id="credit" className='max-w-full' onChange={(e) => setBuildingBREEAMCreditForPhoto(e.target.value)} onClick={(e) => setBuildingBREEAMCreditForPhoto(e.target.value)}>
                                                {buildingBREEAMAssessments.find(item => item.id === buildingBREEAMAssessmentForPhoto).credits.filter(item => item.creditCategoryCode === buildingBREEAMCreditCatForPhoto).map((credit) => (
                                                    <option key={credit.id} value={credit.id}>{credit.creditDescription}</option>
                                                ))}
                                            </select>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {props.showCamera &&
                    <div className="m-2">
                        <button className='inline-block text-gray-600' onClick={() => { setCameraScreenVisable(!cameraScreenVisable); scrollTop() }}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                        </button>
                    </div>
                }
                {props.showThumbnails &&
                    <div className="columns-2">
                        {files.filter(item => item.name.slice(-3) === "jpg" || item.name.slice(-3) === "png" || item.name.slice(-3) === "JPG" || item.name.slice(-4) === "jpeg").map((item) => (
                            <div key={item.name} onClick={() => { getUrlToDownload(item.name) }} className="hover:cursor-pointer p-2">
                                <img src={item.url} className="object-cover w-full text-center rounded-md aspect-square" alt="" />
                            </div>
                        ))}
                    </div>
                }
                <table>
                    <tbody>
                        {props.allowZip && !zipping && (<p className='flex my-2' onClick={() => { zipIt(props.id) }}>
                            <p className=' font-bold mt-1'>Download all</p>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                            </svg>
                        </p>)}
                        {props.allowZip && zipping && (<div className='flex my-2' onClick={() => { zipIt(props.id) }}>
                            <p className=' font-bold mt-1'>Creating Zip File.... Please Wait.... A zip file will appear at the foot of the file list below when complete....</p>
                        </div>)}
                        {files.map((item) => (
                            <tr key={item.name}>
                                <td><p className="m-1 flex items-stretch">

                                    {/* {item.name.slice(-3) === "jpg" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/jpg-icon.png" alt=""></img>
                                    }
                                    {item.name.slice(-4) === "jpeg" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/jpg-icon.png" alt=""></img>
                                    }
                                    {item.name.slice(-3) === "png" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/jpg-icon.png" alt=""></img>
                                    } */}
                                    {item.name.slice(-3) === "jpg" || item.name.slice(-3) === "png" || item.name.slice(-3) === "JPG" || item.name.slice(-4) === "jpeg" ?
                                        <img src={item.url} className="m-0.5 object-contain h-5 w-5" alt="" />
                                        : <></>}
                                    {item.name.slice(-3) === "pdf" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/Adobe-PDF-Document-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-3) === "PDF" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/Adobe-PDF-Document-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-3) === "doc" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/Microsoft_Word-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-4) === "docx" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/Microsoft_Word-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-3) === "xls" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/excel-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-4) === "xlsx" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/excel-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-3) === "csv" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/excel-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-3) === "ods" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/excel-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-3) === "mp4" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/video-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-3) === "zip" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/zip-icon.png" alt=""></img>
                                    }

                                    {item.name.slice(-3) === "msg" &&
                                        <img className="m-0.5 object-contain h-5 w-5" src="/images/msg-icon.png" alt=""></img>
                                    }
                                    {/* <a href={`https://storage.googleapis.com/testing2-platformfiles/${item.name}`}></a> */}

                                    {!props.subFolder && (
                                        <p className="break-all">{item.name.slice(props.id.length + 1)}</p>
                                    )}

                                    {props.subFolder && (
                                        <p className="break-all">{item.name.slice(props.id.length + 1 + (props.subFolder.length + 1))}</p>
                                    )}
                                    {deleting !== item.name &&
                                        <p onClick={() => { getUrlToDownload(item.name) }} className="hover:cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-4 h-4 ml-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                        </svg>
                                        </p>
                                    }
                                    <p onClick={props.allowDeletes ? () => deleteFile(item.name) : () => { }} className="hover:cursor-pointer">
                                        {props.allowDeletes && deleting !== item.name &&
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-4 h-4 ml-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        }
                                        {props.allowDeletes && deleting === item.name &&
                                            <p className="pl-1 font-bold">
                                                Deleting....
                                            </p>
                                        }
                                    </p>
                                </p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {uploading && (
                    <div className="text-lg">
                        <br></br>
                        Uploading.... Please Wait.....
                        <br></br>
                    </div>
                )}

                {props.subcategory === "" && props.subFolder === "finalVersions" && props.allowUploads && !uploading && (
                    <div>
                        <input id="fileInSubFolder1" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileInSubFolder1">Choose a file</label>
                    </div>
                )}

                {props.subcategory === "" && props.subFolder === "appointmentDocs" && props.allowUploads && !uploading && (
                    <div>
                        <input id="fileInSubFolder2" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileInSubFolder2">Choose a file</label>
                    </div>
                )}

                {props.subcategory === "" && props.subFolder === "verificationDocs" && props.allowUploads && !uploading && (
                    <div>
                        <input id="fileInSubFolder3" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileInSubFolder3">Choose a file</label>
                    </div>
                )}

                {props.subcategory === "" && props.subFolder === "internalDocs" && props.allowUploads && !uploading && (
                    <div>
                        <input id="fileInSubFolder4" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileInSubFolder4">Choose a file</label>
                    </div>
                )}

                {props.subcategory !== "" && props.allowUploads && !uploading && (
                    <div>
                        <input className={props.fileUploadWindowPosition ? props.fileUploadWindowPosition : ""} id="fileForSubCategory" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileForSubCategory">Choose a file to upload</label>
                    </div>
                )}

                {props.subcategory === "" && !props.subFolder && props.allowUploads && !uploading && (
                    <div>
                        <input className={props.fileUploadWindowPosition ? props.fileUploadWindowPosition : ""} id={"file" + props.id} type="file" onChange={handleFileInput} />
                        <label htmlFor={"file" + props.id}>Choose a file</label>
                    </div>
                )}

                {selectedFile && !uploading && (
                    <div> <p>{selectedFile.name}</p>
                        <button className="btn-8A" onClick={() => uploadFile(selectedFile)}>Upload</button>
                    </div>
                )
                }
            </div>
        )
    } else {

        return (

            <div>

                {loading &&
                    <div>
                        Loading... Please Wait...
                    </div>
                }

                {props.subcategory === "" && props.subFolder === "finalVersions" && props.allowUploads && !uploading && !loading && (
                    <div>
                        <input id="fileInSubFolder1" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileInSubFolder1">Choose a file</label>
                    </div>
                )}

                {props.subcategory === "" && props.subFolder === "appointmentDocs" && props.allowUploads && !uploading && !loading && (
                    <div>
                        <input id="fileInSubFolder2" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileInSubFolder2">Choose a file</label>
                    </div>
                )}

                {props.subcategory === "" && props.subFolder === "verificationDocs" && props.allowUploads && !uploading && !loading && (
                    <div>
                        <input id="fileInSubFolder3" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileInSubFolder3">Choose a file</label>
                    </div>
                )}

                {props.subcategory === "" && props.subFolder === "internalDocs" && props.allowUploads && !uploading && !loading && (
                    <div>
                        <input id="fileInSubFolder4" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileInSubFolder4">Choose a file</label>
                    </div>
                )}

                {props.subcategory !== "" && props.allowUploads && !uploading && !loading && (
                    <div>
                        <input className={props.fileUploadWindowPosition ? props.fileUploadWindowPosition : ""} id="fileForSubCategory" type="file" onChange={handleFileInput} />
                        <label htmlFor="fileForSubCategory">Choose a file to upload</label>
                    </div>
                )}

                {props.subcategory === "" && !props.subFolder && props.allowUploads && !uploading && !loading && (
                    <div>
                        <input id="file" type="file" onChange={handleFileInput} />
                        <label htmlFor="file">Choose a file</label>
                    </div>
                )}
                {selectedFile && !uploading && !loading && (
                    <div> <p>{selectedFile.name}</p>
                        <button className="btn-8A" onClick={() => uploadFile(selectedFile)}>Upload</button>
                    </div>
                )
                }

                {uploading && (
                    <div className="text-lg">
                        <br></br>
                        Uploading.... Please Wait.....
                        <br></br>
                    </div>
                )}
            </div>

        )

    }

}

// FileUploadAndDownload.defaultProps = {
//     allowUploads: true,
//     showThumbnails: false,
//     showCamera: false,
//     allowDeletes: false,
//     filenamePrefix: "",
//     sendNotif: false,
//     organisation: "",
//     footprint_id: "",
//     allowZip: false,
//     subcategory: "",
//     strictNameCheck: true
// }

export default FileUploadAndDownload;