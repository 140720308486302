import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import Header from "../UI/Header";
import axios from "axios";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import Sidebar from "../UI/Sidebar";
import CountUp from "react-countup";
import Options from '../UI/Options'
import { MultiSelect } from "react-multi-select-component";
import ReactApexChart from "react-apexcharts";
import Button from '../UI/Button'
import ReactTooltip from "react-tooltip"
import { ExportToCsv } from 'export-to-csv'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ModalUpdateValue from '../UI/ModalUpdateValue'
import ProgressBar from '../UI/ProgressBar'
import FileUploadMultipleAndDownload from '../FileUploadMultipleAndDownload'
import ReactSelect from 'react-select'
import Moment from 'moment';
import AuthService from '../../services/AuthService'


const ViewDataCollectionTracker = () => {

    const [loading, setLoading] = useState(true);
    const [usersLoading, setUsersLoading] = useState(true);
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [organisations, setOrganisations] = useState([]);

    const [selectedOrganisation, setSelectedOrganisation] = useState({label: "", value: ""});
    const [selectedFootprint, setSelectedFootprint] = useState({label: "", value: ""});
    
    const [selectedScope, setSelectedScope] = useState([{label: "", value: ""}]);
    const [selectedSubCategory, setSelectedSubCategory] = useState([{label: "", value: ""}]);
    const [allFootprintData, setAllFootprintData] = useState([]);
    const [idToShowFilesFor, setIdToShowFilesFor] = useState("");
    const [filteredFootprints, setFilteredFootprints] = useState([]);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState('')
    const [selectedCommentTypes, setSelectedCommentTypes] = useState([{label: "Client", value: "Client"}, {label: "Consultant", value: "Consultant"}]);

    const [showModal, setShowModal] = useState(false)
    const [showModalMain, setShowModalMain] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')

    const [selectedDescription, setSelectedDescription] = useState([{label: "", value: ""}]);

    const [showAll, setShowAll] = useState(false);

    const URL_REGEX =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    
    const TextWithLinks = (content) =>  {

        const words = content.content.split('\n')
    
        return <div className="text-sm font-medium text-pacific-blue"><p className="my-1 whitespace-pre-line" >{words.map((word) => {
            return word.match(URL_REGEX) ? (
                <span key={word}>
                    <a href={word} target="_blank" className="underline">{word}</a>{'\n'}
                </span>
            ) : (
                word + '\n'
            );
        })}</p></div>
    
    }

    const navigate = useNavigate()

    const moment = require('moment');

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            // width: 420,
            fontSize: 17
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            // width: 420,
            fontSize: 17
        }),
    }


    const deleteCommentFromThread = async (id, footprintItemId) => {

        var newAllFootprintData

        await axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints/" + selectedFootprint.value)
            .then((res) => {

                newAllFootprintData = res.data

                newAllFootprintData.organisationFootprintItems.find(item => item.id === footprintItemId).commentThread = newAllFootprintData.organisationFootprintItems.find(item => item.id === footprintItemId).commentThread.filter(item => item.id !== id)

                axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + selectedFootprint.value, { ...newAllFootprintData })

                setAllFootprintData(newAllFootprintData)

            })
            .catch((err) => {
                console.log(err);
            })


    }

    const commentThread = (e, footprintItemId, itemValidated) => {

        //const commentThreadFiltered = e.filter(item => item.commentType === "Client" || item.commentType === "Consultant")

        var lastCommentId = ""
        
        const allComments = e.filter(item => item.commentType !== "")

        if (allComments.length > 0) {

            lastCommentId = allComments[allComments.length -1].id

        }
        
        const commentThreadFiltered = e.filter((itemA) => {
            return selectedCommentTypes.find((itemB) => {
                return itemA.commentType === itemB.value;
            })
        })

        if (commentThreadFiltered.length > 0) {

        return (
        <div className="pb-2">
            <table className="w-full">

                <tbody>
                    {commentThreadFiltered.map((commentInThread, i) => (
                        <tr key={commentInThread.id} className="bg-transparent">
                            <td className = "pr-2 w-14 border-b-2">{commentInThread.commentDate.slice(0,21)}</td>
                            <td className = "pr-2 w-38 border-b-2">{commentInThread.comment + " (" + commentInThread.commentType + ")"}</td>
                            <td className = "w-14 border-b-2">{users.find(item => item.id === commentInThread.commentUser) ? users.find(item => item.id === commentInThread.commentUser).firstName + " " + users.find(item => item.id === commentInThread.commentUser).lastName : ""}</td>
                            <td className = "pr-2 w-14 border-b-2">{userId === commentInThread.commentUser && commentInThread.id === lastCommentId && itemValidated !=="Validated" ? <button className="hover:cursor-pointer float-right" onClick={() => { deleteCommentFromThread(commentInThread.id, footprintItemId) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg></button> : <></>}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
        )
        }

    };

    const aggregateScopes = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.itemCategory === val.itemCategory);
            if (index === -1) {
                acc.push({
                    itemCategory: val.itemCategory,
                });
            };
            return acc.sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : 0);
        }, []);
    };

    const aggregateSubCategories = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.itemSubCategory === val.itemSubCategory);
            if (index === -1) {
                acc.push({
                    itemSubCategory: val.itemSubCategory,
                });
            };
            return acc;
        }, []);
    };

    const aggregateDescriptions = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.itemDescription === val.itemDescription);
            if (index === -1) {
                acc.push({
                    itemDescription: val.itemDescription,
                });
            };
            return acc;
        }, []);
    };
    
    const setUpdatedNumberOfFilesUploaded = async (e, id, operationType, numberOfFiles) => {

        var newAllFootprintData
        
        await axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints/" + selectedFootprint.value)
            .then((res) => {

                newAllFootprintData = res.data

                if (operationType === "upload") {

                    newAllFootprintData.organisationFootprintItems.find(item => item.id === id).filesUploaded = e + numberOfFiles

                }

                if (operationType === "delete") {

                    newAllFootprintData.organisationFootprintItems.find(item => item.id === id).filesUploaded = e - 1

                }

                setAllFootprintData(newAllFootprintData)

            })
            .catch((err) => {
                console.log(err);
            })
        
        await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + selectedFootprint.value, { ...newAllFootprintData })

    }

    const toggleValidated = async (id) => {

        var newAllFootprintData

        await axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints/" + selectedFootprint.value)
            .then((res) => {

                newAllFootprintData = res.data

                if (newAllFootprintData.organisationFootprintItems.find(item => item.id === id).itemTag === "") {
                    newAllFootprintData.organisationFootprintItems.find(item => item.id === id).itemTag = "Uploads Acknowledged"
                }
                
                else if (newAllFootprintData.organisationFootprintItems.find(item => item.id === id).itemTag === "Uploads Acknowledged") {
                    newAllFootprintData.organisationFootprintItems.find(item => item.id === id).itemTag = "Validated"
                }

                else if (newAllFootprintData.organisationFootprintItems.find(item => item.id === id).itemTag === "Validated") {
                    newAllFootprintData.organisationFootprintItems.find(item => item.id === id).itemTag = ""
                }
                setAllFootprintData(newAllFootprintData)

            })
            .catch((err) => {
                console.log(err);
            })

        await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + selectedFootprint.value, { ...newAllFootprintData })

    }

    const toggleWhichItemToShowFilesFor = (id) => {
   
        setIdToShowFilesFor(id)

    }

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const [numberOfItemsWhichHaveFilesUploaded, setNumberOfItemsWhichHaveFilesUploaded] = useState(0)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            if (role === 3001 || role === 6001) {

                axios.get(process.env.REACT_APP_API_URL + '/organisations_by_clientId/' + company)
                .then((res) => {
                    setOrganisations(res.data);

                    if (sessionStorage.getItem("organisationId") && res.data[0].clientId === company) {
                        setSelectedOrganisation({value: sessionStorage.getItem("organisationId"), label: res.data.find(item => item.id === sessionStorage.getItem("organisationId")).organisationName})
                        sessionStorage.removeItem("organisationId")
                    }

                    if (res.data.length === 1) {
                        setSelectedOrganisation({label: res.data[0].organisationName, value: res.data[0].id})
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });

                axios.get(process.env.REACT_APP_API_URL + '/users_internal_only')
                .then((res) => {
                    setUsers(res.data);
                    setUserId(res.data.find(user => user.emailId === AuthService.getCurrentUserName()).id)
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setUsersLoading(false);
                });


            } else {

                axios.get(process.env.REACT_APP_API_URL + '/organisations')
                    .then((res) => {
                        setOrganisations(res.data);
                        if (sessionStorage.getItem("organisationId")) {
                            setSelectedOrganisation({value: sessionStorage.getItem("organisationId"), label: res.data.find(item => item.id === sessionStorage.getItem("organisationId")).organisationName})
                            sessionStorage.removeItem("organisationId")
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });

                axios.get(process.env.REACT_APP_API_URL + '/users')
                    .then((res) => {
                        setUsers(res.data);
                        setUserId(res.data.find(user => user.emailId === AuthService.getCurrentUserName()).id)
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setUsersLoading(false);
                    });

            }

        }

    }, [role, company, userPropertiesLoading]);

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            if (role === 3001 || role === 6001) {

                if (selectedOrganisation.value !== "") {

                    axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints_by_organisationId/" + selectedOrganisation.value)
                    .then((res) => {

                        setFilteredFootprints(res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual"))

                        setSelectedFootprint({label: res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual")[res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual").length - 1].organisationFootprintYear + " - " + res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual")[res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual").length - 1].organisationFootprintRef, value: res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual")[res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual").length - 1].id})

                        if (sessionStorage.getItem("footprintId") && res.data[0].organisationId === selectedOrganisation.value) {
                            setSelectedFootprint({value: sessionStorage.getItem("footprintId"), label: res.data.find(item => item.id === sessionStorage.getItem("footprintId")).organisationFootprintYear + " - " + res.data.find(item => item.id === sessionStorage.getItem("footprintId")).organisationFootprintRef})
                            sessionStorage.removeItem("footprintId")
                        }

                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });

                }  

            } else {

                if (selectedOrganisation.value !== "") {

                    axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints_by_organisationId/" + selectedOrganisation.value)
                    .then((res) => {

                        setFilteredFootprints(res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual"))

                        if (sessionStorage.getItem("footprintId")) {
                            setSelectedFootprint({value: sessionStorage.getItem("footprintId"), label: res.data.find(item => item.id === sessionStorage.getItem("footprintId")).organisationFootprintYear + " - " + res.data.find(item => item.id === sessionStorage.getItem("footprintId")).organisationFootprintRef})
                            sessionStorage.removeItem("footprintId")
                        } else {
                            setSelectedFootprint({label: res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual")[res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual").length - 1].organisationFootprintYear + " - " + res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual")[res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual").length - 1].organisationFootprintRef, value: res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual")[res.data.filter(item => item.organisationFootprintType.slice(0,6) === "Actual").length - 1].id})
                        }

                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });

                }   
            }

            axios.post(process.env.REACT_APP_API_URL + "/log", {message: AuthService.getCurrentUserName() + " ViewDataCollectionTracker"})
        
        }

    }, [role, company, userPropertiesLoading, selectedOrganisation]);


    useEffect(() => {

        if (company && role && !userPropertiesLoading && selectedFootprint.value !== "") {

                axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints/" + selectedFootprint.value)
                    .then((res) => {

                        setAllFootprintData(res.data)

                        // var i
                        // var count = 0

                        // for (i = 0; i < res.data.organisationFootprintItems.length; i++) {
                        
                        //     if (res.data.organisationFootprintItems[i].filesUploaded > 0) {
                        //         count++
                        //     }

                        // }

                        // setNumberOfItemsWhichHaveFilesUploaded(count)

                        setSelectedScope(aggregateScopes(res.data.organisationFootprintItems).map((item) => {
                            return {
                                label: item.itemCategory,
                                value: item.itemCategory
                            }
                        }))
    
                        setSelectedSubCategory(aggregateSubCategories(res.data.organisationFootprintItems).map((item) => {
                            return {
                                label: item.itemSubCategory,
                                value: item.itemSubCategory
                            }
                        }))

                        setSelectedDescription(aggregateDescriptions(res.data.organisationFootprintItems.filter(item => showAll ? item.showOnTracker === "YES" || item.showOnTracker === "NO" : item.showOnTracker === "YES")).map((item) => {
                            return {
                                label: item.itemDescription,
                                value: item.itemDescription
                            }
                        }))

                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });

        }

    }, [role, company, selectedFootprint, userPropertiesLoading]);

    useEffect(() => {

        if (company && role && !userPropertiesLoading && allFootprintData.organisationFootprintItems) {

            setSelectedSubCategory(aggregateSubCategories(allFootprintData.organisationFootprintItems).filter((itemA) => {
                return selectedScope.find((itemB) => {
                    return itemA.itemSubCategory.slice(0,1) === itemB.value;
                })
                }).map((item) => {
                return {
                    label: item.itemSubCategory,
                    value: item.itemSubCategory
                }
            }))

        }

    }, [role, company, userPropertiesLoading, selectedScope, allFootprintData]);

    useEffect(() => {

        if (company && role && !userPropertiesLoading && allFootprintData.organisationFootprintItems) {

            var i
            var count = 0

            for (i = 0; i < allFootprintData.organisationFootprintItems.filter((itemA) => {
                return selectedScope.find((itemB) => {
                    return itemA.itemCategory === itemB.value;
                })
                }).filter((itemA) => {
                return selectedSubCategory.find((itemB) => {
                    return itemA.itemSubCategory === itemB.value;
                })
                }).length; i++) {
            
                if (allFootprintData.organisationFootprintItems.filter((itemA) => {
                    return selectedScope.find((itemB) => {
                        return itemA.itemCategory === itemB.value;
                    })
                    }).filter((itemA) => {
                    return selectedSubCategory.find((itemB) => {
                        return itemA.itemSubCategory === itemB.value;
                    })
                    })[i].filesUploaded > 0) {
                    count++
                }

            }

            setNumberOfItemsWhichHaveFilesUploaded(count)


        }

    }, [role, company, userPropertiesLoading, selectedSubCategory, selectedScope, allFootprintData]);


    if (
        organisations.length > 0 &&
        !loading &&
        users.length > 0 &&
        !usersLoading
    ) {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-congress-blue"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-white"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="flex bg-slate-100 h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Organisation Carbon Footprint" section="Data Collection Progress Tracker" iconFileName="Dashboard-Hover" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-3 gap-2 sm:gap-7 sm:grid-cols-4 z-30">
                                <div>
                                    <label>Organisation</label>
                                    <ReactSelect
                                        styles={styles}
                                        value={selectedOrganisation}
                                        onChange={setSelectedOrganisation}
                                        options={organisations.map((item) => {
                                            return {
                                                label: item.organisationName,
                                                value: item.id
                                            }
                                        }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)} />
                                </div>

                                <div>
                                    <label>Carbon Footprint</label>
                                    <ReactSelect
                                        styles={styles}
                                        value={selectedFootprint}
                                        onChange={setSelectedFootprint}
                                        options={filteredFootprints.map((item) => {
                                            return {
                                                label: item.organisationFootprintYear + " - " + item.organisationFootprintRef,
                                                value: item.id
                                            }
                                        }).sort((item1, item2) => item1.label > item2.label ? -1 : 0)} />
                                </div>

                                {allFootprintData.organisationFootprintItems &&

                                <>

                                <div>
                                    <label>Scope</label>
                                    <MultiSelect
                                        value={selectedScope}
                                        onChange={setSelectedScope}
                                        options={aggregateScopes(allFootprintData.organisationFootprintItems).map((item) => {
                                            return {
                                                label: item.itemCategory,
                                                value: item.itemCategory
                                            }
                                        })}
                                        labelledBy="Select"
                                        />
                                </div>

                                <div>
                                    <label>Sub Category</label>
                                    <MultiSelect
                                        value={selectedSubCategory}
                                        onChange={setSelectedSubCategory}
                                        options={aggregateSubCategories(allFootprintData.organisationFootprintItems).filter((itemA) => {
                                            return selectedScope.find((itemB) => {
                                                return itemA.itemSubCategory.slice(0,1) === itemB.value;
                                            })
                                            }).map((item) => {
                                            return {
                                                label: item.itemSubCategory,
                                                value: item.itemSubCategory
                                            }
                                        })}
                                        labelledBy="Select"
                                        />
                                </div>

                                </>

                                }
                                
                            </div>

                                {allFootprintData.organisationFootprintItems &&
                                <div className="grid grid-cols-3 gap-2 sm:gap-7 sm:grid-cols-3 z-30 p-5">
                                    <div><label>Data Collection Progress</label> <div className="pr-6"><ProgressBar bgcolor="#0091ca" completed={Math.round(((numberOfItemsWhichHaveFilesUploaded + allFootprintData.organisationFootprintItems.filter((itemA) => {
                                        return selectedScope.find((itemB) => {
                                            return itemA.itemCategory === itemB.value;
                                        })
                                        }).filter((itemA) => {
                                        return selectedSubCategory.find((itemB) => {
                                            return itemA.itemSubCategory === itemB.value;
                                        })
                                        }).filter(item => item.itemTag === "Validated" && item.filesUploaded === 0).length)/allFootprintData.organisationFootprintItems.filter((itemA) => {
                                            return selectedScope.find((itemB) => {
                                                return itemA.itemCategory === itemB.value;
                                            })
                                            }).filter((itemA) => {
                                            return selectedSubCategory.find((itemB) => {
                                                return itemA.itemSubCategory === itemB.value;
                                            })
                                            }).length) * 100)} completedLabel={Math.round(((numberOfItemsWhichHaveFilesUploaded+allFootprintData.organisationFootprintItems.filter((itemA) => {
                                                return selectedScope.find((itemB) => {
                                                    return itemA.itemCategory === itemB.value;
                                                })
                                                }).filter((itemA) => {
                                                return selectedSubCategory.find((itemB) => {
                                                    return itemA.itemSubCategory === itemB.value;
                                                })
                                                }).filter(item => item.itemTag === "Validated" && item.filesUploaded === 0).length)/allFootprintData.organisationFootprintItems.filter((itemA) => {
                                                    return selectedScope.find((itemB) => {
                                                        return itemA.itemCategory === itemB.value;
                                                    })
                                                    }).filter((itemA) => {
                                                    return selectedSubCategory.find((itemB) => {
                                                        return itemA.itemSubCategory === itemB.value;
                                                    })
                                                    }).length) * 100)} /></div></div>
                                    <div><label>Data Validation Progress</label> <div className="pr-6"><ProgressBar bgcolor="#0091ca" completed={Math.round((allFootprintData.organisationFootprintItems.filter((itemA) => {
                                        return selectedScope.find((itemB) => {
                                            return itemA.itemCategory === itemB.value;
                                        })
                                        }).filter((itemA) => {
                                        return selectedSubCategory.find((itemB) => {
                                            return itemA.itemSubCategory === itemB.value;
                                        })
                                        }).filter(item => item.itemTag === "Validated").length/allFootprintData.organisationFootprintItems.filter((itemA) => {
                                            return selectedScope.find((itemB) => {
                                                return itemA.itemCategory === itemB.value;
                                            })
                                            }).filter((itemA) => {
                                            return selectedSubCategory.find((itemB) => {
                                                return itemA.itemSubCategory === itemB.value;
                                            })
                                            }).length) * 100)} completedLabel={Math.round((allFootprintData.organisationFootprintItems.filter((itemA) => {
                                                return selectedScope.find((itemB) => {
                                                    return itemA.itemCategory === itemB.value;
                                                })
                                                }).filter((itemA) => {
                                                return selectedSubCategory.find((itemB) => {
                                                    return itemA.itemSubCategory === itemB.value;
                                                })
                                                }).filter(item => item.itemTag === "Validated").length/allFootprintData.organisationFootprintItems.filter((itemA) => {
                                                    return selectedScope.find((itemB) => {
                                                        return itemA.itemCategory === itemB.value;
                                                    })
                                                    }).filter((itemA) => {
                                                    return selectedSubCategory.find((itemB) => {
                                                        return itemA.itemSubCategory === itemB.value;
                                                    })
                                                    }).length) * 100)} /></div></div>
                                    <div><label>Calculation Progress</label> <div className="pr-6"><ProgressBar bgcolor="#0091ca" completed={Math.round((allFootprintData.organisationFootprintItems.filter((itemA) => {
                                        return selectedScope.find((itemB) => {
                                            return itemA.itemCategory === itemB.value;
                                        })
                                        }).filter((itemA) => {
                                        return selectedSubCategory.find((itemB) => {
                                            return itemA.itemSubCategory === itemB.value;
                                        })
                                        }).filter(item => item.submitted !== "").length/allFootprintData.organisationFootprintItems.filter((itemA) => {
                                            return selectedScope.find((itemB) => {
                                                return itemA.itemCategory === itemB.value;
                                            })
                                            }).filter((itemA) => {
                                            return selectedSubCategory.find((itemB) => {
                                                return itemA.itemSubCategory === itemB.value;
                                            })
                                            }).length) * 100)} completedLabel={Math.round((allFootprintData.organisationFootprintItems.filter((itemA) => {
                                                return selectedScope.find((itemB) => {
                                                    return itemA.itemCategory === itemB.value;
                                                })
                                                }).filter((itemA) => {
                                                return selectedSubCategory.find((itemB) => {
                                                    return itemA.itemSubCategory === itemB.value;
                                                })
                                                }).filter(item => item.submitted !== "").length/allFootprintData.organisationFootprintItems.filter((itemA) => {
                                                    return selectedScope.find((itemB) => {
                                                        return itemA.itemCategory === itemB.value;
                                                    })
                                                    }).filter((itemA) => {
                                                    return selectedSubCategory.find((itemB) => {
                                                        return itemA.itemSubCategory === itemB.value;
                                                    })
                                                    }).length) * 100)} /></div></div>
                                </div>
                                }

                                    {allFootprintData.organisationFootprintItems &&

                                    <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-2 z-30 p-5">

                                        <div className="flex items-center col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Supporting Documents: </div>
                                                <div className="flex items-center ">
                                                    <div className="text-md font-small text-pacific-blue "> <FileUploadMultipleAndDownload id={selectedFootprint.value} allowUploads={role === 4001 || role === 2004 || role === 6001 || role === 2001 || 3001 ? true : false} allowDeletes={role === 4001 || role === 2004 || role === 6001 || role === 2001 ? true : false}/></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between grid-cols-1 p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300 whitespace-pre-line">
                                            <div>
                                        {(role === 2001 || role === 4001 || role === 2004) &&
                                                    <div className = "hover:cursor-pointer" onClick={() => { setShowModalMain(true); setValueToSendToModal(allFootprintData.organisationFootprintNotes); setFieldToSendToModal("organisationFootprintNotes"); setObjectIdToSendToModal(selectedFootprint.value); setCollectionToSendToModal("organisations_footprints") }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5 mr-2" viewBox="0 0 20 20" fill="gray">
                                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                </svg>
                                                    <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={allFootprintData} onClose={() => setShowModalMain(false)} show={showModalMain} title="Update Footprint Notes" />
                                                    </div>
                                                }
                                                
                                                <TextWithLinks content = {allFootprintData.organisationFootprintNotes}></TextWithLinks>
                                                {/* {allFootprintData.organisationFootprintNotes} */}

                                                {selectedOrganisation.value !== "" && selectedFootprint.value !== "" &&
                                                    <Button color="#0090CA" text="View Footprint" onClick={() => {window.open(`/ViewOrganisationFootprint/${selectedOrganisation.value}/${selectedFootprint.value}`)}} />
                                                }

                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                </svg>
                                            </div>

                                        </div>

                                    </div>

                                    }

                                    {allFootprintData.organisationFootprintItems &&

                                    <div className="flex flex-col py-2">
                                        {/* <div className="overflow-x-auto items-holder-8A"> */}
                                        <div className="text-eight-text align-middle inline-block min-w-full px-3">

                                            <div className="shadow-md shadow-slate-300">

                                                <div>
                                                    <table className="objects-8A min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-pacific-blue-50 sticky top-0">
                                                        <tr>
                                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> ID</th> */}
                                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Scope</th> */}
                                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Sub Category</th>
                                                            {role !== 3001 &&
                                                            <>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Description<span className = "pl-2"><Button color="bg-bondi-blue" text={showAll ? "Hide Internal Items" : "Show All Items"} onClick={() => setShowAll(!showAll)}/></span><div className="w-52">
                                                            <MultiSelect
                                                                options={aggregateDescriptions(allFootprintData.organisationFootprintItems.filter(item => showAll ? item.showOnTracker === "YES" || item.showOnTracker === "NO" : item.showOnTracker === "YES")).map((item) => {
                                                                    return {
                                                                        label: item.itemDescription,
                                                                        value: item.itemDescription
                                                                    }
                                                                })}
                                                                value={selectedDescription}
                                                                onChange={setSelectedDescription}
                                                                labelledBy="Select"
                                                            />
                                                            </div></th>
                                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase "> tCO2e</th>
                                                            </>
                                                            }
                                                            {role === 3001 &&
                                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase w-48 2xl:w-72"> Description<div className="w-52">
                                                            <MultiSelect
                                                                options={aggregateDescriptions(allFootprintData.organisationFootprintItems.filter(item => showAll ? item.showOnTracker === "YES" || item.showOnTracker === "NO" : item.showOnTracker === "YES")).map((item) => {
                                                                    return {
                                                                        label: item.itemDescription,
                                                                        value: item.itemDescription
                                                                    }
                                                                })}
                                                                value={selectedDescription}
                                                                onChange={setSelectedDescription}
                                                                labelledBy="Select"
                                                            />
                                                            </div></th>
                                                            }
                                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase "> Documents Uploaded</th>
                                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase"> #</th>
                                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase"> Validated</th>
                                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase"> Notes</th>
                                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase"> Comments
                                                            {role !==3001 ? <div className="w-52 pt-3">
                                                        <MultiSelect
                                                            options={[{label: "Consultant" , value: "Consultant"}, {label: "Client" , value: "Client"}, {label: "Submission" , value: "Submission"}, {label: "QA" , value: "QA"}, {label: "Verification" , value: "Verification"}]}
                                                            value={selectedCommentTypes}
                                                            onChange={setSelectedCommentTypes}
                                                            labelledBy="Select"
                                                        />
                                                        </div> : <></>}
                                                        </th>
                                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase"> Status</th>
                                                        </tr>
                                                        </thead>
                                                        {allFootprintData.organisationFootprintItems &&
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                        {allFootprintData.organisationFootprintItems.filter(item => showAll ? item.showOnTracker === "YES" || item.showOnTracker === "NO" : item.showOnTracker === "YES").filter((itemA) => {
                                                        return selectedScope.find((itemB) => {
                                                            return itemA.itemCategory === itemB.value;
                                                        })
                                                        }).filter((itemA) => {
                                                        return selectedSubCategory.find((itemB) => {
                                                            return itemA.itemSubCategory === itemB.value;
                                                        })
                                                        }).filter((itemA) => {
                                                            return selectedDescription.find((itemB) => {
                                                                return itemA.itemDescription === itemB.value;
                                                            })
                                                            }).sort((item1, item2) => Number(item1.itemCategory) < Number(item2.itemCategory) ? -1 : Number(item1.itemCategory) > Number(item2.itemCategory) ? 1 : Number(item1.itemSubCategory.slice(2,4)) < Number(item2.itemSubCategory.slice(2,4)) ? -1 : Number(item1.itemSubCategory.slice(2,4)) > Number(item2.itemSubCategory.slice(2,4)) ? 1 : item1.itemDescription < item2.itemDescription ? -1 : item1.itemDescription > item2.itemDescription ? 1 : 0).map((organisationFootprintItem) => (
                                                            <tr key={organisationFootprintItem.id + organisationFootprintItem.itemTag}>
                                                            {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.id}</td> */}
                                                            {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}> {organisationFootprintItem.itemCategory}</td> */}
                                                            <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"subCatTip" + organisationFootprintItem.id}>{organisationFootprintItem.itemSubCategory}
                                                                <ReactTooltip id={"subCatTip" + organisationFootprintItem.id} place="top" effect="solid">
                                                                <div>{organisationFootprintItem.itemSubCategory === "1.1 Stationary Combustion" && <p>1. Direct energy consumption data from available invoices for each building owned and operated by the reporting organisation.
                                                                <br></br>2.	Financial value of invoices
                                                                <br></br>3.	Floor area of company facilities
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "1.2 Mobile Combustion" && <p>1.	Vehicle-level fuel consumption data
                                                                <br></br>2.	Mileage data 
                                                                <br></br>3.	Invoice values / Fuel records
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "1.3 Refrigerants" && <p>1. Direct energy consumption data from available invoices for each building owned and operated by the reporting organisation.
                                                                <br></br>2.	Financial value of invoices
                                                                <br></br>3.	Floor area of company facilities
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "2.1 Purchased Electricity" && <p>1. Direct energy consumption data from available invoices for each building owned and operated by the reporting organisation.
                                                                <br></br>2.	Financial value of invoices
                                                                <br></br>3.	Floor area of company facilities
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.1 Purchased Goods & Services" && <p>1. Product-level cradle to gate emissions data from selected suppliers
                                                                <br></br>2.	Mass value of goods purchased
                                                                <br></br>3.	Financial value of goods and services purchased
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.2 Capital Goods" && <p>1. Product-level cradle to gate emissions data from selected suppliers
                                                                <br></br>2.	Mass value of goods purchased
                                                                <br></br>3.	Total financial value of goods and services purchased
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.3 Fuel & Energy" && <p>1. Consumption data which covers the quantity and type of fuel consumed (Scope 1&2 data)</p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.4 Upstream Transportation & Distribution" && <p>1.	Fuel consumed for individual journeys (from selected suppliers)
                                                                <br></br>2.	Distance travelled and mode of transport used for individual journeys
                                                                <br></br>3.	Total spend by supplier for transportation services paid for by the reporting organisation
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.5 Waste" && <p>1. Supplier-specific scope 1 and 2 emissions of waste treatment methods
                                                                <br></br>2.	Total mass of waste generated and method of waste disposal
                                                                <br></br>3. Total cost of waste collection service
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.6 Business Travel" && <p>1. Fuel consumption data that quantifies the amount of fuel consumed during business travel
                                                                <br></br>2.	Distance travelled, and mode of transport used for business travel journeys
                                                                <br></br>3.	Total spend associated with each mode of business travel transport
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.7 Employee Commuting" && <p>1. Fuel consumption data that quantifies the amount of fuel consumed during commuting
                                                                <br></br>2. Data on employee commuting patterns (distance travelled and transport mode used for commuting)
                                                                <br></br>3.	Assumption-based estimates on distance travelled and mode of transport used, based on national averages and commuting patterns.
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.8 Upstream Leased Assets" && <p>1. Asset-specific fuel and energy use data (Scope 1 and 2 data) for individual assets/buildings
                                                                <br></br>2. Collect Scope 1 and 2 emissions data from the lessor(s) and allocate emissions to individual assets
                                                                <br></br>3. Estimation of emissions for each leased asset based on average datasets, such as average emissions per asset type or floor space
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.9 Downstream Transportation & Distribution" && <p>1. Fuel consumed for individual journeys (from selected suppliers)
                                                                <br></br>2. Distance travelled, and mode of transport used for individual journeys
                                                                <br></br>3. Total spend by supplier for transportation services paid for by the supplier
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.10 Processing of Sold Products" && <p>1.	Site-specific data which quantifies the amount of fuel, energy and waste generated from the processing of sold intermediate products
                                                                <br></br>2. Average-data method which estimates emissions from the processing of sold intermediate products using average secondary data, such as average emissions per product
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.11 Use of Sold Products" && <p>1. The data requirements for this category will depend on the specific product being measured as part of the assessment.
                                                                <br></br>Further guidance on data requirements for this category can be found in the GHG Scope 3 Calculation Guidance document. </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.12 End-of-Life Treatment of Sold Products" && <p>1. Data which quantifies the total mass of sold products and packaging to the consumer should be recorded.
                                                                <br></br>From this, the proportion of waste being treated by different disposal methods should then be detailed.</p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.13 Downstream Leased Assets" && <p>1. Asset-specific fuel and energy use data (Scope 1 and 2 data) for individual assets
                                                                <br></br>2.	Collect Scope 1 and 2 emissions data from the lessor(s) and allocate emissions to individual assets/buildings
                                                                <br></br>3.	Estimation of emissions for each leased asset based on average datasets, such as average emissions per asset type or floor space
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.14 Franchises" && <p>1. Collect site-specific Scope 1 and 2 emissions data for individual franchises
                                                                <br></br>2.	Estimate emissions for each franchise based on average statistics, such as average emissions per franchise type or floor space
                                                                </p>}</div>
                                                                <div>{organisationFootprintItem.itemSubCategory === "3.15 Investments" && <p>1.	The prioritisation of data for this category largely depends on the type of investment that has been made by the reporting company. 
                                                                <br></br>Further guidance on data requirements can be found in the GHG Scope 3 Calculation Guidance document. </p>}</div>
                                                                </ReactTooltip></td>
                                                                <td onClick={() => window.open(`/ViewOrganisationFootprintItemHelperTool/${selectedOrganisation.value}/${selectedFootprint.value}/${organisationFootprintItem.id}`)} className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} data-tip data-for={"descriptionTip" + organisationFootprintItem.id}> {organisationFootprintItem.itemDescription}<ReactTooltip id={"descriptionTip" + organisationFootprintItem.id} place="top" effect="solid"><div>{organisationFootprintItem.itemDescription + " (Click to Open Helper Tool and View Sub Items)"}</div></ReactTooltip></td>
                                                                {role !== 3001 &&
                                                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{(organisationFootprintItem.carbonEmissions / 1000).toFixed(2)}</td>
                                                                }
                                                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"} onClick={(e) => { toggleWhichItemToShowFilesFor(organisationFootprintItem.id) }}>{organisationFootprintItem.id === idToShowFilesFor ? <FileUploadMultipleAndDownload id={organisationFootprintItem.id} allowZip={true} strictNameCheck={false} subcategory={organisationFootprintItem.itemSubCategory} onChange={setUpdatedNumberOfFilesUploaded} filenamePrefix= {moment(Date.now()).locale('en').format("YYYY-MM-DD HH:mm:SS") + " " + organisationFootprintItem.itemDescription + " "} allowUploads={organisationFootprintItem.itemTag === "" ? true : false} allowDeletes={organisationFootprintItem.itemTag === "" ? true : false} sendNotif={true} organisation={selectedOrganisation.label} organisation_id ={selectedOrganisation.value} footprint_id={selectedFootprint.value}/> : "Click Here To Manage Files"}</td>
                                                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{organisationFootprintItem.filesUploaded}</td>
                                                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"} onClick={role !== 3001 && role !==6001 ? (e) => { toggleValidated(organisationFootprintItem.id)} : () => {}}>{organisationFootprintItem.itemTag === "Validated" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : organisationFootprintItem.itemTag === "Uploads Acknowledged" ? <img className="h-10" src="/images/LightGreenTick.png" alt=""></img> : organisationFootprintItem.filesUploaded > 0 ? <img className="h-10" src="/images/Amber-Tick.png" alt=""></img> : <img className="h-10" src="/images/Red-Cross.png" alt=""></img>}</td>
                                                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"} onClick={role !== 3001 && role !== 6001 ? () => { setShowModal(true); setValueToSendToModal(organisationFootprintItem.itemConsultantCommentForDataCollection); setFieldToSendToModal("itemConsultantCommentForDataCollection"); setObjectIdToSendToModal(selectedFootprint.value); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id)} : () => {}}>                                                                
                                                                {role !== 3001 && role !== 6001 &&
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                </svg>
                                                                }
                                                                <p className="whitespace-pre-line">{organisationFootprintItem.itemConsultantCommentForDataCollection}</p>
                                                                </td>
                                                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}>                                                                

                                                                {commentThread(organisationFootprintItem.commentThread, organisationFootprintItem.id, organisationFootprintItem.itemTag)}
                                                                

                                                                <div onClick={() => { setShowModal(true); setValueToSendToModal(""); setFieldToSendToModal("commentThread"); setObjectIdToSendToModal(selectedFootprint.value); setCollectionToSendToModal("organisations_footprints"); setCollectionArrayToSendToModal("organisationFootprintItems"); setCollectionArrayIdToSendToModal(organisationFootprintItem.id) }} >
                                                                <svg data-tip data-for="addCommentToolTip" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                                </svg>
                                                                <ReactTooltip id="addCommentToolTip" place="top" effect="solid"><div>Add Comment / Message / Question</div></ReactTooltip>
                                                                </div>
                                                                

                                                                </td>
                                                                {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>{organisationFootprintItem.itemTag !== "" && organisationFootprintItem.verified !== "Compliant" && organisationFootprintItem.submitted === "" ? "In Progress" : organisationFootprintItem.itemTag !== "" && organisationFootprintItem.verified !== "Compliant" && organisationFootprintItem.submitted !== "" ? "Submitted" : organisationFootprintItem.itemTag !== "" && organisationFootprintItem.verified === "Compliant" && organisationFootprintItem.submitted !== "" ? (organisationFootprintItem.carbonEmissions / 1000).toFixed(2) : ""}</td> */}
                                                                <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-lime border-b text-xs" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 hover:cursor-pointer border-b-amber-300 border-b text-xs" : "px-4 md:px-6  py-2 hover:cursor-pointer border-b-red-300 border-b text-xs"}>{organisationFootprintItem.itemTag === "Uploads Acknowledged" ? "In Progress" : organisationFootprintItem.itemTag === "Validated" && organisationFootprintItem.verified !== "Compliant" && organisationFootprintItem.submitted === "" ? "QA Complete - Ready for NCS Submission" : organisationFootprintItem.itemTag === "Validated" && organisationFootprintItem.verified !== "Compliant" && organisationFootprintItem.submitted !== "" ? "Submitted" : organisationFootprintItem.itemTag === "Validated" && organisationFootprintItem.verified === "Compliant" && organisationFootprintItem.submitted !== "" ? "Verified" : ""}</td> 
                                                            {/* <td className={organisationFootprintItem.verified === "Compliant" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-lime border-b text-xs 2xl:text-base" : organisationFootprintItem.submitted !== "" ? "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-amber-300 border-b text-xs 2xl:text-base" : "px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer border-b-red-300 border-b text-xs 2xl:text-base"}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15" />
                                                            </svg></td> */}
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                        }

                                                    </table>
                                                    <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={allFootprintData.organisationFootprintItems} arrayIdToUpdate={collectionArrayIdToSendToModal} onClose={() => setShowModal(false)} show={showModal} role={role} title="Update Comment" />
                                                   
                                                </div>


                                            </div>

                                        </div>
                                        {/* </div> */}
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                
                    </div>
                </div>
        );
    } else {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                    <Header title="Organisation Carbon Footprint" section="Data Collection Progress Tracker" iconFileName="Dashboard-Hover" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <p>Data is loading...</p>
                    </div>

                </div>
            </div>
        );
    }
};

export default ViewDataCollectionTracker;
