import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import ProgressBar from '../UI/ProgressBar'
import { useNavigate,Navigate } from 'react-router-dom'
import Sidebar from '../UI//Sidebar'

const ListStores = () => {
        
    const [stores, setStores] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)
    const [redirecting, setRedirecting] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL

    const navigate = useNavigate ()

    useEffect(() => {

        setLoading(true)

        axios.get(API_URL + "/stores")
            .then((res) => {
                setStores(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
            
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    const deleteStore = (id) => {
        window.confirm('Are you sure you want to delete it?')
    }

    const editStore = (id) =>{
        navigate(`/addOrUpdateStore/${id}`);
    }

    const viewStore = (id) =>{
        navigate(`/viewStore/${id}`);
    }

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (stores.length > 0 && !loading && !redirecting) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                            <Header title="Stores" section="Select Store" iconFileName="BE-Hover" showUserMenu='true'/>

                        <div className="flex flex-wrap">
                            {stores.map((store) => (
                                <div className="bg-slate-200 w-64 shadow-xl border-2 p-5 m-3 content-center rounded-md" key={store.id}>
                                <img className="p-1 h-24 w-32 text-center" src={"/images/store_images/" + store.storeName + ".jpg"} alt="" />
                                <p className = "text-xs pt-1">{store.storeName}</p>
                                <p className = "h-10 overflow-hidden text-xs">{store.storeAddress}</p>
                                <p className = "text-xs">{store.storeContact}</p>
                                <Button color="black" text="View" onClick={() => viewStore(store.id)} /><Button color="black" text="Edit" onClick={() => editStore(store.id)} /><Button color="black" text="Delete" onClick={() => deleteStore(store.id)} /><ProgressBar bgcolor="#0091ca" completed={Math.round((store.wellbeingScore/6)*100)} completedLabel={Math.round((store.wellbeingScore/6)*100)} />
                                </div>
                            ))}
                        </div>

                            <br></br>
                            <Button color="black" text="Add New Store" onClick={() => navigate('/addOrUpdateStore/_add', { replace: true })}/>
                        </div>
                    </div>

                </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                    <Header title="Stores" section="Select Store" iconFileName="BE-Hover"/>
                    <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }    

}

export default ListStores
