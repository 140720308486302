import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../UI/Header";
import { useNavigate, useMatch } from "react-router-dom";
import authHeader from "../../services/AuthHeader";
import ReactSelect from 'react-select'
import Button from '../UI/Button'
import Options from '../UI/Options'
import ModalEmailInfo from '../UI/ModalEmailInfo'
import AuthService from '../../services/AuthService';

const AddOrUpdateClient = () => {
    const match = useMatch("/addOrUpdateClient/:clientId");

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [contactsLoading, setContactsLoading] = useState(true);
    const [usersLoading, setUsersLoading] = useState(true);


    const [clientName, setClientName] = useState("");
    const [clientAddress, setClientAddress] = useState("");
    const [clientContact, setClientContact] = useState("");
    const [clientAddress2, setClientAddress2] = useState("");
    const [clientTown, setClientTown] = useState("");
    const [clientCounty, setClientCounty] = useState("");
    const [clientPostCode, setClientPostCode] = useState("");
    const [clientCountry, setClientCountry] = useState("");
    const [requirePoForInvoicing, setRequirePoForInvoicing] = useState("NO");
    const [clientCertificationNumber, setClientCertificationNumber] = useState("0");
    const [clientBDStatus, setClientBDStatus] = useState("");
    const [clientFinanceConfirmed, setClientFinanceConfirmed] = useState("NO");
    const [clientComments, setClientComments] = useState("");
    const [clientInternalKeyPerson, setClientInternalKeyPerson] = useState("");
    const [clientAKA, setClientAKA] = useState("");
    const [showModalEmailInfo, setShowModalEmailInfo] = useState(false)
    const [dataToSendToModalInfo, setDataToSendToModalInfo] = useState('')

    const [contacts, setContacts] = useState([]);
    const [users, setUsers] = useState([]);

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 14
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 14
        }),
    }

    const getEmails = async (contactEmailAddress) => {
    
        setDataToSendToModalInfo(contactEmailAddress);            
        setShowModalEmailInfo(true);

    }

    const checkClientName = async (newClientString) => {
        var stringOfClients = ""
        var clientInstance
        var numberOfClientsToShow

        var clients
        var clientsMatchingCheckString

        await axios.get(process.env.REACT_APP_API_URL + '/clients')
            .then((res) => {
                clients = res.data
            })
            .catch((err) => {
                console.log(err);
            })

        clientsMatchingCheckString = clients.filter(item => item.clientName.toLowerCase().match(newClientString.toLowerCase()) || item.clientAKA.toLowerCase().match(newClientString.toLowerCase())) 

        if (clientsMatchingCheckString.length > 0) {

            if (clientsMatchingCheckString.length < 6) {
                numberOfClientsToShow = clientsMatchingCheckString.length
            } else {
                numberOfClientsToShow = 5
            }

            for (clientInstance=0; clientInstance < numberOfClientsToShow; clientInstance++) {

                stringOfClients = stringOfClients + clientsMatchingCheckString[clientInstance].clientName + "\n"

            }

            window.alert("These clients already exist with '" + newClientString + "' in the client name:\n" + stringOfClients + "....\n (" + (clientsMatchingCheckString.length) + " in total)")
        
        } else {

            window.alert("No exisitng clients have '" + newClientString + "' in the client name")
        }

    }

    useEffect(() => {
        if (match.params.clientId !== "_add") {
            setLoading(true);

            axios.get(process.env.REACT_APP_API_URL + "/clients/" + match.params.clientId)
                .then((response) => {
                    setClientName(response.data.clientName);
                    setClientAddress(response.data.clientAddress);
                    setClientAddress2(response.data.clientAddress2);
                    setClientTown(response.data.clientTown);
                    setClientCounty(response.data.clientCounty);
                    setClientContact(response.data.clientContact);
                    setClientPostCode(response.data.clientPostCode);
                    setClientCountry(response.data.clientCountry);
                    setRequirePoForInvoicing(response.data.requirePoForInvoicing);   
                    setClientCertificationNumber(response.data.clientCertificationNumber);
                    setClientBDStatus(response.data.clientBDStatus);   
                    setClientFinanceConfirmed(response.data.clientFinanceConfirmed);      
                    setClientComments(response.data.clientComments);  
                    setClientInternalKeyPerson(response.data.clientInternalKeyPerson);  
                    setClientAKA(response.data.clientAKA);                 
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });

            axios.get(process.env.REACT_APP_API_URL + "/contacts_by_clientId/" + match.params.clientId)
                .then((response) => {
                    setContacts(response.data);            
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setContactsLoading(false);
                });
        } else {

            setLoading(false)
            setContactsLoading(false)
            setContacts([])
        }

        axios.get(process.env.REACT_APP_API_URL + '/users')
        .then((res) => {
            setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
            if (match.params.clientId === "_add") {
                setClientInternalKeyPerson(res.data.sort((item1, item2) => item1.firstName < item2.firstName ? -1 : 0)[0].id)
            }
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setUsersLoading(false);
        });
    }, [match.params.clientId]);

    const addForm = async (form) => {
        await axios.post(
            process.env.REACT_APP_API_URL + "/clients",
            form,
            { headers: authHeader() }
        )
    };

    const addFormAndOpenNewContactForm = async (form) => {
        await axios.post(
            process.env.REACT_APP_API_URL + "/clients",
            form,
            { headers: authHeader() }
        ).then((response) => {
            navigate(`/addOrUpdateContact/${response.data.id}/_add`)            
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const updateForm = async (form) => {
        await axios.put(
            process.env.REACT_APP_API_URL + "/clients/" + match.params.clientId,
            form
        );
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (match.params.clientId !== "_add") {
            updateForm({ clientName, clientAddress, clientContact, clientAddress2, clientTown, clientCounty, clientPostCode, clientCountry, requirePoForInvoicing, clientCertificationNumber, clientBDStatus, clientFinanceConfirmed, clientComments, clientInternalKeyPerson, clientAKA }).then((res) => {
                navigate('/listClients');
            });
        } else {
            addForm({ clientName, clientAddress, clientContact, clientAddress2, clientTown, clientCounty, clientPostCode, clientCountry, requirePoForInvoicing, clientCertificationNumber, clientBDStatus, clientFinanceConfirmed, clientComments, clientInternalKeyPerson, clientAKA }).then((res) => {
                navigate('/listClients');
            });
        }
    };

    if (!loading && !contactsLoading && users.length > 0 && !usersLoading) {
        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                    <Header
                        title="Client - Add / Edit Client"
                        section="Client Main Details"
                    />

                <form className="add-form-8A" onSubmit={onSubmit}>
                    <div className="form-control-8A">
                        <label>Client Name</label>
                        <input
                            type="text"
                            placeholder="Enter Client Name Here"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                        />
                    </div>
                    {match.params.clientId === "_add" &&
                        <Button color="bg-bondi-blue" text="Check Client Name" onClick={() => checkClientName(clientName)} />
                    }
                    <div className="form-control-8A">
                        <label>Alternative Client Name (If applicable)</label>
                        <input
                            type="text"
                            placeholder="Enter Alternative Client Name Here"
                            value={clientAKA}
                            onChange={(e) => setClientAKA(e.target.value)}
                        />
                    </div>
                    <div className="form-control-8A">
                        <label>Key Internal Person</label>
                        <select value={clientInternalKeyPerson} onChange={(e) => setClientInternalKeyPerson(e.target.value)}>
                            {users.filter(user => user.role !== 9999 && user.role !== 9998 && user.role !== 3001).map((user) => (
                                <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-control-8A">
                        <label>Client Address 1</label>
                        <input
                            type="text"
                            placeholder="Enter Client Address 1 Here"
                            value={clientAddress}
                            onChange={(e) => setClientAddress(e.target.value)}
                        />
                    </div>
                    <div className="form-control-8A">
                        <label>Client Address 2</label>
                        <input
                            type="text"
                            placeholder="Enter Client Address 2 Here"
                            value={clientAddress2}
                            onChange={(e) => setClientAddress2(e.target.value)}
                        />
                    </div>
                    <div className="form-control-8A">
                        <label>Client Town</label>
                        <input
                            type="text"
                            placeholder="Enter Client Town Here"
                            value={clientTown}
                            onChange={(e) => setClientTown(e.target.value)}
                        />
                    </div>
                    <div className="form-control-8A">
                        <label>Client County</label>
                        <input
                            type="text"
                            placeholder="Enter Client County Here"
                            value={clientCounty}
                            onChange={(e) => setClientCounty(e.target.value)}
                        />
                    </div>
                    <div className="form-control-8A">
                        <label>Client Post Code</label>
                        <input
                            type="text"
                            placeholder="Enter Client Post Code Here"
                            value={clientPostCode}
                            onChange={(e) => setClientPostCode(e.target.value)}
                        />
                    </div>
                    <div className="form-control-8A">
                        <label>Client Country</label>
                        <input
                            type="text"
                            placeholder="Enter Client Country Here"
                            value={clientCountry}
                            onChange={(e) => setClientCountry(e.target.value)}
                        />
                    </div>
                    <div className="form-control-8A">
                            <label>Client Requires a PO for Invoicing</label>
                            <select value={requirePoForInvoicing} onChange={(e) => setRequirePoForInvoicing(e.target.value)}>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>
                    <div className="form-control-8A">
                        <label>Client Certification Number</label>
                        <input
                            className="bg-slate-100"
                            readOnly={true}
                            type="text"
                            placeholder="Enter Client Certification Number Here"
                            value={clientCertificationNumber}
                            onChange={(e) => setClientCertificationNumber(e.target.value)}
                        />
                    </div>
                    <div className="form-control-8A">
                            <label>Client BD Status</label>
                            <select value={clientBDStatus} onChange={(e) => setClientBDStatus(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Seed">Seed</option>
                                <option value="Sapling">Sapling</option>
                                <option value="Tree">Tree</option>
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Confirmed by Finance Team</label>
                            <select value={clientFinanceConfirmed} onChange={(e) => setClientFinanceConfirmed(e.target.value)}>
                                {/* <option value="">Please Select...</option> */}
                                <option value="NO">NO</option>
                                <option value="YES">YES</option>
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Comments</label>
                            <textarea className="w-full h-16 border-2 rounded-lg shadow-md" type="text" placeholder="Enter Comments Here" value={clientComments} onChange={(e) => setClientComments(e.target.value)} />
                        </div>

                    <div>Contacts:</div>
                    {match.params.clientId !== "_add" &&
                    <Button className='bg-bondi-blue' text="Add New Contact" onClick={() => {updateForm({ clientName, clientAddress, clientContact, clientAddress2, clientTown, clientCounty, clientPostCode, clientCountry, requirePoForInvoicing, clientCertificationNumber, clientBDStatus, clientFinanceConfirmed, clientComments, clientInternalKeyPerson, clientAKA }).then((res) => {navigate(`/addOrUpdateContact/${match.params.clientId}/_add`)})}}/>
                    }

                    {match.params.clientId === "_add" &&
                    <Button className='bg-bondi-blue' text="Add New Contact" onClick={() => {addFormAndOpenNewContactForm({ clientName, clientAddress, clientContact, clientAddress2, clientTown, clientCounty, clientPostCode, clientCountry, requirePoForInvoicing, clientCertificationNumber, clientBDStatus, clientFinanceConfirmed, clientComments, clientInternalKeyPerson, clientAKA })}}/>
                    }

                    <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl m-4">
                        <table className="objects-8A min-w-full divide-y divide-gray-200">

                            <thead className="bg-pacific-blue-50">
                                <tr>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Contact Name</th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Telephone No</th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Email</th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Check Emails</th>
                                </tr>
                            </thead>
                            {contacts.length > 0 &&
                            <tbody className="bg-white divide-y divide-gray-200">
                                {contacts.map((contact, i) => (
                                    <tr key={contact.id}>
                                        <td className="px-4 md:px-6  py-2"> {contact.contactName} </td>
                                        <td className="px-4 md:px-6  py-2"> {contact.contactTelephoneNumber} </td>
                                        <td className="px-4 md:px-6  py-2"> {contact.contactEmail} </td>
                                        <td className="text-center whitespace-nowrap"><Options edit={`/addOrUpdateContact/${match.params.clientId}/${contact.id}`}></Options></td>
                                        <td className="text-center whitespace-nowrap"><Button className='bg-bondi-blue' text="Check proposals@" onClick={() => getEmails(contact.contactEmail)}/></td>
                                    </tr>
                                ))}
                            </tbody>
                            }

                        </table>
                    </div>
        

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />
                    </form>
                    <ModalEmailInfo data={dataToSendToModalInfo} toFrom = {true} userEmailAddress={AuthService.getCurrentUserName()} onClose={() => setShowModalEmailInfo(false)} show={showModalEmailInfo} title={"Project Related Email Communication to / from " + dataToSendToModalInfo} />
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <Header
                    title="Client - Add / Edit Client"
                    section="Client Main Details"
                />
                <p>Data is loading...</p>
            </div>
        );
    }
};

export default AddOrUpdateClient;
