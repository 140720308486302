import { useState, useEffect } from 'react'
import Button from './Button'
import axios from 'axios'
import Note from './Note'
import DOMPurify from 'dompurify';

import { Watch } from  'react-loader-spinner'

const ModalEmailInfo = (props) => {

    const [allNotes, setAllNotes] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [fromSearchText, setFromSearchText] = useState("")
    const [toSearchText, setToSearchText] = useState("")
      
    const API_URL = process.env.REACT_APP_API_URL;

    var arrNoteData = []

    const showToggle = (id) => {

        var tempNotes = [...allNotes]

        tempNotes.find(item => item.id === id).showBody = !tempNotes.find(item => item.id === id).showBody

        setAllNotes(tempNotes)

    }



    const getEmails = async () => {

        setLoading(true)

        if (props.toFrom) {

            await axios.post(process.env.REACT_APP_API_URL + "/emailSearch_by_to_from", {searchString: props.data})
            .then((response) => {
                console.log(response.data)
                setAllNotes(response.data.sort((item1, item2) => Date.parse(item1.datetime) < Date.parse(item2.datetime) ? -1 : 0).map(item => ({
                    ...item, // Spread existing key-value pairs
                    showBody: false // Add new key-value pair
                  })))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);                
            });

        } else {
    
        await axios.post(process.env.REACT_APP_API_URL + "/emailSearch", {searchString: props.data})
        .then((response) => {
            console.log(response.data)
            setAllNotes(response.data.sort((item1, item2) => Date.parse(item1.datetime) < Date.parse(item2.datetime) ? -1 : 0).map(item => ({
                ...item, // Spread existing key-value pairs
                showBody: false // Add new key-value pair
              })))
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);                
        });

        }

    }

    const forwardEmail = async (message_id) => {
    
        await axios.post(process.env.REACT_APP_API_URL + "/forward_email", {
            "message_id": message_id,
            "forward_to": props.userEmailAddress,
            "comment": "Forwarded from Versatility:"
          })
        .then((response) => {
            console.log(response.data)
            window.alert ("email forwarded to " + props.userEmailAddress)
        })
        .catch((err) => {
            console.log(err);
        })

    }
    

    useEffect(() => {

        if (props.data) {

            getEmails()

        }

        
    }, [props.data])

    
    if (!props.show) {
        return null
    }
    return (
        <div className="update-value-modal z-50">
            <div className="update-value-modal-email-content">
                <div className="update-value-modal-header mt-4">
                    <p className="update-value-modal-title text-congress-blue text-xl">{props.title}</p>
                </div>
                <div className="grid grid-cols-3 items-center">
                <div className="form-control-8A whitespace-nowrap p-1 pl-4 pr-4">
                    <label className ="text-xs">Search Text</label>
                    <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                </div>
                <div className="form-control-8A whitespace-nowrap p-1 pl-4 pr-4">
                    <label className ="text-xs">From</label>
                    <input type="text" value={fromSearchText} onChange={(e) => setFromSearchText(e.target.value)} />
                </div>
                <div className="form-control-8A whitespace-nowrap p-1 pl-4 pr-4">
                    <label className ="text-xs">To</label>
                    <input type="text" value={toSearchText} onChange={(e) => setToSearchText(e.target.value)} />
                </div>
                </div>
                <div className="update-value-modal-body overflow-x-hidden">
                    {!loading &&
                        <div className="">
                            <div className='overflow-y-scroll h-[65vh]'>                                
                                <table className="objects-8A divide-y divide-gray-200">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            <th scope="col" className="pl-2 py-3 text-left text-xs font-medium text-congress-blue uppercase w-10"></th>
                                            <th scope="col" className="pl-2 py-3 text-left text-xs font-medium text-congress-blue uppercase w-40">From</th>
                                            <th scope="col" className="pl-2 py-3 text-left text-xs font-medium text-congress-blue uppercase w-40">To</th>
                                            <th scope="col" className="pl-2 py-3 text-left text-xs font-medium text-congress-blue uppercase w-40">Date</th>
                                            <th scope="col" className="pl-2 py-3 text-left text-xs font-medium text-congress-blue uppercase w-50">Subject</th>
                                            <th scope="col" className="pl-2 py-3 text-left text-xs font-medium text-congress-blue uppercase w-auto">Body</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {allNotes.filter(item => (item.body.toLowerCase().includes(searchText.toLowerCase()) || item.subject.toLowerCase().includes(searchText.toLowerCase())) && (item.from.toLowerCase().includes(fromSearchText.toLowerCase()) && item.to.toLowerCase().includes(toSearchText.toLowerCase()))).map((item) => (
                                            <tr key={item.id}>
                                                <td onClick={() => {showToggle(item.id)}} className="align-top px-2 md:px-2  py-2">{!item.showBody ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 hover:cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 hover:cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg>}
                                                </td>
                                                <td className="pl-2 py-2 whitespace-pre-line break-all font-bold align-top text-xs"><p className="w-40">{item.from}</p></td>
                                                <td className="pl-2 py-2 whitespace-pre-line break-all font-bold align-top text-xs"><p className="w-40">{item.to}</p></td>
                                                <td className="pl-2 py-2 whitespace-normal font-bold align-top text-xs"><p className="w-40">{item.datetime}</p><Button text="Forward" onClick={() => forwardEmail(item.id)}></Button></td>
                                                <td className="pl-2 py-2 whitespace-normal font-bold align-top text-xs"><p className="w-50">{item.subject}</p></td>
                                                <td className="pl-2 py-2 whitespace-normal text-xs"><p className = "w-[550px]">{item.showBody ? <Note htmlContent={item.body}/> : ""}</p></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {loading &&
                        <div className="min-w-full mt-4">
                            <div className='overflow-auto h-[65vh]'>                                
                                <table className="min-w-full table-fixed">
                                    <tbody className="bg-white">
                                        <tr>
                                            <td className="pl-4 py-2 whitespace-normal flex justify-center items-center">
                                                <Watch 
                                                    height="100"
                                                    width="100"
                                                    radius="48"
                                                    color="#00518c"
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="loading-modal-header">
                                                    <p className="loading-modal-title text-congress-blue text-xl font-bold">Data Loading</p>
                                                </div>   
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    <Button text="Close" onClick={props.onClose}></Button>
                </div>
            </div>    
        </div>
    )
}

export default ModalEmailInfo