import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const register = (email, password) => {
    return axios.post(API_URL + "/register", {
        email,
        password,
    });
};

const setToken = (email, password) => {
    return axios.post(API_URL + "/generate_2fa", {
        email,
        password,
    })
        .then((response) => {
            console.log(response.data)
            if (response.data === "Email sent") {
                sessionStorage.setItem("user", email)
            }
            return response;
        }
        )

};

const validateToken = (form) => {
    return axios.post(API_URL + "/validate_2fa", form)
        .then((response) => {
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("userName", form.user)
            }
            return response;
        }
        )

};

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userName");
    localStorage.removeItem("tab");
    sessionStorage.removeItem("user")
    sessionStorage.removeItem("siteArea")
    window.location.replace('/login')

};
const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const getCurrentUserName = () => {
    return localStorage.getItem("userName");
};

export default {
    register,
    logout,
    getCurrentUser,
    getCurrentUserName,
    setToken,
    validateToken
};